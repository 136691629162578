//App Name
export const APP_NAME = 'DSP';
export const APP_NAME_LICENCE_CONFIG = process.env.REACT_APP_APP_NAME_LICENCE_CONFIG;

//Access Mgmt
export const ACCESS_MGMT_URL = process.env.REACT_APP_ACCESS_MGMT_URL;

// Const: Authentication configuration
export const AUTHORITY_CONFIG = {
    'openid_client_configuration':
    {
        'authentication_settings': {
            'authority': process.env.REACT_APP_AUTHORITY_URL,
            'client_id': process.env.REACT_APP_CLIENT_ID,
            'redirect_uri': process.env.REACT_APP_AUTHORITY_REDIRECT_URL,
            'response_type': 'id_token token',
            'scope': 'openid profile role email userguid admin-web-core',
            'post_logout_redirect_uri': process.env.REACT_APP_AUTHORITY_POST_LOGOUT_REDIRECT_URL,
            'silent_redirect_uri': process.env.REACT_APP_AUTHORITY_RENEW_URL,
            'automaticSilentRenew': true,
            'revokeAccessTokenOnSignout': true
        },
        'authorisation_endpoints': {
            'administration': {
                'base_url': process.env.REACT_APP_AUTHORITY_ADMINISTRATION_BASE_URL,
                'permissions': '/Permission/GetPermissionsByRoleGuids?{0}'
            }
        }
    }
};

// Set IS_DEMO
export const IS_DEMO = false;

// Server URL
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const FORMIO_SERVER_URL = process.env.REACT_APP_FORMIO_SERVER;
const FORM_GEN_URL = process.env.REACT_APP_FORM_GEN;

// JSON URLs
const ENQUIRY_JSON = '/data/enquiry.json';
const ENQUIRY_STATUS_FORMTYPE_JSON = '/data/enquiry_statusformtypes.json';
const NEW_JSON = '/data/request_form_details.json';
const FORM_HISTORY_JSON = '/data/request_form_history.json';
const ACTION_JSON = '/data/application_request_action.json';
const POST_ACTION_JSON = '/data/application_post_action.json';
const LICENCE_LIST_JSON = '/data/licence_list.json';

//codetable
export const CODETABLE_DETAILS_JSON = '/data/codetable_details.json';
export const CODETABLE_UPDATE_JSON = '/data/codetable_update_response.json';
export const CODETABLE_CATEGORY_JSON = '/data/codetable_category_list.json';
export const CODETABLE_LIST_JSON = '/data/codetable_list.json';
export const CODETABLE_SEARCH_JSON = '/data/codetable_search_list.json';
export const CODETABLE_STATUS_JSON = '/data/codetable_status_list.json'
export const CODETABLE_CREATE_JSON = '/data/codetable_create_response.json';

//AppConfigMgmt
export const APPCONFIG_DETAILS_JSON = '/data/appconfig_details.json';
export const APPCONFIG_UPDATE_JSON = '/data/appconfig_update_response.json';
export const APPCONFIG_LIST_JSON = '/data/appconfig_list.json';
export const APPCONFIG_SEARCH_JSON = '/data/appconfig_search_list.json';
export const APPCONFIG_CREATE_JSON = '/data/appconfig_create_response.json';
export const APPCONFIG_CATEGORY_JSON = '/data/appconfig_category_list.json';

//PublicHolidayConfig
export const PUBLICHOLIDAY_DETAILS_JSON = '/data/publicholiday_details.json';
export const PUBLICHOLIDAY_UPDATE_JSON = '/data/publicholiday_update_response.json';
export const PUBLICHOLIDAY_LIST_JSON = '/data/publicholiday_list.json';
export const PUBLICHOLIDAY_SEARCH_JSON = '/data/publicholiday_search_list.json';
export const PUBLICHOLIDAY_CREATE_JSON = '/data/publicholiday_create_response.json';
export const PUBLICHOLIDAY_CATEGORY_JSON = '/data/publicholiday_category_list.json';

//WorkingHourConfig
export const WORKINGHOUR_DETAILS_JSON = '/data/workinghour_details.json';
export const WORKINGHOUR_UPDATE_JSON = '/data/workinghour_update_response.json';
export const WORKINGHOUR_LIST_JSON = '/data/workinghour_list.json';
export const WORKINGHOUREXCEP_LIST_JSON = '/data/workinghourexcep_list.json';
export const WORKINGHOUR_SEARCH_JSON = '/data/workinghour_search_list.json';
export const WORKINGHOUR_CREATE_JSON = '/data/workinghour_create_response.json';
export const WORKINGHOUR_CATEGORY_JSON = '/data/workinghour_category_list.json';
export const WORKINGHOUREXCEP_CREATE_JSON = '/data/workinghourex_create_response.json';
export const WORKINGHOUR_DELETE_JSON = '/data/workinghour_delete.json';

//OnlineHelpMgmt
export const ONLINEHELP_LIST_JSON = '/data/onlinehelp_list.json'
export const ONLINEHELP_LIST_BIRTH_JSON = '/data/onlinehelp_list_birth.json'
export const ONLINEHELP_LIST_DEATH_JSON = '/data/onlinehelp_list_death.json'
export const ONLINEHELP_LIST_MARRIAGE_JSON = '/data/onlinehelp_list_marriage.json'
export const ONLINEHELP_CREATE_JSON = '/data/onlinehelp_create.json'
export const ONLINEHELP_UPDATE_JSON = '/data/onlinehelp_update_response.json'
export const ONLINEHELP_DELETE_URL = '/data/onlinehelp_delete.json'

//App Name
export const IS_LOCALE_ENABLED = true;

//LanguageList
export const LANGUAGE_TRANSLATION_KEY_LIST_JSON = '/data/language_translation_key_list.json'
export const LANGUAGE_APPLICATION_JSON = '/data/language_application_list.json'
export const LANGUAGE_SCOPE_JSON = '/data/language_scope_list.json'
export const LANGUAGE_CATEGORY_JSON = '/data/language_category_list.json'
export const LANGUAGE_SUB_CATEGORY_JSON = '/data/language_subcategory_list.json'
export const LANGUAGE_SORT_BY_JSON = '/data/language_sort_by_list.json'
export const LANGUAGE_JSON = '/data/language_list.json'
export const LANGUAGE_PUBLISH_JSON = '/data/language_publish.json';
export const LANGUAGE_KEY_UPDATE_JSON = '/data/language_key_update.json';
export const LANGUAGE_APP_LANGUAGES_JSON = '/data/language_application_language_list.json'
export const LANGUAGE_SETTINGS_JSON = '/data/language_settings.json'
export const LANGUAGE_CODE_JSON = '/data/language_code_table.json'

//NotificationTemplate
export const GET_NOTIFICATION_TEMPLATE_URL = (IS_DEMO) ? '/data/notification_template_details.json' : `${SERVER_URL}NotificationTemplate/Template/`;
export const UPDATE_NOTIFICATION_TEMPLATE_URL = (IS_DEMO) ? '/data/codetable_update_response.json' : `${SERVER_URL}NotificationTemplate/Template/Edit?`;
export const LIST_NOTIFICATION_TEMPLATE_URL = (IS_DEMO) ? '/data/codetable_list.json' : `${SERVER_URL}NotificationTemplate/Template/List?`;
export const ADD_NOTIFICATION_TEMPLATE_URL = `${SERVER_URL}NotificationTemplate/Template/New?`;
export const LIST_NOTIFICATION_TEMPLATE_CATEGORY_URL = `${SERVER_URL}NotificationTemplate/Category/List?`;
export const LIST_NOTIFICATION_TEMPLATE_PLACEHOLDER_URL = `${SERVER_URL}NotificationTemplate/PlaceHolder/List?`;
export const DELETE_NOTIFICATION_TEMPLATE_URL = `${SERVER_URL}NotificationTemplate/Template/`;

// Page URLs
export const ENQUIRY_PATH = '/enquiry';
export const INBOX_PATH = '/inbox';
export const LOGIN_REDIRECT_PATH = '/dashboard'; // this page needs to be a authentication required page
export const PAYMENT_SUMMARY_PATH = '/paymentSummary';

export const SCOPE_MANAGEMENT_URL = '/SystemConfig/language/scopeManagement';
export const ADD_SCOPE_SYSTEM_URL = '/SystemConfig/language/addScope';
export const VIEW_SCOPE_SYSTEM_URL = '/SystemConfig/language/viewScope';
export const EDIT_SCOPE_SYSTEM_URL = '/SystemConfig/language/editScope';
export const CATEGORY_MANAGEMENT_URL = '/SystemConfig/language/categoryManagement';
export const EDIT_CATEGORY_SYSTEM_URL = '/SystemConfig/language/editCategory';
export const VIEW_CATEGORY_SYSTEM_URL = '/SystemConfig/language/viewCategory';
export const ADD_CATEGORY_SYSTEM_URL = '/SystemConfig/language/addCategory';
export const SUBCATEGORY_MANAGEMENT_URL = '/SystemConfig/language/subcategoryManagement';
export const EDIT_SUBCATEGORY_SYSTEM_URL = '/SystemConfig/language/editSubCategory';
export const VIEW_SUBCATEGORY_SYSTEM_URL = '/SystemConfig/language/viewSubCategory';
export const ADD_SUBCATEGORY_SYSTEM_URL = '/SystemConfig/language/addSubCategory';
export const LANGUAGE_REGISTRATION_EDIT = '/SystemConfig/language/editApplicationLanguages';
export const LANGUAGE_REGISTRATION_LIST = '/SystemConfig/language/languageRegistration';
export const TRANSLATION_MANAGEMENT_UPLOAD_FILE = '/SystemConfig/language/uploadFile/';
export const TRANSLATION_MANAGEMENT_ADD_ENTRY = '/SystemConfig/language/addEntry/';
export const TRANSLATION_MANAGEMENT_UPDATE_ENTRY = `/SystemConfig/language/editEntry`;
export const TRANSLATION_MANAGEMENT_LIST = '/SystemConfig/language/list';

// FAQ urls
export const FAQ_MANAGEMENT_URL = '/SystemConfig/faq/faqManagement';
export const ADD_FAQ_SYSTEM_URL = '/SystemConfig/faq/addFaq';
export const EDIT_FAQ_SYSTEM_URL = '/SystemConfig/faq/editFaq';
export const VIEW_FAQ_SETTINGS_SYSTEM_URL = '/SystemConfig/faq/viewFaqSettings';
export const VIEW_FAQ_SYSTEM_URL = '/SystemConfig/faq/viewFaq';
export const VIEW_PUBLISHED_FAQ_SYSTEM_URL = '/SystemConfig/faq/viewPublishedFaq';

// Application Configurations urls
export const APPCONFIG_LIST = '/SystemConfig/ApplicationConfig';
export const APPCONFIG_UPDATE = '/SystemConfig/ApplicationConfig/Update';
export const APPCONFIG_CREATE = '/SystemConfig/ApplicationConfig/Create';
export const APPCONFIG_VIEW = '/SystemConfig/ApplicationConfig/View';

//notification template urls
export const NOTIFICATION_TEMPLATE_EMAIL_LIST = '/SystemConfig/NotificationTemplate/EmailList';
export const NOTIFICATION_TEMPLATE_EMAIL_UPDATE = '/SystemConfig/NotificationTemplate/EmailUpdate';
export const NOTIFICATION_TEMPLATE_EMAIL_CREATE = '/SystemConfig/NotificationTemplate/EmailCreate';

//codes url
export const CODES_LIST = '/SystemConfig/Codes';
export const CODES_UPDATE = '/SystemConfig/Codes/Update';
export const CODES_CREATE = '/SystemConfig/Codes/Create';
export const CODES_VIEW = '/SystemConfig/Codes/View';

export const EXCEL_UPDATE = '/SystemConfig/language/excelUpdate';
export const EXCEL_GET_FILE_URL = `${SERVER_URL}Export?`;

// Commands URLs
export const DASHBOARD_DATA_URL = `${SERVER_URL}Dashboard/Data`;
export const INBOX_LIST_URL = (IS_DEMO) ? ENQUIRY_JSON : `${SERVER_URL}Workflow/BLSWorkflowInbox`;
export const PENDING_PAYMENT_INBOX_LIST_URL = (IS_DEMO) ? ENQUIRY_JSON : `${SERVER_URL}Workflow/BLSPendingPaymentWorkflowInbox`;
export const APPLICATION_ENQUIRY_LIST_URL = (IS_DEMO) ? ENQUIRY_JSON : `${SERVER_URL}IntranetEnquiry/ApplicationFormList?`;
export const APPLICATION_ENQUIRY_PARAMS_URL = (IS_DEMO) ? ENQUIRY_STATUS_FORMTYPE_JSON : `${SERVER_URL}IntranetEnquiry/ApplicationFilterParams/`;
export const LICENCE_ENQUIRY_LIST_URL = (IS_DEMO) ? ENQUIRY_JSON : `${SERVER_URL}IntranetEnquiry/LicenceFormList?`;
export const LICENCE_ENQUIRY_PARAMS_URL = (IS_DEMO) ? ENQUIRY_STATUS_FORMTYPE_JSON : `${SERVER_URL}IntranetEnquiry/LicenceFilterParams/`;
export const LICENCE_ENQUIRY_PARAMS_CODE_URL = (IS_DEMO) ? ENQUIRY_STATUS_FORMTYPE_JSON : `${SERVER_URL}IntranetEnquiry/LicenceFilterParamsWithCode/`;
export const RENEWAL_REPORT_PARAMS_URL = (IS_DEMO) ? ENQUIRY_STATUS_FORMTYPE_JSON : `${SERVER_URL}IntranetEnquiry/RenewalReportFilterParams/`;

export const GET_FORM_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}LicenceApplication/LicenceApplication/`;
export const GET_FORM_GENERAL_INFO_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}LicenceApplication/GeneralInfo/`;
export const GET_FORM_PROCESSING_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}LicenceApplication/FormProcessing/`;
export const GET_FORM_APPLICATION_INFO_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}LicenceApplication/ApplicationInfo/`;
export const GET_FORM_SUPPORTING_DOCUMENT_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}LicenceApplication/SupportingDocuments/`;
export const GET_FORM_LICENCE_DOCUMENT_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}LicenceApplication/LicenceDocuments/`;
export const GET_FORM_LICENCE_DETAILS_URL = (IS_DEMO) ? NEW_JSON : `${SERVER_URL}LicenceApplication/LicenceDetails/`;
export const GET_FORM_LICENCE_DETAILS_FROM_SFORMID_URL = `${SERVER_URL}LicenceApplication/LicenceDetailsSformId/`;
export const FORM_HISTORY_URL = (IS_DEMO) ? FORM_HISTORY_JSON : `${SERVER_URL}SForm/FormHistoryList/`;
export const GET_ACTIONS_URL = (IS_DEMO) ? ACTION_JSON : `${SERVER_URL}WorkFlow/BLSWorkflowActions/`;
export const POST_ACTION_URL = (IS_DEMO) ? POST_ACTION_JSON : `${SERVER_URL}WorkFlow/BLSWorkflow/`;
export const GET_FILE_URL = `${SERVER_URL}SFormDocument/File?`;
export const GET_LICENCE_DOCUMENT_FILE_URL = `${SERVER_URL}File/LicenceDocumentFile?`;
export const GET_FILE_BY_NAME_URL = `${SERVER_URL}File/GetFileByName`;
export const GET_INTERNAL_FILE_URL = `${SERVER_URL}File/GetInternalFile?`;
export const POST_CHECK_IN_OUT_URL = `${SERVER_URL}SForm/Application?`;
export const LICENCE_LIST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}FormsEnquiry/LicenceList?`;
export const GET_DOCUMENT_NAMES_URL = `${SERVER_URL}LicenceApplication/GetFormDocuments/`;
export const RFA_FORM_HISTORY_URL = (IS_DEMO) ? FORM_HISTORY_JSON : `${SERVER_URL}LicenceApplication/GetRFAHistory/`;
export const RFA_DOCUMENT_URL = (IS_DEMO) ? FORM_HISTORY_JSON : `${SERVER_URL}LicenceApplication/GetRFASupportingDocuments/`;
export const GET_CHECKOUT_HISTORY_URL = (IS_DEMO) ? ACTION_JSON : `${SERVER_URL}LicenceApplication/CheckInCheckOutHistory/`;
export const GET_PAYMENT_DETAILS_URL = (IS_DEMO) ? ACTION_JSON : `${SERVER_URL}Payment/PaymentDetails/`;
export const GET_PAYMENT_RECEIPT_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/GetPaymentReceipt?`;
export const GET_ADDITIONAL_PAYMENT_FEE_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/GetAdditionalPaymentFees/`;
export const GET_ADDITIONAL_PAYMENT_FEE_ITEM_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/GetAdditionalPaymentFeeItem/`;

export const GET_SCOPE_LIST_URL = `${SERVER_URL}Locale/Scope/List?`;
export const ADD_SCOPE_URL = `${SERVER_URL}Locale/Scope/New`;
export const EDIT_SCOPE_URL = `${SERVER_URL}Locale/Scope/Edit`;
export const GET_CATEGORY_LIST_URL = `${SERVER_URL}Locale/Category/List`;
export const ADD_CATEGORY_URL = `${SERVER_URL}Locale/Category/New`;
export const EDIT_CATEGORY_URL = `${SERVER_URL}Locale/Category/Edit`;
export const GET_SUBCATEGORY_LIST_URL = `${SERVER_URL}Locale/SubCategory/List`;
export const ADD_SUBCATEGORY_URL = `${SERVER_URL}Locale/SubCategory/New`;
export const EDIT_SUBCATEGORY_URL = `${SERVER_URL}Locale/SubCategory/Edit`;

export const APP_REGISTERED_LANGUAGE_URL = `${SERVER_URL}Locale/RegisteredLanguage?`;
export const APPLICATION_LIST_URL = `${SERVER_URL}Application/Application/List`;
export const TRANSLATION_LIST_MANAGEMENT_URL = `${SERVER_URL}Locale/Translation/Manage/List?`;
export const TRANSLATION_MANAGEMENT_URL = `${SERVER_URL}Locale/Translation/Manage?`;
export const TRANSLATION_MANAGEMENT_ADD_URL = `${SERVER_URL}Locale/Translation/Manage/New?`;
export const TRANSLATION_MANAGEMENT_EDIT_URL = `${SERVER_URL}Locale/Translation/Manage/Edit`;
export const TRANSLATION_MANAGEMENT_PUBLISH_URL = `${SERVER_URL}Locale/Translation/Manage/Publish?`;
export const TRANSLATION_MANAGEMENT_DELETE_URL = `${SERVER_URL}Locale/Translation/Manage/Delete?`;
export const TRANSLATION_MANAGEMENT_REVERT_URL = `${SERVER_URL}Locale/Translation/Manage/Revert?`;
export const LANGUAGE_REGISTRATION_EDIT_URL = `${SERVER_URL}Locale/LanguageRegistration/Edit`;
export const APP_LANGUAGE_LIST_URL = `${SERVER_URL}Locale/LanguageRegistration/List`;
export const LANGUAGE_CODE_LIST_URL = `${SERVER_URL}Locale/LanguageCode/List`;
export const IMPORT_TRANSLATION_URL = `${SERVER_URL}Locale/Import`;
export const EXPORT_TRANSLATION_URL = `${SERVER_URL}Locale/Export?`;
export const GET_SCOPE_LIST_BY_APP_NAME = `${SERVER_URL}Locale/Scope/ListByName?`;

// FAQ 

export const GET_FAQ_URL = `${SERVER_URL}faq/faq?`;
export const GET_FAQ_TOPIC_URL = `${SERVER_URL}faq/faqTopic?`;
export const GET_FAQ_SUB_TOPIC_URL = `${SERVER_URL}faq/faqSubTopic?`;


export const GET_FAQ_LIST_URL = `${SERVER_URL}faq/List`;
export const DELETE_FAQ_URL = `${SERVER_URL}faq/Delete?`;
export const VIEW_FAQ_URL = `${SERVER_URL}faq/View?`;
export const CREATE_DRAFT_FAQ_URL = `${SERVER_URL}faq/Draft/New?`;
export const CODETABLE_LANGUAGE_LIST_URL = `${SERVER_URL}faq/LanguageCodeList?`;
export const ADD_FAQ_URL = `${SERVER_URL}faq/New/`;
export const PUBLISH_FAQ_URL = `${SERVER_URL}faq/Publish?`;
export const PUBLISH_FAQ_VALIDATION_URL = `${SERVER_URL}faq/PublishFaqValidation?`;
export const EDIT_FAQ_URL = `${SERVER_URL}faq/Edit/`;
export const EDIT_FAQ_SETTINGS_URL = `${SERVER_URL}faq/Settings/Edit/`;
export const SUPPORTED_LANGUAGE_LIST_URL = `${SERVER_URL}faq/SupportedLanguage?`;
export const GET_FAQ_TREE_URL = `${SERVER_URL}faq/Tree?`;
export const ADD_FAQ_TOPIC_URL = `${SERVER_URL}faq/topic/New/`;
export const ADD_FAQ_SUB_TOPIC_URL = `${SERVER_URL}faq/subtopic/New/`;
export const ADD_FAQ_QUESTION_SET_URL = `${SERVER_URL}faq/questionset/New/`;
export const GET_FAQ_QUESTION_SET_URL = `${SERVER_URL}faq/questionset?`;

export const PREVIEW_FAQ_URL = `${SERVER_URL}faq/preview?`;

export const GET_FAQ_QUESTION_SET_LIST_URL = `${SERVER_URL}faq/questionset/List?`;
export const DELETE_FAQ_TOPIC_URL = `${SERVER_URL}faq/topic/Delete?`;
export const DELETE_FAQ_SUB_TOPIC_URL = `${SERVER_URL}faq/subtopic/Delete?`;
export const DELETE_FAQ_QUESTION_SET_URL = `${SERVER_URL}faq/questionset/Delete?`;
export const SORT_FAQ_RANK_URL = `${SERVER_URL}faq/Sort?`;
export const SEARCH_FAQ_URL = `${SERVER_URL}faq/Search?`;

export const EDIT_FAQ_TOPIC_URL = `${SERVER_URL}faq/topic/edit?`;
export const EDIT_FAQ_SUB_TOPIC_URL = `${SERVER_URL}faq/subtopic/edit?`;
export const EDIT_FAQ_QUESTION_SET_URL = `${SERVER_URL}faq/questionset/edit?`;


// Public Holiday Service
export const GET_PUBLIC_HOLIDAY_LIST_URL = `${SERVER_URL}PublicHoliday/List`;
export const GET_PUBLIC_HOLIDAY_URL = `${SERVER_URL}PublicHoliday?`;
export const ADD_PUBLIC_HOLIDAY_URL = `${SERVER_URL}PublicHoliday/New`;
export const DELETE_PUBLIC_HOLIDAY_URL = `${SERVER_URL}PublicHoliday/Delete?`;
export const UPDATE_PUBLIC_HOLIDAY_URL = `${SERVER_URL}PublicHoliday/Update`;

export const PUBLIC_HOLIDAY_MANAGEMENT_URL = '/SystemConfig/PublicHoliday';
export const PUBLIC_HOLIDAY_CREATE_URL = '/SystemConfig/PublicHoliday/Create';
export const PUBLIC_HOLIDAY_UPDATE_URL = '/SystemConfig/PublicHoliday/Update';
export const PUBLIC_HOLIDAY_VIEW_URL = '/SystemConfig/PublicHoliday/View';

// Working Hour
export const GET_WORKING_HOUR_CATEGORY_LIST_URL = `${SERVER_URL}WorkingHour/Category/List`;
export const GET_WORKING_HOUR_LIST_BY_CATEGORY_URL = `${SERVER_URL}WorkingHour/List?`;
export const GET_EXCEPTION_DATE_LIST_BY_CATEGORY_URL = `${SERVER_URL}WorkingHour/ExceptionDate/List?`;
export const GET_WORKING_HOUR_URL = `${SERVER_URL}WorkingHour?`;
export const ADD_WORKING_HOUR_URL = `${SERVER_URL}WorkingHour/New`;
export const ADD_EXCEPTION_DATE_URL = `${SERVER_URL}WorkingHour/ExceptionDate/New`;
export const UPDATE_WORKING_HOUR_URL = `${SERVER_URL}WorkingHour/Update`;
export const UPDATE_EXCEPTION_DATE_URL = `${SERVER_URL}WorkingHour/ExceptionDate/Update`;
export const DELETE_WORKING_HOUR_CATEGORY_URL = `${SERVER_URL}WorkingHour/Category/Delete?`;
export const DELETE_EXCEPTION_DATE_URL = `${SERVER_URL}WorkingHour/ExceptionDate/Delete?`;

export const WORKING_HOUR_UPDATE_URL = '/SystemConfig/WorkingHour/Update';
export const WORKING_HOUR_MANAGEMENT_URL = '/SystemConfig/WorkingHour';
export const EXCEPTION_DATE_UPDATE_URL = '/SystemConfig/WorkingHour/UpdateException';

// SMS Template
export const NOTIFICATION_TEMPLATE_SMS_LIST = '/SystemConfig/NotificationTemplate/SMSList';
export const NOTIFICATION_TEMPLATE_SMS_UPDATE = '/SystemConfig/NotificationTemplate/SMSUpdate';
export const NOTIFICATION_TEMPLATE_SMS_CREATE = '/SystemConfig/NotificationTemplate/SMSCreate';
export const NOTIFICATION_TEMPLATE_SMS_VIEW = '/SystemConfig/NotificationTemplate/SMSView';

// Application Configuration commands
export const GET_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config?`;
export const LIST_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config/List?`;
export const ADD_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config/New`;
export const UPDATE_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config/Update`;
export const LIST_APPCONFIG_CATEGORY_URL = `${SERVER_URL}ApplicationConfiguration/Category/List?`;
export const DELETE_APPCONFIG_URL = `${SERVER_URL}ApplicationConfiguration/Config/Delete?`;

//Code Service
export const CODETABLE_ADD_URL = `${SERVER_URL}Code/New/`;
export const CODETABLE_EDIT_URL = `${SERVER_URL}Code/Edit/`;
export const CODETABLE_CATEGORY_LIST_URL = `${SERVER_URL}Code/Category/List`;
export const CODETABLE_LIST_URL = `${SERVER_URL}Code/List?`;
export const CODETABLE_DELETE_URL = `${SERVER_URL}Code/Delete?`;

// Common Services
export const CODETABLE_URL = `${SERVER_URL}common/codetables`;
export const APPCONFIG_URL = `${SERVER_URL}common/applicationconfigs`;
export const PUBLICHOLIDAY_URL = `${SERVER_URL}common/publicholidays`;
export const NOTIFICATIONTEMPLATE_URL = `${SERVER_URL}common/notificationtemplates`;
export const WORKINGHOUR_URL = `${SERVER_URL}common/workinghours`;
export const ONLINEHELP_URL = `${SERVER_URL}common/onlinehelps`;

// Const: Default timeout for all loader implementations
export const TIMEOUT_LOADER = 300000;

// Const: Display format for date
export const DATE_DISPLAY_FORMAT = {
    DATE_TIME: "DD-MMM-YYYY HH:mm A",
    TIME: "hh:mm A",
    DATE: "DD-MMM-YYYY",
    DATE_SHORT: "DD-MMM",
    DAY_OF_WEEK: "DDD"
}

// Const: Default period ago for Enquiry page
export const DEFAULT_ENQUIRY_MONTHS_AGO = 1;

// Const: Status Badge Color configuration
export const STATUS_BADGE_COLOR = {
    /* green */ success: ['Application Accepted', 'Completed', 'Active'],
    /* amber */ warning: ['Pending Approval Officer', 'Pending Verification Officer', 'Pending Review Officer', 'Pending User Action', 'Processing', 'Warning'],
    /* red   */ danger: ['Application Rejected', 'Rejected', 'Exceeded'],
    /* grey  */ normal: ['Draft', 'None']
};

// Const: Regular Expressions for Validation
export const REGEX = {
    EMAIL: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,})$/,
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
    TELEPHONE: /^(\+)([\d ]{12})+$/,
    NUMBER: /^[1-9]\d*(\.\d+)?$/,
    DATE: /^(([0-9])|([0-2][0-9])|([3][0-1]))-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/,
    INTEGER_POSITIVE_INCLUDE_ZERO: /^\s*[1-9]+\d*\s*$|^\s*0\s*$/,
    DECIMAL: /^[0-9]+(\.[0-9]{1,2})?$/
};


// Const: Regular Expressions for Masked inputs
// Format: Array of Regex for each character
export const MASK = {
    TELEPHONE: ['+', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
};


// Const: Actions in React-Grid / Tables
export const ROW_ACTIONS = {
    VIEW: "VIEW",
    EDIT: "EDIT",
    DELETE: "DELETE"
}

// Const: Form Type for Birth Certificate forms
export const FORM_TYPE = {
    ENQUIRY_DETAILS: 'Enquiry Details',
    PROCESSING: 'Processing'
}

// Const: Layout Type for each page
export const LAYOUT_TYPE = {
    NAVBAR_DESKTOP: 1,      // 1 = vertical, 2 = horizontal
    NAVBAR_MOBILE: 1,       // 1 = hamburger, 2 = bottom buttons
    FORM: 1,                // 1 = full page, 2 = floating window
    FORM_CONTENT: 1       // 1 = tab, 2 = accordion, 3 = wizard
}

export const THEME = "blue"; // red blue green

// Const: SMART Me URL
export const SMART_ME_IDENTITY_CLIENT_URL = process.env.REACT_APP_SMART_ME_IDENTITY_CLIENT_URL;
export const CHANGE_USERNAME_URL = SMART_ME_IDENTITY_CLIENT_URL + 'Account/ChangeUsername';
export const CHANGE_PASSWORD_URL = SMART_ME_IDENTITY_CLIENT_URL + 'Account/ChangePassword';
export const CHANGE_SECURITY_INFO_URL = SMART_ME_IDENTITY_CLIENT_URL + 'Account/ChangeSecurityInfo';

// Const: API to SMART Me URL
export const GET_USER_PERMISSION_URL = process.env.REACT_APP_SERVER_URL + 'Api/PermissionGuidsByUserIdAndAppId';

// General Information Constants
export const DEFAULT_HOME_COUNTRY = 'Singapore';
export const DEFAULT_HOME_COUNTRY_ID_TYPE = 'NRIC';
export const DEFAULT_OVERSEAS_COUNTRY_ID_TYPE = 'Passport';

// Const: Address Details
export const ADDRESS_TYPE = {
    LOCAL: 'Local',
    OVERSEAS: 'Overseas'
};

const ADDRESS_FIELDS = {
    LINE1: {
        AddressLine1: '',
        BlockNo: '',
        StreetName: ''
    },
    LINE2: {
        AddressLine2: '',
        LevelNo: '',
        UnitNo: '',
        BuildingName: ''
    }
};

export const DEFAULT_ADDRESS_TYPE = ADDRESS_TYPE.LOCAL;

export const ADDRESS = {
    ADDRESS_TYPE,
    ADDRESS_FIELDS
};

export const APPLICATION_TYPE = {
    APPLY: 'Apply',
    RENEWAL: 'Renewal',
    AMEND: 'Amend',
    CANCEL: 'Cancel'
};

export const STATUS_TYPES = {
    SUSPENDED: 'Suspended'
};

export const GENERAL_INFORMATION_FORMTYPE = {
    INDIVIDUAL: 'GeneralInformation',
    CORPORATE: 'GeneralInformation_Corporate'
};

// Const: Active paths
export const PATH = {
    DASHBOARD: '/dashboard',
    INBOX: {
        MAIN: '/inbox',
        FORMPROCESSING: '/inbox/FormProcessing'
    },
    PAYMENT_INBOX: '/paymentInbox',
    MAKE_PAYMENT: '/makePayment',
    ENQUIRY: {
        MAIN: '/enquiry',
        APPLICATION: '/enquiry/application',
        SERVICE: '/enquiry/service',
        SERVICE_DETAILS: '/enquiry/service/serviceDetails',
        APPLICATION_FORMDETAILS: '/enquiry/application/FormDetails'
    },
    REPORT_GENERATION: {
        MAIN: '/ReportGeneration/',
        SERVICE_REPORT: '/ReportGeneration/ServiceReport',
        PAYMENT_REPORT: '/ReportGeneration/PaymentReport',
        RENEWAL_REPORT: '/ReportGeneration/RenewalReport'
    },
    CONFIG: {
        MAIN: '/Config/',
        ACCESS_MANAGEMENT: ACCESS_MGMT_URL,
        SERVICE_CONFIG: '/Config/ServiceConfig',
        SERVICE_APP_CONFIG: '/Config/ServiceAppConfig',
        PREVIEW_SERVICE: '/Config/PreviewService',
        FORMIOADMIN: FORMIO_SERVER_URL,
        FORM_GENERATOR: '/Config/FormIO/Create'
    },
    SERVICE_MANAGEMENT: {
        MAIN: '/service/management',
        SERVICE_MANAGEMENT: '/service/management',
        SUSPEND: '/service/management/suspend',
        EDIT_SUSPENSION: '/service/management/editSuspension',
        REVOKE: '/service/management/revoke',
        REINSTATE: '/service/management/reinstate'
    },
    SERVICE_EXTENSION: {
        MAIN: '/service/extension',
        SERVICE_EXTENSION: '/service/extension',
        ADD_NEW_SERVICE_EXTENSION_PAGE: 'addserviceextension'
    },
    SYSTEM_CONFIG: {
        MAIN: '/SystemConfig/',
        APP_CONFIG: '/SystemConfig/ApplicationConfig',
        CODE: '/SystemConfig/Codes',
        NOTIFICATION_TEMPLATE: {
            MAIN: '/SystemConfig/NotificationTemplate',
            EMAIL_TEMPLATE: '/SystemConfig/NotificationTemplate/EmailList',
            LETTER_TEMPLATE: '/SystemConfig/NotificationTemplate/LetterList',
            SMS_TEMPLATE: '/SystemConfig/NotificationTemplate/SmsList'
        },
        PUBLIC_HOLIDAY: '/SystemConfig/PublicHoliday',
        WORKING_HOUR: '/SystemConfig/WorkingHour',
        ONLINE_HELP: '/SystemConfig/OnlineHelp',
        FAQ: '/SystemConfig/faq/faqManagement'
    },
    LANGUAGE: {
        MAIN: '/SystemConfig/language',
        REGISTRATION: '/SystemConfig/language/languageRegistration',
        LIST: '/SystemConfig/language/list',
        SCOPE_MANAGEMENT: '/SystemConfig/language/scopeManagement',
        CATEGORY_MANAGEMENT: '/SystemConfig/language/categoryManagement',
        SUBCATEGORY_MANAGEMENT: '/SystemConfig/language/subcategoryManagement',
        EXCEL_UPDATE: '/SystemConfig/language/excelUpdate'
    },
    RECOMMENDATION_ENGINE_CONFIGURATION: '/NetworkGraph'
};

// Const: Path to Permissions
export const PATH_TO_PERMISSIONS = {
    [PATH.INBOX.MAIN]: ['108E2F88-AB32-4E20-8E55-8EABB739C0CF'],
    [PATH.PAYMENT_INBOX]: ['6C2F9085-59C8-4C48-A547-F57065473DB4'],
    [PATH.ENQUIRY.MAIN]: ['259E4520-B71E-4BB3-A3ED-BB19675A52A5'],
    [PATH.ENQUIRY.APPLICATION]: ['9626B02A-C5E6-41BF-B81A-29F6AD8B948F'],
    [PATH.ENQUIRY.LICENCE]: ['214B58FD-ED55-49AC-A12C-5757FA91AA11'],
    [PATH.REPORT_GENERATION.MAIN]: ['9C8DD789-D1EE-450E-AF8E-9711CBCE36B1'],
    [PATH.REPORT_GENERATION.LICENCE_REPORT]: ['63014543-A85D-4053-BFF6-C4733D95D038'],
    [PATH.REPORT_GENERATION.PAYMENT_REPORT]: ['7F0B3E88-C4F0-46CB-ABAF-B97F88DF397F'],
    [PATH.REPORT_GENERATION.RENEWAL_REPORT]: ['614B7922-E4F8-4D05-9C05-80E13D66C20A'],
    [PATH.CONFIG.MAIN]: ['E68A9527-C89D-420A-9563-14FE3507F7C7'],
    [PATH.CONFIG.ACCESS_MANAGEMENT]: ['E68A9527-C89D-420A-9563-14FE3507F7C7'],
    [PATH.CONFIG.LICENCE_CONFIG]: ['5A2DB3E7-D9FD-4198-B590-9928BFBD9010'],
    [PATH.CONFIG.LICENCE_APP_CONFIG]: ['83C17032-2652-4684-8483-FD34A29D402E'],
    [PATH.CONFIG.PREVIEW_SERVICE]: ['F59CD58E-2A10-496E-B8F8-B1C28FC491A2'],
    [PATH.SERVICE_MANAGEMENT.MAIN]: ['EDFC1AB5-6E8E-4E30-B2B9-5EA38B53DD18'],
    [PATH.SERVICE_MANAGEMENT.LICENCE_MANAGEMENT]: ['EDFC1AB5-6E8E-4E30-B2B9-5EA38B53DD18'],
    [PATH.SYSTEM_CONFIG.MAIN]: ['D84038CA-D271-446E-9B7E-6DDEE1CDDD4D'],
    [PATH.SYSTEM_CONFIG.APP_CONFIG]: ['D7847080-8721-4DB1-A4E8-68D789F8989A'],
    [PATH.SYSTEM_CONFIG.CODE]: ['D3B96F3A-717A-4932-A555-04B48A68E9E7'],
    [PATH.SYSTEM_CONFIG.FAQ]: ['C0260AF9-E420-4B42-BA7E-659A55F79C9D'],
    [PATH.SYSTEM_CONFIG.NOTIFICATION_TEMPLATE.MAIN]: ['D84038CA-D271-446E-9B7E-6DDEE1CDDD4D'],
    [PATH.SYSTEM_CONFIG.NOTIFICATION_TEMPLATE.EMAIL_TEMPLATE]: ['D84038CA-D271-446E-9B7E-6DDEE1CDDD4D'],
    [PATH.SYSTEM_CONFIG.NOTIFICATION_TEMPLATE.LETTER_TEMPLATE]: ['D84038CA-D271-446E-9B7E-6DDEE1CDDD4D'],
    [PATH.SYSTEM_CONFIG.NOTIFICATION_TEMPLATE.SMS_TEMPLATE]: ['D84038CA-D271-446E-9B7E-6DDEE1CDDD4D'],
    [PATH.SYSTEM_CONFIG.PUBLIC_HOLIDAY]: ['D84038CA-D271-446E-9B7E-6DDEE1CDDD4D'],
    [PATH.SYSTEM_CONFIG.WORKING_HOUR]: ['D84038CA-D271-446E-9B7E-6DDEE1CDDD4D'],
    [PATH.SYSTEM_CONFIG.ONLINE_HELP]: ['D84038CA-D271-446E-9B7E-6DDEE1CDDD4D'],
    [PATH.LANGUAGE.MAIN]: ['0F428ED2-A1B7-481D-9FF3-60F9D6E4BE60'],
    [PATH.LANGUAGE.REGISTRATION]: ['0F428ED2-A1B7-481D-9FF3-60F9D6E4BE60'],
    [PATH.LANGUAGE.LIST]: ['0F428ED2-A1B7-481D-9FF3-60F9D6E4BE60'],
    [PATH.LANGUAGE.SCOPE_MANAGEMENT]: ['0F428ED2-A1B7-481D-9FF3-60F9D6E4BE60'],
    [PATH.LANGUAGE.CATEGORY_MANAGEMENT]: ['0F428ED2-A1B7-481D-9FF3-60F9D6E4BE60'],
    [PATH.LANGUAGE.SUBCATEGORY_MANAGEMENT]: ['0F428ED2-A1B7-481D-9FF3-60F9D6E4BE60'],
    [PATH.LANGUAGE.EXCEL_UPDATE]: ['0F428ED2-A1B7-481D-9FF3-60F9D6E4BE60'],
    [PATH.RECOMMENDATION_ENGINE_CONFIGURATION]: ['37BD3CFE-14BB-46D4-88EF-3C4C3A38BC42'],
    [PATH.CONFIG.ACCESS_MANAGEMENT]: ['636F91E6-AABC-4839-95E1-D116693FF449']
};

// Const: Table Layout
export const TABLE_LAYOUT = {
    COLUMNS: 3
};

//Page URLs
export const LICENCE_CONFIG_VERSION_PAGE = '/Config/ServiceConfig/Version';
export const LICENCE_CONFIG_CREATE_PAGE = '/Config/ServiceConfig/Create';
export const LICENCE_CONFIG_VIEW_PAGE = '/Config/ServiceConfig/View';
export const LICENCE_CONFIG_EDIT_PAGE = '/Config/ServiceConfig/Edit';
export const LICENCE_CONFIG_LICENCE_LIST_PAGE = '/Config/ServiceConfig';
export const LICENCE_CONFIG_CREATE_FROM_EXISTING_PAGE = '/Config/ServiceConfig/CreateFromExisting';

export const LICENCE_APP_CONFIG_CREATE_PAGE = '/Config/ServiceAppConfig/Create';
export const LICENCE_APP_CONFIG_VERSION_PAGE = '/Config/ServiceAppConfig/Version';
export const LICENCE_APP_CONFIG_VIEW_PAGE = '/Config/ServiceAppConfig/View';
export const LICENCE_APP_CONFIG_EDIT_PAGE = '/Config/ServiceAppConfig/Edit';
export const LICENCE_APP_CONFIG_LICENCE_LIST_PAGE = '/Config/ServiceAppConfig';
export const LICENCE_APP_CONFIG_CREATE_FROM_EXISTING_PAGE = '/Config/ServiceAppConfig/CreateFromExisting';

//SelfService
export const SELF_SERVICE = '/Config/SelfService';

//LicenceConfigMgmt
export const LICENCE_CONFIG_LIST = '/data/licence_config_list.json';
export const LICENCE_CONFIG_VERSION_LIST = '/data/licence_config_version_list.json';
export const LICENCE_CONFIG_VIEW = '/data/licence_config_view.json';
export const LICENCE_CONFIG_AGENCY_LIST = '/data/licence_config_agency_list.json';

//LicenceAppConfigMgmt
export const LICENCE_APP_CONFIG_LIST = '/data/licence_app_config_list.json';
export const WORKFLOW_STATE_LIST = '/data/workflow_state_list.json';
export const WORKFLOW_SCHEME_NAME_LIST = `${SERVER_URL}WorkFlow/GetBLSWorkflowSchemeList`;
export const DECLARATION_LIST = '/data/licence_declaration_list.json';
export const SUPPORTING_DOC_LIST = '/data/licence_supporting_doc_list.json';
export const LICENCE_APP_CONFIG_DATA = '/data/licence_app_config_data.json';
export const LICENCE_DOC_DESCRIPTION_LIST = '/data/licence_doc_description.json';
export const GET_WORKFLOW_STATE_LIST = (IS_DEMO) ? WORKFLOW_STATE_LIST : `${SERVER_URL}WorkFlow/GetBLSWorkflowStates`;

//AdditionalApplicationFormField
export const ADDITIONAL_APPLICATION_WORKFLOW_SCHEME_NAME_LIST = `${SERVER_URL}WorkFlow/GetBLSWorkflowSchemeActivityList?`;
export const ADDITIONAL_APPLICATION_REQUIRED_ACTIONS_PENDING_VERIFICATION_LIST = '/data/additional_application_fields_required_actions_pedning_verification.json';
export const ADDITIONAL_APPLICATION_REQUIRED_ACTIONS_LIST = `${SERVER_URL}WorkFlow/GetBLSWorkflowSchemeCommandListByActivity?`;
export const ADDITIONAL_APPLICATION_USER_ROLES_LIST = `${SERVER_URL}WorkFlow/GetBLSWorkflowSchemeActorList?`;
export const USER_ROLE_TRANSLATION_KEY = 'DSP.InternalLicenceAppConfig.Form.Field.';
export const ADDITIONAL_APPLICATION_FORM_TITLE_KEY = 'DSP.InternalApplicationDetails.Content.Title.';

//RecommendationEngineConfig
export const GET_LICENCE_GRAPH_URL = `${SERVER_URL}NetworkGraph/GetLicenceRelations?`;
export const REMOVE_LICENCE_RULE_URL = `${SERVER_URL}NetworkGraph/RemoveLicenceRelation/`;
export const ADD_LICENCE_RULE_URL = `${SERVER_URL}NetworkGraph/AddLicenceRelation`;

// Licence URLs
export const GET_LICENCE_DETAILS = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}IntranetEnquiry/LicenceDetails/`;
export const GET_LICENCE_APPLICATION_HISTORY = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}IntranetEnquiry/LicenceApplicationHistory/`;
export const GET_LICENCE_STATUS_HISTORY = `${SERVER_URL}IntranetEnquiry/LicenceStatusHistory/`;
export const GET_LICENCE_STATUS_DETAILS = `${SERVER_URL}IntranetEnquiry/LicenceStatusDetails/`;
export const GET_LICENCE_STATUS_SUPPORTING_DOCUMENTS = `${SERVER_URL}IntranetEnquiry/LicenceStatusSupportingDocuments/`;
export const GET_LICENCE_SUSPEND_END_DATE = `${SERVER_URL}IntranetEnquiry/SuspendedLicenceEndDate/`;

// Licence Management
export const REVOKE_LICENCE_URL = `${SERVER_URL}LicenceManagement/RevokeLicence/`;
export const REINSTATE_LICENCE_URL = `${SERVER_URL}LicenceManagement/ReinstateLicence/`;
export const LICENCE_MANAGEMENT_LIST_URL = `${SERVER_URL}LicenceManagement/RevokableLicences/`;
export const SUSPEND_LICENCE_URL = `${SERVER_URL}LicenceManagement/SuspendLicence/`;
export const EDIT_SUSPEND_LICENCE_URL = `${SERVER_URL}LicenceManagement/EditSuspendedLicence/`;

// Report Generation
export const REPORT_SAVE_AND_DOWNLOAD_LICENCE_URL = `${SERVER_URL}ReportGeneration/GenerateLicenceReport`;
export const REPORT_SAVE_AND_DOWNLOAD_PAYMENT_URL = `${SERVER_URL}ReportGeneration/GeneratePaymentReport`;
export const REPORT_SAVE_AND_DOWNLOAD_RENEWAL_URL = `${SERVER_URL}ReportGeneration/GenerateRenewalReport`;
export const REPORT_ENQUIRY_SEARCH_LICENCE_URL = `${SERVER_URL}ReportGeneration/SearchLicences?`;
export const REPORT_ENQUIRY_SEARCH_PAYMENT_URL = `${SERVER_URL}ReportGeneration/SearchPayments?`;
export const REPORT_ENQUIRY_SEARCH_RENEWAL_URL = `${SERVER_URL}ReportGeneration/SearchRenewals?`;
export const REPORT_HISTORY_LICENCE_URL = `${SERVER_URL}ReportGeneration/GetReportHistory?reportType=Licence`;
export const REPORT_HISTORY_PAYMENT_URL = `${SERVER_URL}ReportGeneration/GetReportHistory?reportType=Payment`;
export const REPORT_HISTORY_RENEWAL_URL = `${SERVER_URL}ReportGeneration/GetReportHistory?reportType=Renewal`;

//LicenceConfigMgmt
export const LICENCE_CONFIG_CREATE = `${SERVER_URL}LicenceConfiguration/Type/New`;
export const LICENCE_CONFIG_LICENCE_TYPE_LIST = `${SERVER_URL}LicenceConfiguration/LicenceType/List`;
export const LICENCE_CONFIG_ACTIVE_LICENCE_TYPE_BY_AGENCY_LIST = `${SERVER_URL}LicenceConfiguration/LicenceTypeByAgency/List`;
export const LICENCE_CONFIG_LICENCE_VERSIONS_BY_TYPE_LIST = `${SERVER_URL}LicenceConfiguration/LicenceDefinitionByLicenceType/List`;
export const GET_CODES_BY_CODE_CATEGORIES = `${SERVER_URL}Code/GetCodesByCodeCategories`;
export const LICENCE_CONFIG_GET_LICENCE_VERSION_BY_ID = `${SERVER_URL}LicenceConfiguration/LicenceDefinition/`;
export const LICENCE_CONFIG_EDIT = `${SERVER_URL}LicenceConfiguration/Edit/`;
export const LICENCE_CONFIG_USER_AGENCY_LIST = `${SERVER_URL}LicenceConfiguration/GetUserAgencyList/`;

//LicenceAppConfigMgmt
export const LICENCE_APP_CONFIG_CREATE = `${SERVER_URL}LicenceApplicationConfiguration/Create`;
export const LICENCE_APP_CONFIG_GET_DECLARATION = `${SERVER_URL}LicenceApplicationConfiguration/GetDeclarationsByLicenceTypeId`;
export const LICENCE_APP_CONFIG_GET_SUPPORTING_DOC_NAME = `${SERVER_URL}LicenceApplicationConfiguration/GetGlobalSupportingDocumentList`;
export const LICENCE_APP_CONFIG_GET_LICENCE_APP_LIST = `${SERVER_URL}LicenceApplicationConfiguration/GetLicenceAppConfigListAll`;
export const LICENCE_APP_CONFIG_GET_LICENCE_APP_VERSION_LIST = `${SERVER_URL}LicenceApplicationConfiguration/GetLicenceAppConfigListByFormId`;
export const LICENCE_APP_CONFIG_GET_LICENCE_APP_CONFIG_BY_FORM_ID = `${SERVER_URL}LicenceApplicationConfiguration/View`;
export const LICENCE_APP_CONFIG_EDIT = `${SERVER_URL}LicenceApplicationConfiguration/Edit`;
export const LICENCE_APP_CONFIG_GET_TEMPLATE_TYPE_LIST = `${SERVER_URL}LicenceApplicationConfiguration/GetGlobalLicenceDocumentTemplateTypeList`;
export const LICENCE_APP_CONFIG_GET_DOCUMENT_PLACEHOLDER_LIST = `${SERVER_URL}LicenceApplicationConfiguration/GetLicenceDocumentPlaceholderList`;
export const LICENCE_APP_CONFIG_GET_WF_SCHEME_LIST  = `${SERVER_URL}Workflow/WorkflowSchemeList`;

//LicenceAppConfigPreviewForm
export const FORM_ENGINE_EXCEL_URL = `${SERVER_URL}FormEngine/ProcessExcel`;
export const FORM_ENGINE_PREVIEW_ADDITIONAL_FORM_EXCEL_URL = `${SERVER_URL}FormEngine/PreviewAdditionalFormExcel`;
export const FORM_ENGINE_GENERATE_USER_TOKEN = `${SERVER_URL}FormEngine/GenerateUserToken?`;
export const FORM_ENGINE_RETRIEVE_EXCEL_TEMPLATE_URL = `${SERVER_URL}FormEngine/RetrieveTemplate`;
export const FORM_ENGINE_DOWNLOAD_EXCEL_FILE_URL = `${SERVER_URL}FormEngine/DownloadExcelFile`;

// Additional Information Service
export const GET_ADDITIONAL_INFORMATION_DATA_FROM_SFORMID_URL = `${SERVER_URL}AdditionalInformation/RetrieveAdditionalFormData/`;
export const GET_USER_ROLES_GUID_FROM_USER_GUID_URL = `${SERVER_URL}AdditionalInformation/GetUserRoleGUID`;
export const GET_ADDITIONAL_FORM_BY_SFORM_ID = `${SERVER_URL}AdditionalInformation/AdditionalForm/`;

// Language Translation URLs
export const GET_TRANSLATION_URL = `${SERVER_URL}BlsLocaleApi/GetTranslation?language={{lng}}`;
export const GET_SUPPORTED_LANGUAGE_URL = `${SERVER_URL}BlsLocaleApi/GetSupportedLanguage`;

// Print licence
export const PRINT_LICENCE_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Print/PrintLicence/`;

//todo
export const CHECK_PAYMENT_ORDERS_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/CheckOrders?`;
export const GET_MAKE_PAYMENT_DETAIL_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/MakePaymentSummary?`;
export const GET_PAYMENT_REQUEST_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/PaymentHub?`;
export const GET_PAYMENT_SUCCESS_URL = (IS_DEMO) ? LICENCE_LIST_JSON : `${SERVER_URL}Payment/PaymentSuccess?`;

//Licence Extension
export const ADD_LICENCE_EXTENSION = `${SERVER_URL}LicenceExtension/AddExtension`;
export const GET_LICENCE_EXTENSION = `${SERVER_URL}LicenceExtension/GetLicenceExtension`;
export const DOWNLOAD_LICENCE_EXTENSION_HISTORY = (IS_DEMO) ? ENQUIRY_JSON : `${SERVER_URL}LicenceExtension/DownloadExtensionHistory?`;

//Document Type
export const DOCUMENT_TYPE_ISSUED = `Issued`;
export const DOCUMENT_TYPE_SUBMITTED = "Submitted";
export const DOCUMENT_TYPE_ALL = `All`;

//Self Service
export const GET_PREVIEW_SERVICE = `${SERVER_URL}SelfService/View/`;
export const GET_PREVIEW_SERVICE_SC = `${SERVER_URL}SelfService/View/Lc/`;
export const GET_PREVIEW_SERVICE_SAC = `${SERVER_URL}SelfService/View/Lac/`;
export const PREVIEW_SERVICE_DRAFT_LIST = `${SERVER_URL}SelfService/DraftToStaging`;
export const PREVIE_SERVICE_PREVIEW_LIST = `${SERVER_URL}SelfService/StagingToProduction`;
export const GET_PREVIEW_SERVICE_TEST_ACCOUNT_CONFIG = `${SERVER_URL}SelfService/GetTestAccountConfiguration`;
export const PREVIEW_SERVICE_SAVE_TEST_ACCOUNTS = `${SERVER_URL}SelfService/SaveTestAccounts`;
export const GET_PREVIEW_SERVICE_TEST_ACCOUNT_BY_LICENCE_TYPE_ID = `${SERVER_URL}SelfService/GetTestAccountByLicenceTypeId`;
export const POST_PREVIEW_SERVICE_PUBLISH_FOR_LIVE = `${SERVER_URL}SelfService/PublishToProduction`;

//Static Site Content
export const SITE_ABBREVIATION = 'BL';
export const SITE_NAME = 'CrimsonLogic - Coherence Digital Services Platform (Intranet Site)';
export const SITE_FAVICON =process.env.PUBLIC_URL+ '/favicon.ico'
export const SITE_LOGO = process.env.PUBLIC_URL+'/images/logo.png';
export const SITE_LOGO_ALT_TEXT = `${SITE_NAME}`;
export const CURRENT_YEAR = (new Date()).getFullYear();
export const COPYRIGHT_SYMBOL = "\u00A9";
export const COPYRIGHT_TEXT = `${COPYRIGHT_SYMBOL}${CURRENT_YEAR} Coherence Digital Services Platform`;

// Form.IO
export const FORMIO_URL = FORMIO_SERVER_URL;
export const FORMIO_URL_GET_ALL_FORMS = `${FORMIO_SERVER_URL}form?type=form`;
export const FORMIO_FORM_TYPE = 'form';
export const FORMIO_RESOURCE_TYPE = 'resource';
export const FORMGEN_URL = FORM_GEN_URL;

//SuiteCRM
export const GET_SUITECRM_MODULES = `${SERVER_URL}suitecrm/modules`;
export const GET_SUITECRM_FIELDS = `${SERVER_URL}suitecrm/fields`;