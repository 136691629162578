import React from "react";
import './Create.css';
import {Button, Container, Row, Col} from 'reactstrap';
import Form from './components/Form';
import { useState } from 'react';
import SelectFileModal from './components/modals/SelectFileModal';
import { useTranslation } from 'react-i18next';
import { LANGUAGE_KEYS } from "../../Shared/Constants/LanguageKeys";

const FormIOCreate = () => {
    const [createNewForm, setCreateNewForm] = useState(false);
    const [formJson, setFormJson] = useState({});
    const [formValidation, setFormValidation] = useState({ title: null, sections: [] });
    const [isValidated, setIsValidated] = useState(false);
    const [key, setKey] = useState(1);
    const { t } = useTranslation();

    function createFormOnClick() {
        setCreateNewForm(true);
        setFormJson({});
        setFormValidation({ title: null, sections: []})
        setKey(key + 1);
    };

    const [showSelectFileModal, setShowSelectFileModal] = useState(false);
    function closeSelectFileModal() {
        setShowSelectFileModal(false);
    }
    function openSelectFileModal() {
        setShowSelectFileModal(true)
    };

    function uploadFormOnClick() {
        openSelectFileModal();
    }

    function selectFileModalOnUpload(json) {
        const tempFormJson = JSON.parse(json);
        setCreateNewForm(true);
        setFormJson(tempFormJson);
        setKey(key + 1);

        const tempFormValidation = {
            id: tempFormJson.id,
            title: !!tempFormJson.title && tempFormJson.title.trim().length > 0,
            sections: tempFormJson.sections.map(section => {
                return {
                    id: section.id,
                    title: !!section.title && section.title.trim().length > 0,
                    fields: section.fields.map(field => {
                        return {
                            id: field.id,
                            inputType: !!field.inputType && field.inputType.trim().length > 0,
                            label: !!field.label && field.label.trim().length > 0,
                        }
                    })
                }
            })
        }
        setIsValidated(true);
        setFormValidation(tempFormValidation);

        closeSelectFileModal();
    }

    // function testOnClick() {
    //     console.log(formJson);
    // }

    return <div>
        <Container fluid>
            {/* <h1>{t(LANGUAGE_KEYS.BLS_INTERNALAPPCONFIG_FORM_FIELD_FORMGENERATOR)}</h1> */}
            <h1>Form Generator</h1>
        </Container>
        <Container fluid>
            <Row xs="auto">
                <Col>
                    <Button color="primary" onClick={createFormOnClick} outline>Create New Form</Button>
                </Col>
                <Col>
                    <Button color="primary" onClick={uploadFormOnClick} outline>Upload Existing Form</Button>
                </Col>
                {/* <Col>
                    <Button variant="outline-primary" onClick={testOnClick}>Test</Button>
                </Col> */}
                {/* <Col>
                    <Button variant="outline-primary">Modify Existing Form</Button>
                </Col> */}
            </Row>
        </Container>
        <br/>
        {createNewForm && <Container fluid>
            <Form form={formJson} formValidation={formValidation} isValidated={isValidated} setIsValidated={setIsValidated} setOnValidat onFormChange={setFormJson} key={key}></Form>
        </Container>}

        <SelectFileModal 
            show={showSelectFileModal}
            onModalClose={closeSelectFileModal}
            onModalUpdate={selectFileModalOnUpload}
            formJson={formJson}
        />
    </div>
}

export default FormIOCreate;