import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button as AntdButton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { GET_ACTIONS_URL, GET_FORM_PROCESSING_URL, POST_CHECK_IN_OUT_URL } from '../../../../App/AppSettings';
import { ERROR, LANGUAGE_KEYS, SUCCESS } from '../../../../Shared/Constants/LanguageKeys';
import DropdownButton from '../../../../Shared/DropdownButton/DropdownButton.js';
import { getParams, postParams, useAsyncFetch } from '../../../../Shared/Fetch';
import { toastError, toastSuccess } from '../../../../Shared/Forms/Toaster.js';
import history from '../../../../Shared/History';
import { Modal } from '../../../../Shared/Modal';
import TitleChildren from '../../../../Title/TitleChildren';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../../../DisplayComponents/DisplayUtils';
import { additionalFormRegex } from '../../../FormComponents/FormUtils';
import { SubmitRemarks } from './Actions';

export default function FormProcessing(formProcessingProps) {

    const { t } = useTranslation();
    const IS_DEMO = false;

    const { additionalFormDisplayError, additionalFormResponseData, additionalFormSectionName } = formProcessingProps;
    const { values, toggleSection } = formProcessingProps.smartFormValues;
    const { state } = history.location;
    const [formData, setData] = useState(undefined);
    const [checkOut, setCheckout] = useState(false);
    const [selectListActions, setSelectListActions] = useState([]);
    const [command, setCommand] = useState(undefined);
    const [toggleCommand, setToggleCommand] = useState(true);
    const isAction = formProcessingProps.isAction;

    const RETRIEVE_URL = IS_DEMO ? GET_FORM_PROCESSING_URL : GET_FORM_PROCESSING_URL + state.ID;
    const ACTIONS_URL = IS_DEMO ? GET_ACTIONS_URL : GET_ACTIONS_URL + state.ID;

    const formResponse = useAsyncFetch(RETRIEVE_URL, getParams());
    const actionResponse = useAsyncFetch(ACTIONS_URL, getParams());
    // Start: Check In Check Out Feature

    const resetCheckInCheckOutReadyFunc = () => {
        setCheckInCheckOutReadyClick(false);
        formProcessingProps.toggleCheckInCheckOut();
    };

    const onCheckInCheckOutClickFn = params => {
        setCheckInCheckOutParams(params);
        setCheckInCheckOutReadyClick(true);
    };

    const [isCheckInCheckOutReadyClick, setCheckInCheckOutReadyClick] = useState(false);
    const [checkInCheckOutParams, setCheckInCheckOutParams] = useState({});

    const postCheckInCheckOutResponse =
        useAsyncFetch(POST_CHECK_IN_OUT_URL, postParams('', checkInCheckOutParams),
            isCheckInCheckOutReadyClick, resetCheckInCheckOutReadyFunc);

    let licenceTypeNameTranslationKey = '';

    // End: Check In Check Out Feature
    useEffect(() => {
        async function setFormData() {
            if (IS_DEMO) {
                const { FormData, CreatedDate, LastUpdatedDate } = formResponse.body.Data;

                const Data = Object.assign({}, FormData, {
                    FormName: state.FormName,
                    InternalDescription: state.InternalDescription,
                    ReferenceNo: state.ReferenceNo,
                    CreatedDate,
                    LastUpdatedDate,
                    LicenceTypeId: state.LicenceTypeId
                });

                setData(Data);
            }
            else {
                const { IsSuccess, Messages, Data } = formResponse.body;

                if (formResponse.success && IsSuccess) {

                    let { FormData } = Data;
                    const retrievedValues = JSON.parse(FormData);

                    FormData = Object.assign(Data, retrievedValues, {
                        Remarks: '',
                        FormID: state.ID,
                        FormName: state.FormName,
                        InternalDescription: state.InternalDescription,
                        ReferenceNo: state.ReferenceNo,
                        ApplicationType: Data.ApplicationType,
                        LicenceTypeId: state.LicenceTypeId,
                        ApplicationTypeCodeName: state.ApplicationTypeCodeName
                    });

                    setData(FormData);
                    setCheckout(FormData.CheckedStatus);
                }
                else {
                    // Error message to be loaded here
                    toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
                }
            }
        }

        async function setActionData() {
            const { IsSuccess, Messages, Data } = actionResponse.body;

            if (actionResponse.success && IsSuccess) {
                const selectListActions = (Data ? Data : []);
                setSelectListActions(selectListActions);
            }
            else {
                // Error message to be loaded here
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        async function setPostCheckInCheckOutData() {
            const { IsSuccess, Messages } = postCheckInCheckOutResponse.body;

            if (IsSuccess) {
                toastSuccess(t(getLangKey(SUCCESS.BACKEND_SUCCESS_MESSAGE, Messages)), Messages);
                setCheckout(!checkOut);
                setToggleCommand(!toggleCommand);
                setCommand(undefined); // reset command
            }
            else {
                toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
            }
        }

        if (formResponse) {
            setFormData();
        }

        if (isAction === true && actionResponse) {
            setActionData();
        }

        if (postCheckInCheckOutResponse) {
            setPostCheckInCheckOutData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAction, postCheckInCheckOutResponse, formResponse, actionResponse]);


    const Processing_Actions = () => {

        const toggleCheckout = async () => {
            const params = {
                id: formData.FormID,
                checkedStatus: !checkOut
            };

            onCheckInCheckOutClickFn(params);
        };

        const toggleCheckoutDisplay = () => {
            if (checkOut) {
                return (<FontAwesomeIcon size='lg' fixedWidth icon={faLock} />);
            }
            else {
                return (<FontAwesomeIcon size='lg' fixedWidth icon={faLockOpen} />);
            }
        };

        const handleSelectChange = async key => {
            let isDisplayErrorRequired = false;
            if (additionalFormResponseData) {
                // Check if key is needed in additional information command
                if (additionalFormResponseData.RequiredCommandNames.some(rcn => rcn === key)) {
                    isDisplayErrorRequired = true;
                }

                const displayError = await additionalFormDisplayError(values, isDisplayErrorRequired);

                // Open modal if result come back is 'false'
                if (!displayError) {
                    // Set modal header
                    setCommand(key);
                } else {
                    toggleSection(false, false, t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY, additionalFormRegex(additionalFormSectionName))), null);
                }
            } else {
                setCommand(key);
            }
        };

        return (
            <Row className='title-custom'>
                <Col className='title-actions'>
                    <AntdButton className='checkOut-btn' onClick={() => toggleCheckout()}>
                        {toggleCheckoutDisplay()}
                    </AntdButton>
                    <DropdownButton
                        className='btn-neutral'
                        buttonLabel={t(LANGUAGE_KEYS.BLS_INTERNALREPORTHISTORY_TABLE_TITLE_ACTIONS)}
                        menuItems={selectListActions}
                        onChange={e => handleSelectChange(e.key)}
                        placement='bottomLeft'
                        disabled={!checkOut}
                    />
                </Col>
            </Row>
        );
    };

    const EnquiryDetails_Actions = () => {

        return (
            <Row className='title-custom'>
                <Col className='title-actions'>
                    <AntdButton
                        className='btn-backward'
                        onClick={() => history.goBack()}
                    >
                        {t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_NAVIGATION_BUTTON_BACK)}
                    </AntdButton>
                </Col>
            </Row>
        );
    };

    const Actions = () => {
        const ActionsComponent = isAction ? Processing_Actions() : EnquiryDetails_Actions();
        return ActionsComponent;
    };

    const clearCommand = () => {
        setCommand(undefined);
    };

    const customActions = formProcessingProps.customActions;
    let CustomAction = SubmitRemarks; // Default

    if (command && customActions) {
        CustomAction = customActions[command];
        CustomAction = CustomAction === undefined ? SubmitRemarks : CustomAction;
    }
    if(formData) {
        licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(formData.FormName);
    }

    return (
        <>
            {formData &&
                <TitleChildren
                    items={[
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_SERVICENAME), value: t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, formData.LicenceTypeId)), type: 'text' },
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_APPLICATIONTYPE), value: t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, formData.ApplicationTypeCodeName)), type: 'text' },
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_REFDETAILS), value: formData.ReferenceNo, type: 'textMobileShow' },
                        { label: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_STATUSDETAILS), value: formData.InternalDescription, type: 'badge' }]}
                >
                    <Actions />
                    <Modal render={({ modal }) => (
                        <CustomAction
                            key={command}
                            modal={modal}
                            clearCommand={clearCommand}
                            toggleCommand={toggleCommand}
                            isCheckOut={checkOut}
                            command={command}
                            smartFormValues={formProcessingProps.smartFormValues}
                        />
                    )} />
                </TitleChildren>
            }
        </>
    );
}