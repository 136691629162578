import React from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
    GET_ADDITIONAL_FORM_BY_SFORM_ID, GET_ADDITIONAL_INFORMATION_DATA_FROM_SFORMID_URL, GET_FORM_LICENCE_DETAILS_FROM_SFORMID_URL,
    GET_FORM_URL, INBOX_PATH, IS_DEMO, LAYOUT_TYPE, POST_ACTION_URL, TABLE_LAYOUT
} from '../../App/AppSettings';
import Layout from '../../Layout';
import SectionWrapper from '../../SectionWrapper';
import { CONTENT_TYPE, fetchRequest, formParams, getParams, isValidForm, navigateTo } from '../../Shared/Actions';
import { SECTION_WRAPPER_TYPES } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS, SUCCESS, getLangKey } from '../../Shared/Constants/LanguageKeys';
import { SMARTForm, toastError, toastSuccess, withDynaFormWrapper } from '../../Shared/Forms';
import history from '../../Shared/History';
import { withLoader } from '../../Shared/Loading';
import { additionalFormRegex, getRemoteList, getTableListValues } from '../FormComponents/FormUtils';
import { createValidationSchemaForLicenceFields, createValidationSchemaForSupportingDocuments } from '../FormComponents/FormValidation/ValidationSchema';
import {
    AdditionalInformation, ApplicationInfo, CheckInCheckOutHistory, FormHistory, FormProcessing,
    GeneralInfo, PaymentInfo, RFAHistory, SupportingDocuments, ViewAdditionalInformation
} from './Components';
import { SubmitAdditionalPayment, SubmitClarification } from './Components/FormProcessing/Actions';

const SECTION_LAYOUT_TYPE = SECTION_WRAPPER_TYPES.TAB;
const POST_URL = POST_ACTION_URL;
const NEXT_URL = INBOX_PATH;

const CustomActions = {
    'Request For Clarification': SubmitClarification,
    'Approve And Request For Additional Payment': SubmitAdditionalPayment
};

class FormProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            licence: {},
            additionalFieldsError: false,
            workFlow: 0
        };
    }

    async componentDidMount() {
        const { loader } = this.props;
        loader.start();

        await this.getLicenceDetails();

        const retrievedValues = this.state.applicationDetails.FormData.LicenceInformation;
        // Get additional form information data
        const groupedAdditionalFormDefinition = await this.getAdditionalInformationData();
        // Set workflow state id that is being retrieved from history.location.state
        const { WorkflowStateId } = history.location.state;
        // Create an array to store additional form definition that is not corresponding to current workflow state id
        let additionalFormDefinitionWithoutWorkflowState = [];
        let additionalFormDefinitionWithWorkflowState = [];

        // Form ID
        const { ID } = history.location.state;
        // Retrieve additional form by sform id
        const response = await fetchRequest(GET_ADDITIONAL_FORM_BY_SFORM_ID + ID, getParams(), false);

        const { IsSuccess, Data } = response.body;
        let initialValues = {};

        if (IsSuccess && Data.length > 0) {
            // Get additional information that has the current workflow state
            const workflowAdditionalInformationData = Data.find(data => data.WorkflowStateId === WorkflowStateId);

            // Check if there is any additional form that has current workflow state
            if (workflowAdditionalInformationData != null) {
                // Get fields remote data
                await this.getOptionsDataList(workflowAdditionalInformationData);
                initialValues = Object.assign({ FormID: this.state.ID, AdditionalFormDefinitionID: workflowAdditionalInformationData.AdditionalFormDefinitionId });

                this.setState({
                    validationSchema: this.createValidationSchema(workflowAdditionalInformationData),
                    additionalFormSectionName: workflowAdditionalInformationData.Title,
                    additionalFormResponseData: workflowAdditionalInformationData,
                    workFlow: workflowAdditionalInformationData.WorkflowStateId
                });
            } else {
                initialValues = Object.assign({ Remarks: '', FormID: this.state.ID }, retrievedValues);
            }

            // Check if additional form data in empty
            if (groupedAdditionalFormDefinition) {
                // If the retrieved additional form definition has the same workflow as the current workflow (Additional Form)
                additionalFormDefinitionWithWorkflowState = Object.keys(groupedAdditionalFormDefinition).reduce((object, key) => {

                    if (Number(key) === WorkflowStateId) {
                        object[key] = groupedAdditionalFormDefinition[key];
                    }
                    // Check if the retrieved additional form has a value for the current workflow
                    if (groupedAdditionalFormDefinition[WorkflowStateId] &&
                        groupedAdditionalFormDefinition[WorkflowStateId][0]['Values']) {
                        // Assign form initial values to a variable
                        initialValues = Object.assign(
                            { Remarks: '' },
                            initialValues,
                            retrievedValues,
                            this.getFormInitialValuesWithPreviousValues(
                                workflowAdditionalInformationData,
                                groupedAdditionalFormDefinition[WorkflowStateId][0]['Values'],
                                groupedAdditionalFormDefinition[WorkflowStateId][0]['SupportingDocument'],
                            )
                        );
                    } else {
                        // Assign form initial values to a variable
                        if (workflowAdditionalInformationData != null) {
                            initialValues = Object.assign({ Remarks: '' }, initialValues, retrievedValues, this.getFormInitialValues(workflowAdditionalInformationData));
                        } else {
                            initialValues = Object.assign({ Remarks: '' }, initialValues, retrievedValues);
                        }
                    }
                    return object;
                }, {});

                // If the retrieved additional form definition does not have the same workflow as the current workflow (Additional Form View)
                additionalFormDefinitionWithoutWorkflowState = Object.keys(groupedAdditionalFormDefinition).reduce((object, key) => {
                    if (Number(key) !== WorkflowStateId) {
                        object[key] = groupedAdditionalFormDefinition[key];
                    }
                    return object;
                }, {});
            } else {
                // Assign form initial values to a variable
                if (workflowAdditionalInformationData != null) {
                    initialValues = Object.assign({ Remarks: '' }, initialValues, retrievedValues, this.getFormInitialValues(workflowAdditionalInformationData));
                } else {
                    initialValues = Object.assign({ Remarks: '' }, initialValues, retrievedValues);
                }
            }
            this.setState({
                values: initialValues,
                isLoaded: true,
                additionalFormDefinitionWithoutWorkflowState,
                additionalFormDefinitionWithWorkflowState
            });
        } else {
            this.setState({
                values: Object.assign({ Remarks: '', FormID: this.state.ID }, this.state.values, retrievedValues),
                isLoaded: true
            });
        }
        loader.done();
    }

    toggleCheckInCheckOut = () => {
        this.setState(prevState => {
            return {
                toggleCheckOut: !prevState.toggleCheckOut
            };
        });
    };

    createValidationSchema = devData => {
        const { t } = this.props;
        const validationObject = {};
        /***********************/
        /*** Licence Section ***/
        /***********************/
        validationObject[devData.FormDefinition.FormType] = createValidationSchemaForLicenceFields(t, devData.FormDefinition);

        /***********************************/
        /*** Supporting Document Section ***/
        /***********************************/
        if (Object.keys(devData.FilesDefinition).length > 0 && devData.FilesDefinition.Fields.length > 0) {
            validationObject.SupportingDocument = createValidationSchemaForSupportingDocuments(t, devData.FilesDefinition.Fields);
        }
        return Yup.object().shape(validationObject);
    };

    async getLicenceDetails() {
        const { ID } = history.location.state;

        const response = await fetchRequest(GET_FORM_URL + ID, getParams());

        const { IsSuccess, Data } = response.body;
        if (response.success && IsSuccess) {
            const applicationDetails = Object.assign({}, Data, { FormData: JSON.parse(Data.FormData) });
            if (applicationDetails.ApplicationType !== 'Apply') {
                await this.getLicenceData();
            }
            this.setState(() => {
                return {
                    ID,
                    applicationDetails
                };
            });
        }
    }

    getLicenceData = async () => {
        const { t } = this.props;
        const { ID } = history.location.state;
        const response = await fetchRequest(GET_FORM_LICENCE_DETAILS_FROM_SFORMID_URL + ID, getParams(), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {

            this.setState(prevState => {
                return {
                    licence: Object.assign(prevState.licence, Data, { LicenceData: JSON.parse(Data.LicenceData) })
                };
            });
        } else {
            toastError(t(ERROR.SERVICE_DATA_NOT_FETCHED));
        }
    };

    getAdditionalInformationData = async () => {
        const { ID } = history.location.state;
        const response = await fetchRequest(GET_ADDITIONAL_INFORMATION_DATA_FROM_SFORMID_URL + ID, getParams(), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess && Data.length > 0) {
            return Data.reduce((allAFD, {
                AdditionalFormData, WorkflowStateId, AdditionalFormDefinition, CreatedDate, ProcessedBy, AdditionalFormDocument
            }) => {
                if (!allAFD[WorkflowStateId]) {
                    allAFD[WorkflowStateId] = [];
                }
                const additionalFormDefinitionsObj = {};
                additionalFormDefinitionsObj['Values'] = AdditionalFormData.DataList;
                additionalFormDefinitionsObj['AdditionalFormDefinition'] = AdditionalFormDefinition;
                additionalFormDefinitionsObj['CreatedDate'] = CreatedDate;
                additionalFormDefinitionsObj['ProcessedBy'] = ProcessedBy;
                additionalFormDefinitionsObj['SupportingDocument'] = AdditionalFormDocument;
                allAFD[WorkflowStateId].push(additionalFormDefinitionsObj);
                return allAFD;
            }, {});
        }
    };

    getFormInitialValues = devData => {
        const formTypeNames = {
            FormTypes: []
        };

        const licenceFieldNames = {
            SupportingDocument: {}
        };
        licenceFieldNames[devData.FormDefinition.FormType] = {};
        formTypeNames.FormTypes.push(devData.FormDefinition.FormType);

        devData.FormDefinition.Sections.forEach(section => {
            section.Fields && section.Fields.forEach(field => {
                licenceFieldNames[devData.FormDefinition.FormType][field.Name] = '';
            });

            if (section.Table != null) {
                licenceFieldNames[devData.FormDefinition.FormType][section.Name] = [];
                const newTableArrObj = {};
                section.Table.Fields.forEach(field => {
                    newTableArrObj[field.Name] = '';
                });
                if (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) {
                    licenceFieldNames[devData.FormDefinition.FormType][section.Name].push(newTableArrObj);
                }
            }
        });

        devData.FilesDefinition && devData.FilesDefinition.Fields.forEach(files => {
            licenceFieldNames.SupportingDocument[files.Name] = [];
        });

        return {
            ...formTypeNames, ...licenceFieldNames
        };
    };

    getFormInitialValuesWithPreviousValues = (devData, historyValues, supportingDocument) => {
        const formTypeNames = {
            FormTypes: []
        };

        const licenceFieldNames = {
            SupportingDocument: {}
        };

        licenceFieldNames[devData.FormDefinition.FormType] = {};
        formTypeNames.FormTypes.push(devData.FormDefinition.FormType);

        devData.FormDefinition.Sections.forEach(section => {
            section.Fields && section.Fields.forEach(field => {
                licenceFieldNames[devData.FormDefinition.FormType][field.Name] = historyValues[field.Name];
            });

            if (section.Table != null) {
                const sectionTableList = getTableListValues(Object.keys(historyValues), section.Name);
                licenceFieldNames[devData.FormDefinition.FormType][section.Name] = [];
                sectionTableList && sectionTableList[section.Name].forEach((row, rindex) => {
                    const newTableArrObj = {};
                    section.Table.Fields && section.Table.Fields.forEach(field => {
                        if (historyValues[`${row}.${field.Name}`] !== undefined &&
                        historyValues[`${row}.${field.Name}`] !== '') {
                            newTableArrObj[field.Name] = historyValues[`${row}.${field.Name}`];
                        } else {
                            newTableArrObj[field.Name] = '';
                        }
                        // if (historyValues[historyValues.length - 1][`${section.Name}.${rindex}.${field.Name}`] !== undefined &&
                        //     historyValues[historyValues.length - 1][`${section.Name}.${rindex}.${field.Name}`] !== '') {
                        //     newTableArrObj[field.Name] = historyValues[historyValues.length - 1][`${section.Name}.${rindex}.${field.Name}`];
                        // } else {
                        //     newTableArrObj[field.Name] = '';
                        // }
                    });
                    licenceFieldNames[devData.FormDefinition.FormType][section.Name].push(newTableArrObj);
                });
            }
        });
        devData.FilesDefinition && devData.FilesDefinition.Fields.forEach(field => {
            const fileList = [];
            const documents = supportingDocument;
            documents.forEach(document => {
                if (document.FileDescription === field.DisplayName) {
                    fileList.push({ fileName: document.Filename, file: null, fileKey: document.Filename });
                }
            });
            licenceFieldNames.SupportingDocument[field.Name] = fileList;
        });

        return {
            ...formTypeNames, ...licenceFieldNames
        };
    };

    getOptionsDataList = async devData => {
        const { t } = this.props;
        const listOfCategory = [];
        //loop through all the fields to collate all the options required for the entire form

        //FormDefinition
        devData.FormDefinition.Sections.forEach(formDefSection => {
            formDefSection.Fields && formDefSection.Fields.forEach(formDefField => {
                const isRemote = formDefField.DataSource !== undefined && formDefField.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(formDefField.DataParameters.Category) < 0) {
                    listOfCategory.push(formDefField.DataParameters.Category);
                }
            });
            formDefSection.Table && formDefSection.Table.Fields.forEach(formDefTableField => {
                const isRemote = formDefTableField.DataSource !== undefined && formDefTableField.DataSource === 'Remote';
                if (isRemote && listOfCategory.indexOf(formDefTableField.DataParameters.Category) < 0) {
                    listOfCategory.push(formDefTableField.DataParameters.Category);
                }
            });
        });

        const totalOptionsList = await getRemoteList(t, listOfCategory);

        this.setState({
            optionDataList: totalOptionsList
        });
    };

    addToObject = (obj, key, value, index) => {
        // Create a temp object and index variable
        const temp = {};

        // Loop through the original object
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                // If the indexes match, add the new item
                if (prop === index && key && value) {
                    temp[key] = value;
                }
                // Add the current item in the loop to the temp obj
                temp[prop] = obj[prop];
            }
        }

        // If no index, add to the end
        if (!index && key && value) {
            temp[key] = value;
        }
        return temp;

    };

    additionalFormDisplayError = (values, isDisplayErrorRequired) => {
        if (isDisplayErrorRequired) {
            if (isValidForm(this.state.validationSchema, values)) {
                this.setState({
                    additionalFieldsError: false
                });
                return false;
            } else {
                this.setState({
                    additionalFieldsError: true
                });
                return true;
            }
        } else {
            return null;
        }
    };

    render() {
        const { t } = this.props;
        const { additionalFieldsError, additionalFormDefinitionWithoutWorkflowState, additionalFormDefinitionWithWorkflowState,
            additionalFormResponseData, additionalFormSectionName, isLoaded, applicationDetails, licence, optionDataList, workFlow
        } = this.state;

        const { WorkflowStateId } = history.location.state;
        let SECTION_NAMES = {
            GENERAL_INFORMATION: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_GENERAL),
            APPLICATION_INFORMATION: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_APPLICATION),
            SUPPORTING_DOCUMENTS: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_DOCUMENTS),
            PAYMENT_INFORMATION: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_PAYMENT),
            FORM_HISTORY: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_HISTORY),
            RFA_HISTORY: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_RFA),
            CHECK_IN_CHECK_OUT_HISTORY: t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_CHECKOUTS)
        };

        const supportingDocuments = applicationDetails?.SupportingDocuments;
        const licenceDocuments = applicationDetails?.LicenceDocuments;
        const documentsFailedToGenerate = applicationDetails?.DocumentsFailedToGenerate;

        const submitCallback = async ({ response }) => {
            const { t } = this.props;
            if (response.success) {
                const { IsSuccess, RedirectURL } = response.body;
                if (IsSuccess) {
                    if (RedirectURL) {
                        navigateTo(RedirectURL, '');
                    } else {
                        navigateTo(NEXT_URL, '');
                    }
                    toastSuccess(t(SUCCESS.FORM_PROCESS_SUCCESS));
                } else {
                    toastError(t(ERROR.FORM_NOT_PROCESSED));
                }
            } else {
                toastError(t(ERROR.SERVER_UNREACHABLE));
            }
        };

        if (additionalFormDefinitionWithoutWorkflowState) {
            Object.keys(additionalFormDefinitionWithoutWorkflowState).forEach(key => {
                additionalFormDefinitionWithoutWorkflowState[key].forEach(formDefinition => {
                    const tabSectionName = formDefinition['AdditionalFormDefinition'].FormType;
                    SECTION_NAMES = this.addToObject(SECTION_NAMES, t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY, additionalFormRegex(tabSectionName))),
                        t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY, additionalFormRegex(tabSectionName))), 'PAYMENT_INFORMATION');
                });
            });
        }

        if (workFlow === WorkflowStateId) {
            SECTION_NAMES = this.addToObject(
                SECTION_NAMES, 'ADDITIONAL_INFORMATION', t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY, additionalFormRegex(additionalFormSectionName))), 'PAYMENT_INFORMATION'
            );
        }

        return (
            <React.Fragment>
                {isLoaded && <SMARTForm
                    sectionNames={SECTION_NAMES}
                    defaultSection={SECTION_NAMES.GENERAL_INFORMATION}
                    formValues={this.state.values}
                    formContext={applicationDetails ? applicationDetails.FormDefinition.FormName : ''}
                    nextURL={POST_URL}
                    serverURL={POST_URL}
                    isDummy={IS_DEMO}
                    submitCallback={submitCallback}
                    formParams={formParams('POST', CONTENT_TYPE.ADDITIONAL_FORM_DATA)}
                >
                    {({ toggleSection, toggleAllSections, sectionState, onChange, onChangeField, values,
                        modalError, modalStateError, submitForm }) => {
                        const smartFormValues = {
                            sectionState,
                            values,
                            toggleSection,
                            toggleAllSections,
                            onChange,
                            onChangeField,
                            modalError,
                            modalStateError,
                            submitForm
                        };

                        return (
                            <Layout type={LAYOUT_TYPE.FORM}
                                title={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_REVIEWAPPLICATION)}
                                footer={false}
                                titleClassName='sticky-title'
                                titleChildren={
                                    <FormProcessing
                                        isAction={true}
                                        customActions={CustomActions}
                                        toggleCheckInCheckOut={this.toggleCheckInCheckOut}
                                        smartFormValues={smartFormValues}
                                        additionalFormResponseData={additionalFormResponseData}
                                        additionalFieldsError={additionalFieldsError}
                                        additionalFormDisplayError={this.additionalFormDisplayError}
                                        additionalFormSectionName={additionalFormSectionName}
                                    />
                                }>
                                <React.Fragment>
                                    <Row className='body-content'>
                                        <Col>
                                            {documentsFailedToGenerate && documentsFailedToGenerate.length > 0 &&
                                                <p className="ml-3 text-danger text-left">
                                                    {t(ERROR.UNABLETOGENARATEDOCUMENT) + " " + documentsFailedToGenerate.join(', ')}
                                                    <br />
                                                    {t(ERROR.CONTACTAGENCYFORSUPPORT)}
                                                </p>
                                            }
                                            <SectionWrapper
                                                type={SECTION_LAYOUT_TYPE}
                                                tabs={SECTION_NAMES}
                                                sectionState={sectionState}
                                                toggleSection={toggleSection}
                                            >
                                                {/* General Info */}
                                                <GeneralInfo
                                                    toggleSection={toggleSection}
                                                    sectionState={sectionState}
                                                    sectionName={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_GENERAL)}
                                                />
                                                {/* Application Info */}
                                                <ApplicationInfo
                                                    applicationDetails={applicationDetails}
                                                    values={values}
                                                    toggleSection={toggleSection}
                                                    sectionState={sectionState}
                                                    sectionName={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_APPLICATION)}
                                                    licence={licence}
                                                />

                                                {/* Supporting Docs */}
                                                <SupportingDocuments
                                                    toggleSection={toggleSection}
                                                    sectionState={sectionState}
                                                    sectionName={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_DOCUMENTS)}
                                                    supportingDocData={supportingDocuments}
                                                    licenceDocuments={licenceDocuments}
                                                    showSelectDocumentType={true}
                                                />

                                                {/* View Additional Information */}
                                                {
                                                    additionalFormDefinitionWithoutWorkflowState && Object.keys(additionalFormDefinitionWithoutWorkflowState).map((key, index) => {
                                                        return (
                                                            <ViewAdditionalInformation
                                                                key={index}
                                                                additionalFormDefinition={additionalFormDefinitionWithoutWorkflowState[key]}
                                                                toggleSection={toggleSection}
                                                                sectionState={sectionState}
                                                            />
                                                        );
                                                    })
                                                }

                                                {/* Additional Information */}
                                                {
                                                    workFlow === WorkflowStateId && <AdditionalInformation
                                                        toggleSection={toggleSection}
                                                        sectionState={sectionState}
                                                        smartFormValues={smartFormValues}
                                                        sectionName={t(getLangKey(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY, additionalFormRegex(additionalFormSectionName)))}
                                                        additionalFormResponseData={additionalFormResponseData}
                                                        additionalFieldsError={additionalFieldsError}
                                                        optionDataList={optionDataList}
                                                        additionalFormDefinition={additionalFormDefinitionWithWorkflowState[WorkflowStateId]}
                                                    />
                                                }

                                                {/* Payment Information */}
                                                <PaymentInfo
                                                    toggleSection={toggleSection}
                                                    sectionState={sectionState}
                                                    sectionName={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_PAYMENT)}
                                                />

                                                {/* Form History */}
                                                <FormHistory
                                                    toggleSection={toggleSection}
                                                    sectionState={sectionState}
                                                    sectionName={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_HISTORY)}
                                                />

                                                {/* RFA history */}
                                                <RFAHistory
                                                    toggleSection={toggleSection}
                                                    sectionState={sectionState}
                                                    sectionName={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_RFA)}
                                                />

                                                {/* Check-In-Check-Out history */}
                                                <CheckInCheckOutHistory
                                                    //key={props.toggleCheckInCheckOut}
                                                    toggleSection={toggleSection}
                                                    sectionState={sectionState}
                                                    sectionName={t(LANGUAGE_KEYS.BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_CHECKOUTS)}
                                                />
                                            </SectionWrapper>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            </Layout>
                        );
                    }}
                </SMARTForm>
                }
            </React.Fragment>
        );
    }
}

export default withTranslation()(withLoader(withDynaFormWrapper(FormProcess)));
