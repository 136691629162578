import { APP_NAME_LICENCE_CONFIG } from '../../App/AppSettings';

export const APP_NAME = "DSP";
export const DEFAULT_APPLICATION_NAME = "DSP";

export const getLangKey = (translationKey, ...replacementKeys) => {
    //spread operator for the keys that need to be replaced in
    let arr = [...replacementKeys];
    let count = 0;

    //assign each matched substring to argument array index on FCFS basis
    const replaceMatchedSubstring = () => {
        let str = arr[count];
        count++;
        return str;
    };
    const replacedStr = translationKey.replace(/%[0-9a-zA-Z]*%/g, replaceMatchedSubstring);
    return replacedStr;
}

export const SCOPE_CAT_SUBCAT_STRINGS = {
    //DASHBOARD
    INTERNALDASHBOARD_CONTENT_TITLE: 'InternalDashboard.Content.Title',
    INTERNALDASHBOARD_CONTENT_NOTE: 'InternalDashboard.Content.Note',
    //APP ENQUIRY
    INTERNALAPPENQUIRY_TABLE_TITLE: 'InternalAppEnquiry.Table.Title',
    INTERNALAPPENQUIRY_CONTENT_TITLE: 'InternalAppEnquiry.Content.Title',
    INTERNALAPPENQUIRY_FORM_PLACEHOLDER: 'InternalAppEnquiry.Form.Placeholder',
    INTERNALAPPENQUIRY_FORM_FIELD: 'InternalAppEnquiry.Form.Field',
    INTERNALAPPENQUIRY_FORM_BUTTON: 'InternalAppEnquiry.Form.Button',
    INTERNALAPPENQUIRY_FORM_TITLE: 'InternalAppEnquiry.Form.Title',
    INTERNALAPPENQUIRY_MESSAGE_PLACEHOLDER: 'InternalAppEnquiry.Message.Placeholder',
    //LICENCE DETAILS
    INTERNALLICENCEDETAILS_CONTENT_INFORMATION: 'InternalLicenceDetails.Content.Information',
    INTERNALLICENCEDETAILS_CONTENT_MENU: 'InternalLicenceDetails.Content.Menu',
    INTERNALLICENCEDETAILS_CONTENT_LINK: 'InternalLicenceDetails.Content.Link',
    INTERNALLICENCEDETAILS_TABLE_TITLE: 'InternalLicenceDetails.Table.Title',
    INTERNALLICENCEDETAILS_TABLE_INFORMATION: 'InternalLicenceDetails.Table.Information',
    INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER: 'InternalLicenceDetails.Message.Placeholder',
    //APPLICATION DETAILS
    INTERNALAPPLICATIONDETAILS_CONTENT_TITLE: 'InternalApplicationDetails.Content.Title',
    INTERNALAPPLICATIONDETAILS_CONTENT_BUTTON: 'InternalApplicationDetails.Content.Button',
    INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION: 'InternalApplicationDetails.Content.Pagination',
    INTERNALAPPLICATIONDETAILS_TABLE_TITLE: 'InternalApplicationDetails.Table.Title',
    INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION: 'InternalApplicationDetails.Table.Information',
    INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER: 'InternalApplicationDetails.Message.Placeholder',
    INTERNALAPPLICATIONDETAILS_NAVIGATION_BUTTON: 'InternalApplicationDetails.Navigation.Button',
    //LICENCE ENQUIRY
    INTERNALLICENQUIRY_TABLE_TITLE: 'InternalLicEnquiry.Table.Title',
    INTERNALLICENQUIRY_CONTENT_TITLE: 'InternalLicEnquiry.Content.Title',
    INTERNALLICENQUIRY_FORM_PLACEHOLDER: 'InternalLicEnquiry.Form.Placeholder',
    INTERNALLICENQUIRY_FORM_BUTTON: 'InternalLicEnquiry.Form.Button',
    INTERNALLICENQUIRY_FORM_TITLE: 'InternalLicEnquiry.Form.Title',
    //INBOX
    INTERNALINBOX_TABLE_TITLE: 'InternalInbox.Table.Title',
    INTERNALINBOX_MESSAGE_INFORMATION: 'InternalInbox.Message.Information',
    INTERNALINBOX_CONTENT_TITLE: 'InternalInbox.Content.Title',
    INTERNALINBOX_NAVIGATION_FIELD: 'InternalInbox.Navigation.Field',
    //MENU
    INTERNALMENU_NAVIGATION_MENU: 'InternalMenu.Navigation.Menu',
    //CATEGORY MANAGEMENT
    INTERNALCATEGORYMGMT_FORM_PLACEHOLDER: 'InternalCategoryMgmt.Form.Placeholder',
    INTERNALCATEGORYMGMT_FORM_FIELD: 'InternalCategoryMgmt.Form.Field',
    INTERNALCATEGORYMGMT_FORM_TITLE: 'InternalCategoryMgmt.Form.Title',
    INTERNALCATEGORYMGMT_FORM_BUTTON: 'InternalCategoryMgmt.Form.Button',
    INTERNALCATEGORYMGMT_FORM_HELPTEXT: 'InternalCategoryMgmt.Form.HelpText',
    INTERNALCATEGORYMGMT_TABLE_TITLE: 'InternalCategoryMgmt.Table.Title',
    //SCOPE MANAGEMENT
    INTERNALSCOPEMGMT_FORM_TITLE: 'InternalScopeMgmt.Form.Title',
    INTERNALSCOPEMGMT_FORM_PLACEHOLDER: 'InternalScopeMgmt.Form.Placeholder',
    INTERNALSCOPEMGMT_FORM_FIELD: 'InternalScopeMgmt.Form.Field',
    INTERNALSCOPEMGMT_FORM_BUTTON: 'InternalScopeMgmt.Form.Button',
    INTERNALSCOPEMGMT_FORM_HELPTEXT: 'InternalScopeMgmt.Form.HelpText',
    INTERNALSCOPEMGMT_TABLE_TITLE: 'InternalScopeMgmt.Table.Title',
    //SUBCAT MANAGEMENT
    INTERNALSUBCATEGORYMGMT_FORM_PLACEHOLDER: 'InternalSubCategoryMgmt.Form.Placeholder',
    INTERNALSUBCATEGORYMGMT_FORM_FIELD: 'InternalSubCategoryMgmt.Form.Field',
    INTERNALSUBCATEGORYMGMT_FORM_TITLE: 'InternalSubCategoryMgmt.Form.Title',
    INTERNALSUBCATEGORYMGMT_FORM_BUTTON: 'InternalSubCategoryMgmt.Form.Button',
    INTERNALSUBCATEGORYMGMT_FORM_HELPTEXT: 'InternalSubCategoryMgmt.Form.HelpText',
    INTERNALSUBCATEGORYMGMT_TABLE_TITLE: 'InternalSubCategoryMgmt.Table.Title',
    //LANGUAGE REGISTRATION
    INTERNALLANGUAGEREGISTRATION_FORM_TITLE: 'InternalLanguageRegistration.Form.Title',
    INTERNALLANGUAGEREGISTRATION_TABLE_INFORMATION: 'InternalLanguageRegistration.Table.Information',
    INTERNALLANGUAGEREGISTRATION_TABLE_TITLE: 'InternalLanguageRegistration.Table.Title',
    INTERNALLANGUAGEREGISTRATION_FORM_PLACEHOLDER: 'InternalLanguageRegistration.Form.Placeholder',
    INTERNALLANGUAGEREGISTRATION_FORM_FIELD: 'InternalLanguageRegistration.Form.Field',
    //TRANSLATION MANAGEMENT
    INTERNALTRANSLATIONMGMT_FORM_TITLE: 'InternalTranslationMgmt.Form.Title',
    INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER: 'InternalTranslationMgmt.Form.Placeholder',
    INTERNALTRANSLATIONMGMT_FORM_FIELD: 'InternalTranslationMgmt.Form.Field',
    INTERNALTRANSLATIONMGMT_FORM_BUTTON: 'InternalTranslationMgmt.Form.Button',
    INTERNALTRANSLATIONMGMT_FORM_INFORMATION: 'InternalTranslationMgmt.Form.Information',
    //GENERAL
    INTERNALGENERAL_FORM_BUTTON: 'InternalGeneral.Form.Button',
    INTERNALGENERAL_CONTENT_ERROR: 'InternalGeneral.Content.Error',
    INTERNALGENERAL_CONTENT_SUCCESS: 'InternalGeneral.Content.Success',
    INTERNALGENERAL_CONTENT_BUTTON: 'InternalGeneral.Content.Button',
    INTERNALGENERAL_CONTENT_HELPTEXT: 'InternalGeneral.Content.HelpText',
    //LANG PACK UPDATE
    INTERNALLANGPACKUPDATE_FORM_TITLE: 'InternalLangPackUpdate.Form.Title',
    INTERNALLANGPACKUPDATE_FORM_PLACEHOLDER: 'InternalLangPackUpdate.Form.Placeholder',
    INTERNALLANGPACKUPDATE_FORM_FIELD: 'InternalLangPackUpdate.Form.Field',
    INTERNALLANGPACKUPDATE_FORM_BUTTON: 'InternalLangPackUpdate.Form.Button',
    INTERNALLANGPACKUPDATE_FORM_INFORMATION: 'InternalLangPackUpdate.Form.Information',
    INTERNALLANGPACKUPDATE_TABLE_TITLE: 'InternalLangPackUpdate.Table.Title',
    //PAYMENT INBOX
    INTERNALPAYMENTINBOX_TABLE_TITLE: 'InternalPaymentInbox.Table.Title',
    INTERNALPAYMENTINBOX_CONTENT_TITLE: 'InternalPaymentInbox.Content.Title',
    INTERNALPAYMENTINBOX_MESSAGE_INFORMATION: 'InternalPaymentInbox.Message.Information',
    INTERNALPAYMENTINBOX_NAVIGATION_FIELD: 'InternalPaymentInbox_Navigation_Field',
    INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER: 'InternalPaymentInbox.Message.Placeholder',
    INTERNALPAYMENTINBOX_CONTENT_FIELD: 'InternalPaymentInbox.Content.Field',
    INTERNALPAYMENTINBOX_CONTENT_BUTTON: 'InternalPaymentInbox.Content.Button',
    INTERNALPAYMENTINBOX_CONTENT_INFORMATION: 'InternalPaymentInbox.Content.Information',
    //REPORT HISTORY
    INTERNALREPORTHISTORY_TABLE_BUTTON: 'InternalReportHistory.Table.Button',
    INTERNALREPORTHISTORY_TABLE_TITLE: 'InternalReportHistory.Table.Title',
    INTERNALREPORTHISTORY_MESSAGE_PLACEHOLDER: 'InternalReportHistory.Message.Placeholder',
    INTERNALREPORTHISTORY_CONTENT_MODAL: 'InternalReportHistory.Content.Modal',
    INTERNALREPORTHISTORY_CONTENT_BUTTON: 'InternalReportHistory.Content.Button',
    INTERNALREPORTHISTORY_NAVIGATION_FIELD: 'InternalReportHistory.Navigation.Field',
    //LICENCE REPORT
    INTERNALLICENCEREPORT_TABLE_TITLE: 'InternalLicenceReport.Table.Title',
    INTERNALLICENCEREPORT_FORM_FIELD: 'InternalLicenceReport.Form.Field',
    //REPORT SEARCH
    INTERNALREPORTSEARCH_NAVIGATION_BUTTON: 'InternalReportSearch.Navigation.Button',
    INTERNALREPORTSEARCH_FORM_BUTTON: 'InternalReportSearch.Form.Button',
    INTERNALREPORTSEARCH_FORM_TITLE: 'InternalReportSearch.Form.Title',
    INTERNALREPORTSEARCH_MESSAGE_PLACEHOLDER: 'InternalReportSearch.Message.Placeholder',
    //PAYMENT REPORT
    INTERNALPAYMENTREPORT_TABLE_TITLE: 'InternalPaymentReport.Table.Title',
    INTERNALPAYMENTREPORT_FORM_FIELD: 'InternalPaymentReport.Form.Field',
    INTERNALPAYMENTREPORT_FORM_BUTTON: 'InternalPaymentReport.Form.Button',
    //RENEWAL REPORT
    INTERNALRENEWALREPORT_TABLE_TITLE: 'InternalRenewalReport.Table.Title',
    INTERNALRENEWALREPORT_FORM_FIELD: 'InternalRenewalReport.Form.Field',
    //LICENCE MANAGEMENT
    INTERNALLICENCEMANAGEMENT_CONTENT_TITLE: 'InternalLicenceManagement.Content.Title',
    INTERNALLICENCEMANAGEMENT_CONTENT_SECTION: 'InternalLicenceManagement.Content.Section',
    INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON: 'InternalLicenceManagement.Content.Button',
    INTERNALLICENCEMANAGEMENT_TABLE_TITLE: 'InternalLicenceManagement.Table.Title',
    INTERNALLICENCEMANAGEMENT_FORM_FIELD: 'InternalLicenceManagement.Form.Field',
    INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER: 'InternalLicenceManagement.Form.Placeholder',
    INTERNALLICENCEMANAGEMENT_FORM_TITLE: 'InternalLicenceManagement.Form.Title',
    INTERNALLICENCEMANAGEMENT_FORM_BUTTON: 'InternalLicenceManagement.Form.Button',
    INTERNALLICENCEMANAGEMENT_MESSAGE_PLACEHOLDER: 'InternalLicenceManagement.Message.Placeholder',
    INTERNALLICENCEMANAGEMENT_NAVIGATION_BUTTON: 'InternalLicenceManagement.Navigation.Button',
    //LICENCE EXTENSION
    INTERNALLICENCEEXTENSION_CONTENT_TITLE: 'InternalLicenceExtension.Content.Title',
    INTERNALLICENCEEXTENSION_CONTENT_SECTION: 'InternalLicenceExtension.Content.Section',
    INTERNALLICENCEEXTENSION_CONTENT_MODAL: 'InternalLicenceExtension.Content.Modal',
    INTERNALLICENCEEXTENSION_CONTENT_BUTTON: 'InternalLicenceExtension.Content.Button',
    INTERNALLICENCEEXTENSION_TABLE_TITLE: 'InternalLicenceExtension.Table.Title',
    INTERNALLICENCEEXTENSION_TABLE_LINK: 'InternalLicenceExtension.Table.Link',
    INTERNALLICENCEEXTENSION_FORM_FIELD: 'InternalLicenceExtension.Form.Field',
    INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER: 'InternalLicenceExtension.Form.Placeholder',
    INTERNALLICENCEEXTENSION_FORM_TITLE: 'InternalLicenceExtension.Form.Title',
    INTERNALLICENCEEXTENSION_FORM_BUTTON: 'InternalLicenceExtension.Form.Button',
    INTERNALLICENCEEXTENSION_MESSAGE_PLACEHOLDER: 'InternalLicenceExtension.Message.Placeholder',
    INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON: 'InternalLicenceExtension.Navigation.Button',
    //LICENCE RECOMMENDATION
    INTERNALLICENCERECOMMENDATION_NAVIGATION_MENU: 'InternalLicenceRecommendation.Navigation.Menu',
    INTERNALLICENCERECOMMENDATION_CONTENT_MODAL: 'InternalLicenceRecommendation.Content.Modal',
    INTERNALLICENCERECOMMENDATION_CONTENT_BUTTON: 'InternalLicenceRecommendation.Content.Button',
    INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON: 'InternalLicenceRecommendation.Navigation.Button',
    //LICENCE CONFIG
    INTERNALLICENCECONFIG_CONTENT_TITLE: 'InternalLicenceConfig.Content.Title',
    INTERNALLICENCECONFIG_CONTENT_SECTION: 'InternalLicenceConfig.Content.Section',
    INTERNALLICENCECONFIG_CONTENT_MODAL: 'InternalLicenceConfig.Content.Modal',
    INTERNALLICENCECONFIG_CONTENT_BUTTON: 'InternalLicenceConfig.Content.Button',
    INTERNALLICENCECONFIG_CONTENT_HELPTEXT: 'InternalLicenceConfig.Content.HelpText',
    INTERNALLICENCECONFIG_TABLE_TITLE: 'InternalLicenceConfig.Table.Title',
    INTERNALLICENCECONFIG_FORM_FIELD: 'InternalLicenceConfig.Form.Field',
    INTERNALLICENCECONFIG_FORM_PLACEHOLDER: 'InternalLicenceConfig.Form.Placeholder',
    INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER: 'InternalLicenceConfig.Message.Placeholder',
    INTERNALLICENCECONFIG_MESSAGE_MODAL: 'InternalLicenceConfig.Message.Modal',
    INTERNALLICENCECONFIG_NAVIGATION_BUTTON: 'InternalLicenceConfig.Navigation.Button',
    INTERNALLICENCECONFIG_NAVIGATION_FIELD: 'InternalLicenceConfig.Navigation.Field',
    INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE: 'InternalLicenceConfig.VersionStatus.Title',
    LICENCEDEFINITIONFAQ_QUESTION: 'LicenceDefinitionFAQ.Question',
    LICENCEDEFINITIONFAQ_ANSWER: 'LicenceDefinitionFAQ.Answer',
    //LICENCE APP CONFIG
    INTERNALLICENCEAPPCONFIG_CONTENT_TITLE: 'InternalLicenceAppConfig.Content.Title',
    INTERNALLICENCEAPPCONFIG_CONTENT_SECTION: 'InternalLicenceAppConfig.Content.Section',
    INTERNALLICENCEAPPCONFIG_CONTENT_MODAL: 'InternalLicenceAppConfig.Content.Modal',
    INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON: 'InternalLicenceAppConfig.Content.Button',
    INTERNALLICENCEAPPCONFIG_CONTENT_MENU: 'InternalLicenceAppConfig.Content.Menu',
    INTERNALLICENCEAPPCONFIG_CONTENT_LINK: 'InternalLicenceAppConfig.Content.Link',
    INTERNALLICENCEAPPCONFIG_TABLE_TITLE: 'InternalLicenceAppConfig.Table.Title',
    INTERNALLICENCEAPPCONFIG_FORM_FIELD: 'InternalLicenceAppConfig.Form.Field',
    INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER: 'InternalLicenceAppConfig.Form.Placeholder',
    INTERNALLICENCEAPPCONFIG_FORM_ERROR: 'InternalLicenceAppConfig.Form.Error',
    INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER: 'InternalLicenceAppConfig.Message.Placeholder',
    INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL: 'InternalLicenceAppConfig.Message.Modal',
    INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON: 'InternalLicenceAppConfig.Navigation.Button',
    INTERNALLICENCEAPPCONFIG_NAVIGATION_FIELD: 'InternalLicenceAppConfig.Navigation.Field',
    LICENCETYPEDECLARATION_DECLARATIONDESC: 'LicenceTypeDeclaration.DeclarationDescription',
    //COMMON INFO
    COMMONINFO_FORM_FIELD: 'CommonInformation.Form.Field',
    COMMONINFO_FORM_PLACEHOLDERTEXT: 'CommonInformation.Form.PlaceholderText',
    COMMONINFO_FORM_HELPTEXT: 'CommonInformation.Form.HelpText',
    COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME: 'CommonInformation.GlobalSupportingDocument.DocumentName',
    COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC: 'CommonInformation.GlobalSupportingDocument.DocumentDescription',
    COMMONINFO_FORM_SECTION: 'CommonInformation.Form.Section',
    //SORT BY LICENCE
    LICENCETYPE_LICENCETYPENAME: 'LicenceType.LicenceTypeName',
    LICENCEDEFINITION_LICENCEDESCRIPTION: 'LicenceDefinition.LicenceDescription',
    LICENCEDEFINITION_FEEDESCRIPTION: 'LicenceDefinition.FeeDescription',
    //AGENCY
    LICENCEAGENCY_AGENCY_AGENCYNAME: 'LicenceAgency.Agency.AgencyName',
    //PAYMENT
    LICENCEAPPLICATIONFEE_APPLICATIONFEE: 'LicenceApplicationFee.ApplicationFee',
    //Licence Recommendation Graph
    AI_LICENCERECOMMENDER_RULETYPE: 'AI.LicenceRecommender.RuleType',
    //SORT BY GENERALINFO
    GENERALINFORMATION_FORM_SECTION: 'GeneralInformation.Form.Section',
    GENERALINFORMATION_FORM_FIELD: 'GeneralInformation.Form.Field',
    GENERALINFORMATION_FORM_HELPTEXT: 'GeneralInformation.Form.HelpText',
    GENERALINFORMATION_FORM_PLACEHOLDERTEXT: 'GeneralInformation.Form.PlaceholderText',
    //PAYMENT TYPE
    DEFAULT_PAYMENTTYPE_PAYMENTOPTION: 'Default.PaymentType.PaymentOption',

    //Code Table DropDown List
    CODE: 'Code',
    TITLE: 'Title',
    LICENCE_APP_FEE_TYPE: 'LicenceApplicationFeeType',

    //CODE RELATED 
    CODE_LICENCEAPPLICATIONTYPE_TITLE: 'Code.LicenceApplicationType.Title',
    CODE_LICENCESTATUS_TITLE: 'Code.LicenceStatus.Title',

    //PREVIEW-SERVICE RELATED
    INTERNALPREVIEWSERVICE_MODAL_BUTTON: 'InternalPreviewService.Modal.Button',
    INTERNALPREVIEWSERVICE_MODAL_TITLE: 'InternalPreviewService.Modal.Title',
    INTERNALPREVIEWSERVICE_MODAL_CONTENT: 'InternalPreviewService.Modal.Content',
    INTERNALPREVIEWSERVICE_TABLE_BUTTON: 'InternalPreviewService.Table.Button',
    INTERNALPREVIEWSERVICE_TABLE_TOOLTIP: 'InternalPreviewService.Table.Tooltip',
    INTERNALPREVIEWSERVICE_TABLE_TITLE: 'InternalPreviewService.Table.Title',
    INTERNALPREVIEWSERVICE_MODEL_ERROR: 'InternalPreviewService.Modal.Error',
    INTERNALPREVIEWSERVICE_MODEL_SUCCESS: 'InternalPreviewService.Modal.Success',
    INTERNALPREVIEWSERVICE_TABLE_FILED: 'InternalPreviewService.Table.Field',
    INTERNALPREVIEWSERVICE_MODEL_TEXT: 'InternalPreviewService.Modal.Text',
    INTERNALPREVIEWSERVICE_TABLE_TEXT: 'InternalPreviewService.Table.Text',
    INTERNALPREVIEWSERVICE_FORM_PLACEHOLDER: 'InternalPreviewService.Form.Placeholder',
    INTERNALPREVIEWSERVICE_MESSAGE_SECTION:'InternalPreviewService.Message.Section',
    INTERNALPREVIEWSERVICE_CONTENT_TITLE: 'InternalPreviewService.Content.Title',
    INTERNALPREVIEWSERVICE_CONTENT_INFORMATION: 'InternalPreviewService.Content.Information',
    INTERNALPREVIEWSERVICE_CONTENT_MENU: 'InternalPreviewService.Content.Menu',
    INTERNALPREVIEWSERVICE_MESSAGE_PLACEHOLDER: 'InternalPreviewService.Message.Placeholder',
    INTERNALPREVIEWSERVICE_ERRORMESSAGE_PLACEHOLDERTEXT: 'InternalPreviewService.ErrorMessages.PlaceholderText',
    INTERNALPREVIEWSERVICE_CONTENT_BUTTON: 'InternalPreviewService.Content.Button',
};

export const KEY_STRINGS = {
    UNABLETOGENARATEDOCUMENT: 'UnableToGenarateDocument',
    CONTACTADMINFORSUPPORT: 'ContactAdminForSupport',
    ALLFIELDSARECOMMON: 'AllFieldsAreCommon',
    DASHBOARD: 'Dashboard',
    TASKS: 'Tasks',
    APPLICATIONS_TODAY: 'ApplicationsToday',
    PENDING_APPLICATIONS: 'PendingApplications',
    SERVICE_APPLICATION_ISSUED_TODAY: 'ServiceApplicationIssuedToday',
    REVENUE_TODAY: 'RevenueToday',
    DAILY_ISSUED_SERVICE_APPLICATIONS: 'DailyIssuedServiceApplications',
    BAR_CHART_DATES: 'BarChartDates',
    MYTASKS: 'MyTasks',
    ENQUIRY: 'Enquiry',
    REVENUE_BREAKDOWN: 'RevenueBreakdown',
    NO_CURRENT_DATA: 'NoCurrentData',
    BAR_CHART_SERVICE_APPLICATION_ISSUED: 'BarChartServiceApplicationIssued',
    NUMBER: 'Number',
    REFERENCE_NO: 'ReferenceNo',
    STATUS: 'Status',
    SUBMISSION_DATE: 'SubmissionDate',
    SUBMISSION_NO: 'SubmissionNo',
    APPLICATION_TYPE: 'ApplicationType',
    APPLICATION_STATUS: 'ApplicationStatus',
    AGENCY: 'Agency',
    FROM_DATE: 'FromDate',
    TO_DATE: 'ToDate',
    SEARCHAPP: 'SearchApp',
    APPLICATION_ENQUIRY: 'ApplicationEnquiry',
    RECORDS: 'Records',
    SERVICE: 'Service',
    SERVICE_NUMBER: 'ServiceNumber',
    SERVICE_NO: 'ServiceNo',
    SERVICE_NAME: 'ServiceName',
    SERVICE_OVERVIEW: 'ServiceOverview',
    EXPIRYDATE: 'ExpiryDate',
    APPLICANT: 'Applicant',
    SERVICE_STATUS: 'ServiceStatus',
    ISSUED_DATE: 'IssuedDate',
    PREMISE_OPERATING_ADDRESS: 'PremiseOperatingAddress',
    SERVICE_DOCUMENT: 'ServiceDocument',
    FILE_TYPE: 'FileType',
    APP_REFNO: 'AppRefNumber',
    APP_TYPE: 'AppType',
    SERVICE_APP_HISTORY: 'ServiceAppHistory',
    SERVICE_MANAGEMENT_HISTORY: 'ServiceManagementHistory',
    STATUS_TYPE: 'StatusType',
    PROCESSED_DATE: 'ProcessedDate',
    PROCESSED_BY: 'ProcessedBy',
    NO_SERVICE_STATUS_HISTORY: 'NoServiceStatusHistory',
    SEARCH: 'Search',
    APPROVAL_ENQUIRY: 'ApprovalEnquiry',
    SEARCH_SERVICES: 'SearchServices',
    SLA_DATE: 'SLADate',
    LOCKED: 'Locked',
    LAST_UPDATED: 'LastUpdated',
    NO_TASKS: 'NoTasks',
    APPLICATION: 'Application',
    DOCUMENTS: 'Documents',
    PAYMENT: 'Payment',
    HISTORY: 'History',
    RFA: 'RFA',
    CHECKOUTS: 'CheckOuts',
    GENERAL: 'General',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    DOCUMENTTYPE: 'DocumentType',
    ADD_CATEGORY: 'AddCategory',
    ADD_SCOPE: 'AddScope',
    ADD_SUBCATEGORY: 'AddSubCategory',
    SUBCATEGORY_MANAGEMENT: 'SubCategoryManagement',
    BACK: 'Back',
    SUBMIT: 'Submit',
    DEFAULT: 'Default',
    ACTIVE: 'Active',
    ACTIONS: 'Actions',
    SUBMITTED: 'Submitted',
    ISSUED: 'Issued',
    LANGUAGE: 'Language',
    REGISTERED_LANGUAGES: 'RegisteredLanguages',
    ADD_LANGUAGE: 'AddLanguage',
    LANGUAGE_REGISTRATION: 'LanguageRegistration',
    EDIT_APP_LANG: 'EditAppLang',
    LANGUAGE_SETTINGS: 'LanguageSettings',
    CATEGORY_MANAGEMENT: 'CategoryManagement',
    ADD_VALUE: 'AddValue',
    ADD_NEW_LANGUAGE_KEY: 'AddNewLanguageKey',
    TRANSLATION_MANAGEMENT: 'TranslationManagement',
    SCOPE: 'Scope',
    CATEGORY: 'Category',
    SUBCATEGORY: 'SubCategory',
    KEY: 'Key',
    ALIGN_TO_RIGHT: 'AlignToRight',
    REVIEW_INFO: 'ReviewInfo',
    LANG_PACK_UPDATE: 'LangPackUpdate',
    DOWNLOAD: 'Download',
    ADD_LANG_PACK: 'AddLangPack',
    VALIDATE: 'Validate',
    PUBLISH: 'Publish',
    PUBLISHED: 'Published',
    SORT_BY: 'SortBy',
    ADD_ENTRY: 'AddEntry',
    PUBLISH_ENTRY: 'PublishEntry',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    CLOSE: 'Close',
    FULL_KEY: 'FullKey',
    DEFAULT_LANGUAGE: 'DefaultLanguage',
    LAST_EDITED_BY: 'Lasteditedby',
    SCOPE_MANAGEMENT: 'ScopeManagement',
    HEADER: 'Header',
    PAYMENT_INBOX: 'PaymentInbox',
    NO_REPORT_RECORDS: 'NoReportRecords',
    REPORT_PARAMETERS: 'ReportParameters',
    VIEW_PARAMETER: 'ViewParameter',
    REQUESTED_DATE: 'RequestedDate',
    REQUESTED_BY: 'RequestedBy',
    DOWNLOAD_REPORT: 'DownloadReport',
    SEARCH_LICENCES: 'SearchLicences',
    ISSUED_START_DATE: 'IssuedStartDate',
    ISSUED_END_DATE: 'IssuedEndDate',
    REPORT_HISTORY: 'ReportHistory',
    PAYMENT_REPORT: 'PaymentReport',
    PAYMENT_REFNO: 'PaymentReferenceNo',
    PAYMENT_METHOD: 'PaymentMethod',
    COLLECTED_DATE: 'CollectedDate',
    SUBTOTAL: 'Subtotal',
    PAYMENT_BY: 'PaymentBy',
    RENEWAL_REPORT: 'RenewalReport',
    TIME_FROM_EXPIRY: 'TimeFromExpiry',
    FILTER: 'Filter',
    INBOX: 'Inbox',
    ADMIN: 'Admin',
    SYSTEM_CONFIGURATION: 'SystemConfiguration',
    NOTIFICATION_TEMPLATE: 'NotificationTemplate',
    REPORT_GENERATION: 'ReportGeneration',
    NETWORK_GRAPH: 'NetworkGraph',
    SERVICE_CONFIGURATION: 'ServiceConfiguration',
    SERVICE_APP_CONFIGURATION: 'ServiceAppConfiguration',
    PREVIEW_SERVICE: 'PreviewService',
    LANGUAGE_MANAGEMENT: 'LanguageManagement',
    HOME: 'Home',
    SERVICE_SUMMARY: 'ServiceSummary',
    PAYMENT_SUMMARY: 'PaymentSummary',
    CONFIGURATION: 'Configuration',
    ACCESS_MANAGEMENT: 'AccessManagement',
    FORMIOADMIN: 'FormIOAdminPortal',
    APP_CONFIG: 'AppConfig',
    CODE_TABLE: 'CodeTable',
    LANGUAGE_PACK_UPDATE: 'LanguagePackUpdate',
    APPLICATIONS: 'Applications',
    SERVICES: 'Services',
    APPROVALS: 'Approvals',
    SERVICE_MANAGEMENT: 'ServiceManagement',
    TYPE: 'Type',
    SIZE: 'Size',
    ACTION: 'Action',
    EMAIL_TEMPLATE: 'EmailTemplate',
    SMS_TEMPLATE: 'SMSTemplate',
    LETTER_TEMPLATE: 'LetterTemplate',
    PUBLIC_HOLIDAY: 'PublicHoliday',
    WORKING_HOUR: 'WorkingHour',
    ONLINE_HELP_MANAGEMENT: 'OnlineHelpManagement',
    SERVICE_EXTENSION: 'ServiceExtension',
    REF_DETAILS: 'RefDetails',
    STATUS_DETAILS: 'StatusDetails',
    PAYMENT_REFERENCE: 'PaymentReference',
    PAYMENT_DATE: 'PaymentDate',
    AMOUNT: 'Amount',
    PAYMENT_MODE: 'PaymentMode',
    STATUS_OF_FORM: 'StatusOfForm',
    ACTION_BY: 'ActionBy',
    ACTION_DATE: 'ActionDate',
    REMARKS: 'Remarks',
    RFA_TYPE: 'RFAType',
    DUE_DATE: 'DueDate',
    CREATED_BY: 'CreatedBy',
    REQUEST_DATE: 'RequestDate',
    RESPONSE_DATE: 'ResponseDate',
    RESPONSE_BY: 'ResponseBy',
    NO_RFA_HISTORY: 'NoRFAHistory',
    EMAIL: 'Email',
    DATE: 'Date',
    NO_CHECKOUT: 'NoCheckout',
    RESPONSE: 'Response',
    NO_FORM_HISTORY: 'NoFormHistory',
    NO_PAYMENT: 'NoPayment',
    NO_SERVICES_MATCHING: 'NoServicesMatching',
    NO_PAYMENTS_MATCHING: 'NoPaymentsMatching',
    NO_SERVICE_HISTORY: 'NoServiceHistory',
    NO_SERVICE_APP_HISTORY: 'NoServiceAppHistory',
    NO_RESULTS: 'NoResults',
    EXTENSION_PERIOD_TO: 'ExtensionPeriodTo',
    EXTENSION_PERIOD_FROM: 'ExtensionPeriodFrom',
    CHOOSE_DATE: 'ChooseDate',
    DATE_OF_EXTENSION: 'DateOfExtension',
    EXTENSION_PERIOD: 'ExtensionPeriod',
    ADD_NEW_SERVICE: 'AddNewService',
    NEW_SERVICE_EXTENSION: 'NewServiceExtension',
    SERVICE_VALIDITY_PERIOD: 'ServiceValidityPeriod',
    YEAR: 'Year',
    MONTH: 'Month',
    DAY: 'Day',
    DEFINED_RULES: 'DefinedRules',
    AI_ASSOCIATION_RULE_MODEL: 'AIAssociationRuleModel',
    ADD_CONNECTION: 'AddConnection',
    ADD_SERVICE_RELATION: 'AddServiceRelation',
    SOURCE: 'Source',
    RULE_TYPE: 'RuleType',
    PLEASE_SELECT: 'PleaseSelect',
    TARGET: 'Target',
    SERVICE_EXTENSION_DETAILS: 'ServiceExtensionDetails',
    SERVICE_CONFIGURATION_MANAGEMENT: 'ServiceConfigurationManagement',
    VIEW_SERVICE_VERSIONS: 'ViewServiceVersions',
    VIEW_SERVICE_APPLICATION_VERSIONS:'ViewServiceApplicationVersion',
    NEW_SERVICE: 'NewService',
    NEW_SERVICE_APPLICATION: 'NewServiceApplication',
    VIEW_SERVICE: 'ViewService',
    EDIT_SERVICE: 'EditService',
    EDIT_SERVICE_APPLICATION: 'EditServiceApplication',
    CREATE_FROM_EXISTING_VERSION: 'CreateFromExistingVersion',
    VIEW: 'View',
    EDIT: 'Edit',
    DELETE: 'Delete',
    USE_VERSION_TEMPLATE: 'UseVersionTemplate',
    ADD: 'Add',
    SERVICE_INFORMATION: 'ServiceInformation',
    PUBLISH_SERVICE: 'PublishService',
    SERVICE_DEPENDENCY: 'ServiceDependency',
    FAQ: 'FAQ',
    VIEW_FAQ: 'ViewFAQ',
    VIEW_DEPENDENCY: 'ViewDependency',
    ADD_DEPENDENCY: 'AddDependency',
    ADD_FAQ: 'AddFAQ',
    EDIT_DEPENDENCY: 'EditDependency',
    EDIT_FAQ: 'EditFAQ',
    DELETE_DEPENDENCY: 'DeleteDependency',
    DELETE_FAQ: 'DeleteFAQ',
    NEW_DEPENDENCY: 'NewDependency',
    NEW_FAQ: 'NewFAQ',
    CREATE_NEW_VERSION: 'CreateNewVersion',
    EFFECTIVE_FROM: 'EffectiveFrom',
    EFFECTIVE_TO: 'EffectiveTo',
    CREATED_DATE: 'CreatedDate',
    UPDATED_DATE: 'UpdatedDate',
    UPDATED_BY: 'UpdatedBy',
    DISPLAY_SEQUENCE: 'DisplaySequence',
    ANSWER: 'Answer',
    QUESTIONS: 'Questions',
    QUESTION: 'Question',
    DEPENDENCY_TYPE: 'DependencyType',
    NO_SERVICE_CONFIG: 'NoServiceConfig',
    NO_VERSION_FOUND: 'NoVersionFound',
    NO_DEPENDENCY: 'NoDependency',
    NO_FAQ: 'NoFAQ',
    DELETE_DEPENDENCY_MESSAGE: 'DeleteDependencyMessage',
    DELETE_FAQ_MESSAGE: 'DeleteFAQMessage',
    SERVICE_DESCRIPTION: 'ServiceDescription',
    IS_ONLINE: 'IsOnline',
    EXTERNAL_LINK: 'ExternalLink',
    SERVICE_PROCESS_TIME: 'ServiceProcessTime',
    SERVICE_PREFIX: 'ServicePrefix',
    RFA_RESPONSE_PERIOD: 'RFAResponsePeriod',
    DAYS_TO_MAKE_PAYMENT: 'DaysToMakePayment',
    TERMINATION_MIN_PERIOD: 'TerminationMinPeriod',
    CAN_BE_RENEWED: 'CanBeRenewed',
    RENEWAL_PERIOD: 'RenewalPeriod',
    LATE_PENALTY_PERIOD: 'LatePenaltyPeriod',
    SERVICE_FEE_DESC: 'ServiceFeeDesc',
    WORKING_DAYS: 'WorkingDays',
    DAYS: 'Days',
    SERVICE_APP_CONFIGURATION_MANAGEMENT: 'ServiceApplicationConfigurationManagement',
    VIEW_SERVICE_APPLICATION: 'ViewServiceApplication',
    PREVIEW: 'Preview',
    ADD_DECLARATION: 'AddDeclaration',
    ADD_DOCUMENT: 'AddDocument',
    ADD_PAYMENT_ITEM: 'AddPaymentItem',
    ADD_SERVICE_DOCUMENT: 'AddServiceDocument',
    VIEW_SERVICE_DOCUMENT: 'ViewServiceDocument',
    APPLICATION_DETAILS: 'ApplicationDetails',
    APPLICATION_DETAILS_OFFICER: 'ApplicationDetailsOfficer',
    PAYMENT_INFORMATION: 'PaymentInformation',
    DECLARATION: 'Declaration',
    FORMIOURL: 'FormIOUrl',
    SUPPORTING_DOCUMENT: 'SupportingDocument',
    APPLICATION_FIELDS: 'ApplicationFields',
    ADDITIONAL_APPLICATION_FORMS: 'AdditionalApplicationForms',
    VIEW_DECLARATION: 'ViewDeclaration',
    VIEW_DOCUMENT: 'ViewDocument',
    VIEW_ADDITIONAL_APP_FIELDS: 'ViewAdditionalAppFields',
    VIEW_ADDITIONAL_APP_DOCUMENT: 'ViewAdditionalAppDocument',
    VIEW_PAYMENT_ITEM: 'ViewPaymentItem',
    ADD_SUPPORTING_DOCUMENT: 'AddSupportingDocument',
    ADD_ADDITIONAL_APP_FIELDS: 'AddAdditionalAppFields',
    ADD_SUPPORTING_DOCUMENTS: 'AddSupportingDocuments',
    ADD_ADDITIONAL_APP_SUPPORTINGDOC: 'AddAdditionalAppSupportingDoc',
    EDIT_ADDITIONAL_APP_FIELDS: 'EditAdditionalAppFields',
    EDIT_DECLARATION: 'EditDeclaration',
    EDIT_SUPPORTING_DOCUMENT: 'EditSupportingDocument',
    EDIT_PAYMENT_ITEM: 'EditPaymentItem',
    ADD_NEW_SERVICE_APP: 'AddNewServiceApp',
    PREVIOUS: 'Previous',
    MANDATORY: 'Mandatory',
    WORKFLOW_STATE: 'WorkflowState',
    PLACEHOLDER_VALUE: 'PlaceholderValue',
    PLACEHOLDER_DESCRIPTION: 'PlaceholderDescription',
    DISPLAY_TITLE: 'DisplayTitle',
    REQUIRED_ACTIONS: 'RequiredActions',
    USER_ROLES: 'UserRoles',
    FILE: 'File',
    PAYMENT_ITEM_DESC: 'PaymentItemDesc',
    PAYMENT_TYPE: 'PaymentType',
    PAYMENT_AMOUNT: 'PaymentAmount',
    INCLUDE_TAX: 'IncludeTax',
    DOCUMENT_DESCRIPTION: 'DocumentDescription',
    DELETE_PAYMENT: 'DeletePayment',
    DELETE_RECORD: 'DeleteRecord',
    INTERNAL_WORKFLOW_SCHEME_NAME: 'InternalWorkflowSchemeName',
    FORM_DATA_NEEDED_BY_INERNAL_WORKFLOW_SCHEME: 'FormDataNeededByInternalWorkflowScheme',
    PUBLIC_WORKFLOW_SCHEME_NAME: 'PublicWorkflowSchemeName',
    FORM_DATA_NEEDED_BY_PUBLIC_WORKFLOW_SCHEME: 'FormDataNeededByPublicWorkflowScheme',
    DECLARATION_REQUIRED: 'DeclarationRequired',
    SELECT_SERVICE: 'SelectService',
    NEXT: 'Next',
    YES: 'Yes',
    NO: 'No',
    EDIT_SERVICE_DOCUMENT: 'EditServiceDocument',
    NO_SERVICE_SELECTED: 'NoServiceSelected',
    NO_DOCUMENT_PLACEHOLDER: 'NoDocumentPlaceholder',
    DOCUMENT_FILE: 'DocumentFile',
    DOCUMENT_HINT: 'DocumentHint',
    NO_PAYMENT_ITEM: 'NoPaymentItem',
    NO_ADDITIONAL_APP_FORM: 'NoAdditionalAppForm',
    NO_SUPPORTINGDOC: 'NoSupportingDoc',
    ASSOCIATED_WORKFLOW_STATE: 'AssociatedWorkflowState',
    USER_ROLES_FORM_VIEW: 'UserRolesFormView',
    UPLOAD_FORM_FIELDS: 'UploadFormFields',
    SAVE_AND_CLOSE: 'SaveAndClose',
    SAVE_AND_ADD_NEW_FORM: 'SaveAndAddNewForm',
    OPTIONAL: 'Optional',
    NO_DECLARATION: 'NoDeclaration',
    SUPPORTINGDOC_NAME: 'SupportingDocName',
    IS_MANDATORY: 'IsMandatory',
    UPLOAD_FILE: 'UploadFile',
    HERE: 'Here',
    CLICK: 'Click',
    DOWNLOAD_EXCEL_MESSAGE: 'DownloadExcelMessage',
    ACCESS_FORM_GENERATOR: 'AccessFormGenerator',
    NUMBER_OF_DAYS: 'NumberOfDays',
    NUMBER_OF_WORKING_DAYS: 'NumberOfWorkingDays',
    PAYMENT_DESCRIPTION: 'PaymentDescription',
    DOCUMENT_NAME: 'DocumentName',
    UPLOADED_DOCUMENT: 'UploadedDocument',
    FILE_NAME: 'FileName',
    DOWNLOAD_FILE: 'DownloadFile',
    EXISTED_DOCUMENT_NAME: 'ExistedDocumentName',
    DOCUMENT_NAME_MAXIMUM_LIMIT_EXCEED: 'DocumentNameMaximumLimitExceed',
    DOCUMENT_DESC_MAXIMUM_LIMIT_EXCEED: 'DocumentDescMaximumLimitExceed',
    SERVICE_LIST: 'ServiceList',
    REVOKE: 'Revoke',
    EDIT_SUSPENSION_DETAILS: 'EditSuspensionDetails',
    REINSTATE: 'Reinstate',
    SUSPEND: 'Suspend',
    REVOCATION_REMARKS: 'RevocationRemarks',
    REINSTATEMENT_REMARKS: 'ReinstatementRemarks',
    REVOKE_SERVICE: 'RevokeService',
    SUSPEND_SERVICE: 'SuspendService',
    REINSTATE_SERVICE: 'ReinstateService',
    SUSPENSION_END_DATE: 'SuspensionEndDate',
    SUSPENSION_REMARKS: 'SuspensionRemarks',
    AGENCY2: 'Agency2',
    ISSUED_DATE2: 'IssuedDate2',
    EXPIRYDATE2: 'ExpiryDate2',
    REF: 'Ref',
    REDIRECT_PAYMENT: 'RedirectPayment',
    RECEIVE_PAYMENT: 'ReceivePayment',
    PRINT_RECEIPT: 'PrintReceipt',
    RETRIEVE_PAYMENT_DETAILS: 'RetrievePaymentDetails',
    APPLICATION_SUBMISSION_COMPLETE: 'ApplicationSubmissionComplete',
    ORDER_ID: 'OrderID',
    PAYMENT_SUCESSFUL: 'PaymentSucessful',
    APPLICATION_NO: 'ApplicationNo',
    MODE_PAYMENT: 'ModePayment',
    VIEW_REMARKS: 'ViewRemarks',
    VIEW_SEARCH_PARAM: 'ViewSearchParam',
    DELETE_DECLARATION: 'DeleteDeclaration',
    DELETE_DECLARATION_MESSAGE: 'DeleteDeclarationMessage',
    EDIT_ADDITIONAL_APP_SUPPORTINGDOC: 'EditAdditionalAppSupportingDoc',
    END_DATE: 'EndDate',
    ENTITY: 'Entity',
    REMARK: 'Remark',
    CANCELLATION_INFORMATION: 'CancellationInformation',
    EFFECTIVE_END_DATE: 'EffectiveEndDate',
    PAY_APPLICATION: 'PayApplication',
    CURRENCY_SYMBOL: 'CurrencySymbol',
    PAYMENTS: 'Payments',
    GRAND_TOTAL: 'GrandTotal',
    TAX: 'Tax',
    FEES: 'Fees',
    SUB_TOTAL: 'SubTotal',
    PAYMENT_SUCCESS: 'PaymentSuccess',
    NO_PAYMENT_REQUIRED: 'NoPaymentRequired',
    PAYMENT_TEXT: 'PaymentText',
    CHANGE_USERNAME: 'ChangeUsername',
    CHANGE_PASSWORD: 'ChangePassword',
    CHANGE_SECURITY_INFO: 'ChangeSecurityInfo',
    LOGOUT: 'LogOut',
    LOGIN: 'Login',
    NO_FILES_UPLOADED: 'NoFilesUploaded',
    DOWNLOAD_LICENCE: 'DownloadLicence',
    SERVICE_DATA_NOT_FETCHED: 'ServiceDataNotFetched',
    STATUS_DATA_NOT_FETCHED: 'StatusDataNotFetched',
    SUPPORTING_DOCS_NOT_FETCHED: 'SupportingDocsNotFetched',
    FORM_NOT_PROCESSED: 'FormNotProcessed',
    INVALID_SELECTED_ITEM: 'InvalidSelectedItem',
    PREVIEW_NO_FILE_FOUND: 'PreviewNoFileFound',
    REINSTATEMENT_NOT_PROCESSED: 'ReinstatementNotProcessed',
    REVOCATION_NOT_PROCESSED: 'RevocationNotProcessed',
    RECOMMEND_RULE_NOT_UPDATED: 'RecommendRuleNotUpdated',
    REPORT_HISTORY_LOAD_FAIL: 'ReportHistoryLoadFail',
    PLEASE_UPLOAD_FILE: 'PleaseUploadFile',
    FILE_PROCESS_FAIL: 'FileProcessFail',
    APP_CONFIG_NOT_ADDED: 'AppConfigNotAdded',
    PREV_END_DATE_NOT_FETCHED: 'PrevEndDateNotFetched',
    SERVER_UNREACHABLE2: 'ServerUnreachable2',
    NOTIF_TEMPLATE_NOT_ADDED: 'NotificationTemplateNotAdded',
    NOTIF_TEMPLATE_NOT_UPDATED: 'NotificationTemplateNotUpdated',
    HELP_TREE_NOT_CREATED: 'HelpTreeNotCreated',
    HELP_TREE_NOT_UPDATED: 'HelpTreeNotUpdated',
    PUBLIC_HOLIDAY_NOT_ADDED: 'PublicHolidayNotAdded',
    PUBLIC_HOLIDAY_NOT_UPDATED: 'PublicHolidayNotUpdated',
    WORKING_HOUR_NOT_ADDED: 'WorkingHourNotAdded',
    EXCEPTION_DATE_NOT_ADDED: 'ExceptionDateNotAdded',
    SOMETHING_WENT_WRONG: 'SomethingWentWrong',
    EXCEPTION_NOT_UPDATED: 'ExceptionNotUpdated',
    FORM_PROCESS_SUCCESS: 'FormProcessSuccess',
    REINSTATE_SERVICE_SUCCESS: 'ReinstateServiceSuccess',
    REVOKE_SERVICE_SUCCESS: 'RevokeServiceSuccess',
    SUSPEND_SERVICE_SUCCESS: 'SuspendServiceSuccess',
    RECOMMEND_RULE_UPDATE_SUCCESS: 'RecommendRuleUpdateSuccess',
    SERVICE_SUSPENSION_UPDATE_SUCCESS: 'ServiceSuspensionUpdateSuccess',
    DELETED_SUCCESSFULLY: 'DeletedSuccessfully',
    NOTIF_TEMPLATE_ADD_SUCCESS: 'NotificationTemplateAddSuccess',
    NOTIF_TEMPLATE_UPDATE_SUCCESS: 'NotificationTemplateUpdateSuccess',
    HELP_TREE_CREATE_SUCCESS: 'HelpTreeCreateSuccess',
    HELP_TREE_UPDATE_SUCCESS: 'HelpTreeUpdateSuccess',
    PUBLIC_HOLIDAY_ADD_SUCCESS: 'PublicHolidayAddSuccess',
    PUBLIC_HOLIDAY_UPDATE_SUCCESS: 'PublicHolidayUpdateSuccess',
    WORKING_HOUR_ADD_SUCCESS: 'WorkingHourAddSuccess',
    WORKING_HOUR_UPDATE_SUCCESS: 'WorkingHourUpdateSuccess',
    EXCEPTION_DATE_ADD_SUCCESS: 'ExceptionDateAddSuccess',
    CATEGORY_DELETE_SUCCESS: 'CategoryDeleteSuccess',
    EXCEPTION_DELETE_SUCCESS: 'ExceptionDeleteSuccess',
    EXCEPTION_UPDATE_SUCCESS: 'ExceptionUpdateSuccess',
    FAQ_SET_CREATED_SUCCESS: 'FAQSetCreatedSuccess',
    PAYMENT_ITEM_REQUIRED: 'PaymentItemRequired',
    SERVICE_WORKER_REG_ERROR: 'ServiceWorkerRegistrationError',
    ENQUIRY_PROCESS_FAIL: 'EnquiryProcessFail',
    REPORT_GENERATE_FAIL: 'ReportGenerateFail',
    PREPARE_PAYMENT_FAIL: 'PreparePaymentFail',
    PROCESS_PAYMENT_FAIL: 'ProcessPaymentFail',
    FILE_FORMAT_ERROR: 'FileFormatError',
    FILE_SIZE_ERROR: 'FileSizeError',
    PAYMENT_FAIL: 'PaymentFail',
    CANNOT_GET_PAYMENT_DETAILS: 'PaymentDetailsFetchFail',
    PAYMENT_DETAILS_NOT_FETCHED: 'CannotGetPaymentDetails',
    NO_PAYMENT_ADDED: 'NoPaymentAdded',
    LABEL_REQUIRED: 'LabelRequired',
    MIN_VALUE: 'MinValue',
    MAX_VALUE: 'MaxValue',
    TICK_REQUIRED: 'TickRequired',
    FILE_LIMIT_REACHED: 'FileLimitReached',
    FILE_REQUIRED: 'FileRequired',
    FILE_DESC_REQUIRED: 'FileDescRequired',
    FORM_VALIDATION_FAIL: 'FormValidationFail',
    FORM_DEFAULT_FAIL: 'FormDefaultFailed',
    PASSWORD_REGEX_UNMATCH: 'PasswordRegexUnmatch',
    EMAIL_INVALID: 'EmailInvalid',
    MIN_LENGTH_LIMIT: 'MinLengthLimit',
    MAX_LENGTH_LIMIT: 'MaxLengthLimit',
    TELEPHONE_INVALID: 'TelephoneInvalid',
    NUMBER_INVALID: 'NumberInvalid',
    APPLICATION_DELETE_FAIL: 'ApplicationDeleteFail',
    LIST_RETRIEVAL_FAIL: 'ListRetrievalFail',
    DRAFT_SAVED_FAIL: 'DraftSavedFail',
    DRAFT_EMPTY_FIELD: 'DraftEmptyField',
    DATE_INVALID: 'DateInvalid',
    TIME_INVALID_WE: 'TimeInvalidWE',
    TIME_INVALID_BS: 'TimeInvalidBS',
    TIME_INVALID_BSWE: 'TimeInvalidBSWE',
    TIME_INVALID_BE: 'TimeInvalidBE',
    SERVER_UNREACHABLE: 'ServerUnreachable',
    TREE_QUESTION_SET_EMPTY: 'TreeQuestionSetEmpty',
    TREE_QUESTION_SET_ANSWER_EMPTY: 'TreeQuestionSetAnswerEmpty',
    CREATE_DRAFT_SUCCESS : 'CreateDraftSuccess',
    FORM_DEFAULT_SUCCESS: 'FormDefaultSuccess',
    APPLICATION_DELETE_SUCCESS: 'ApplicationDeleteSuccess',
    CREATE_SUCCESS: 'CreateSuccess',
    UPDATE_SUCCESS: 'UpdateSuccess',
    DELETE_SUCCESS: 'DeleteSuccess',
    PUBLISH_SUCCESS: 'PublishSuccess',
    REVERT_SUCCESS: 'RevertSuccess',
    CREATE: 'Create',
    SAVE: 'Save',
    NEW: 'New',
    SAVE_CHANGES: 'SaveChanges',
    CREATE_DRAFT: 'CreateDraft',
    DELETE_DRAFT: 'DeleteDraft',
    SEQUENCE_ERROR: 'SequenceError',
    PAYMENT_ITEMS_REQUIRED: 'PaymentItemsRequired',
    VALUE_REQUIRED: 'ValueRequired',
    SELECT_DECLARATION: 'SelectDeclaration',
    DAY_REQUIRED: 'DayRequired',
    MONTH_REQUIRED: 'MonthRequired',
    YEAR_REQUIRED: 'YearRequired',
    EFFECTIVE_DATE_ERROR: 'EffectiveDateError',
    VALID_NUMBER: 'ValidNumber',
    VALID_NUM_GREATER_ZERO: 'ValidNumGreaterZero',
    VALID_NUM_ZERO_TO_TWELVE: 'ValidNumZeroToTwelve',
    VALID_NUM_ZERO_TO_THIRTYONE: 'ValidNumZeroToThirtyOne',
    REMARKS_REQUIRED: 'RemarksRequired',
    DATE_LATER_THAN_EXPIRY: 'DateLaterThanExpiry',
    DATE_EQUAL_PREVIOUS_SUSPENSION: 'DateEqualPreviousSuspension',
    START_DATE_BEFORE_END: 'StartDateBeforeEnd',
    UPDATE: 'Update',
    DETAILS: 'Details',
    CHARACTERS_REMAINING: 'CharactersRemaining',
    DOWNLOAD_LIST: 'DownloadList',
    DOWNLOAD_EXCEL_TEMPLATE: 'DownloadExcelTemplate',
    SERVICE_EXTENSION_LIST: 'ServiceExtensionList',
    NO_FILE_FOUND: 'NoFileFound',
    ADDITIONAL_SUPPORTING_DOCUMENTS: 'AdditionalSupportingDocuments',
    REVIEW_APPLICATION: 'ReviewApplication',
    REQUEST_FOR_CLARIFICATION: 'RequestForClarification',
    PAYMENT_ITEM: 'PaymentItem',
    PAYMENT_ITEMS: 'PaymentItems',
    SELECT_PAYMENT_ITEM: 'SelectPaymentItem',
    TAX_AMOUNT: 'TaxAmount',
    FEE_AMOUNT: 'FeeAmount',
    TOTAL_PAYABLE_AMOUNT: 'TotalPayableAmount',
    NO_PAYMENT_ITEM_ADDED: 'NoPaymentItemAdded',
    FAQ_MANAGEMENT: 'FAQManagement',
    ADD_TESTERS: 'AddTesters',
    ADD_TESTER: 'AddTester',
    CREATE_TEST_ACCOUNTS: 'CreateTestAccounts',
    EDIT_TEST_ACCOUNTS: 'EditTestAccounts',
    TESTER_ROLES: 'TesterRoles',
    USER_TYPE: 'UserType',
    USER_NAME: 'UserName',
    AGENCY_NAME: 'AgencyName',
    PUBLISH_STATUS_TO_LIVE: 'PublishStatusToLive',
    LAST_PUBLISHED_TO_PREVIEW:"LastPublishedToPreview",
    PUBLISH_STATUS_TO_PREVIEW:"PublishStatusToPreview",
    TEST_ACCOUNTS:"TestAccounts",
    NO_RESULTS_MESSAGE:"NoResultsMessage",
    PUBLISHED_BY: "PublishedBy",
    PUBLISHED_DATE: "PublishedDate",
    NO_DUPPLICATE_EMAIL_MESSAGE:'NoDuplicateEmailMessage',
    MINIMAL_TEST_ACCOUNT_ERROR_MESSAGE: 'MinimalTestAccountErrorMessage',
    ADD_TEST_ACCOUNT_SUCCESS_MESSAGE: 'AddTestAccountSuccessMessage',
    ADD_TEST_ACCOUNT_DEFAULT_MESSAGE: 'AddTestAccountSuccessDefaultMessage',
    EDIT_TEST_ACCOUNT_TOOLTIP: 'EditTestAccount',
    DELETE_TEST_ACCOUNT_TOOLTIP: 'DeleteTestAccount',
    COMPLETE_TESTING_BY:'CompleteTestingBy',
    ADD_NEW_TEST_ACCOUNTS: 'AddNewTestAccounts',
    UPDATE_TEST_ACCOUNTS: 'UpdateTestAccounts',
    DELETE_TEST_ACCOUNTS: 'DeleteTestAccounts',
    NO_TEST_ACCOUNT_RESULT: 'NoTestAccountResult',
    MODAL_CONFIRM_TEST_ACCOUNT_TRUE:'ModelConfirmTestAccountTrue',
    MODAL_CONFIRM_TEST_ACCOUNT_FALSE:'ModelConfirmTestAccountFalse',
    TESTER_ROLE:'TesterRole',
    TESTER_EMAIL:'TesterEmail',
    NO_SERVICE_RESULT:'NoServiceResult',
    VERSION_NO:'VersionNo',
    CHECK_BOX: 'Checkbox',
    PREVIEW_SERVICE_INFORMATION:'PreviewServiceInformation',
    PREVIEW_SERVICE_DRAFT_TAB_TITLE: 'PreviewServiceDraftTabTitle',
    PUBLIC_PREVIEW_SITE:'PublicPreviewSite',
    INTRANET_PREVIEW_SITE:'IntranetPreviewSite',
    PREVIEW_SERVICE_PREVIEW_TAB_TITLE:'PreviewServicePreviewTabTitle',
    PREVIEW_SERVICE_NOTE:'PreviewServiceNote',
    PREVIEW_SERVICE_NOTE_MESSAGE:'PreviewServiceNoteMessage',
    SELECT_SERVICE_TO_PREVIEW: 'SelectServiceToPreview',
    SELECT_SERVICE_TO_LIVE: 'SelectServiceToLive',
    PUBLISH_FOR_PREVIEW: 'PublishForPreview',
    PUBLISH_FOR_LIVE: 'PublishForLive',
    CONFIRM_PUBLISH_FOR_PREVIEW: 'ConfirmPublishForPreview',
    CONFIRM_PUBLISH_FOR_LIVE: 'ConfirmPublishForLive',
    CONFIGURATION_HEADER: 'ConfigurationHeader',
    FORMGENERATOR: 'FormGeneratorTitle',
}


export const LANGUAGE_KEYS = {
    //INTERNAL DASHBOARD
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_DASHBOARD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.DASHBOARD}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_TASKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.TASKS}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_APPLICATIONSTODAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.APPLICATIONS_TODAY}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_PENDINGAPPLICATIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.PENDING_APPLICATIONS}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_SERVICEAPPLICATIONISSUEDTODAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.SERVICE_APPLICATION_ISSUED_TODAY}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_REVENUETODAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.REVENUE_TODAY}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_REVENUEBREAKDOWN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.REVENUE_BREAKDOWN}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_DAILYISSUEDSERVICEAPPLICATIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.DAILY_ISSUED_SERVICE_APPLICATIONS}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_NOCURRENTDATA: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.NO_CURRENT_DATA}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_BARCHARTDATES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.BAR_CHART_DATES}`,
    BLS_INTERNALDASHBOARD_CONTENT_TITLE_BARCHARTSERVICEAPPLICATIONISSUED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALDASHBOARD_CONTENT_TITLE}.${KEY_STRINGS.BAR_CHART_SERVICE_APPLICATION_ISSUED}`,
    //COMMON INFO
    BLS_COMMONINFO_FORM_FIELD_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_FIELD}.%Key%`,
    BLS_COMMONINFO_FORM_HELPTEXT_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_HELPTEXT}.%Key%`,
    BLS_COMMONINFO_FORM_PLACEHOLDERTEXT_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_PLACEHOLDERTEXT}.%Key%`,
    BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_GLOBALSUPPORTINGDOC_DOCNAME}.%Key%`,
    BLS_COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_GLOBALSUPPORTINGDOC_DOCDESC}.%Key%`,
    BLS_COMMONINFO_FORM_SECTION_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_SECTION}.%Key%`,
    BLS_COMMONINFO_FORM_SECTION_ALLFIELDSARECOMMON: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.COMMONINFO_FORM_SECTION}.${KEY_STRINGS.ALLFIELDSARECOMMON}`,
    //SORT BY SCOPE AND KEY
    BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCETYPE_LICENCETYPENAME}.%Key%`,
    BLS_SCOPE_LICENCEDEFINITION_LICENCEDESCRIPTION_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEDEFINITION_LICENCEDESCRIPTION}.%Key%`,
    BLS_SCOPE_LICENCEDEFINITION_LICENCEFEEDESCRIPTION_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEDEFINITION_FEEDESCRIPTION}.%Key%`,
    //APP ENQUIRY
    BLS_INTERNALAPPENQUIRY_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALAPPENQUIRY_TABLE_TITLE_REFNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_TABLE_TITLE}.${KEY_STRINGS.REFERENCE_NO}`,
    BLS_INTERNALAPPENQUIRY_TABLE_TITLE_SUBMISSIONNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_TABLE_TITLE}.${KEY_STRINGS.SUBMISSION_NO}`,
    BLS_INTERNALAPPENQUIRY_TABLE_TITLE_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_TABLE_TITLE}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALAPPENQUIRY_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALAPPENQUIRY_TABLE_TITLE_SUBMISSIONDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_TABLE_TITLE}.${KEY_STRINGS.SUBMISSION_DATE}`,
    BLS_INTERNALAPPENQUIRY_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALAPPENQUIRY_CONTENT_TITLE_APPENQUIRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_CONTENT_TITLE}.${KEY_STRINGS.APPLICATION_ENQUIRY}`,
    BLS_INTERNALAPPENQUIRY_FORM_FIELD_SUBMISSIONNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_FIELD}.${KEY_STRINGS.SUBMISSION_NO}`,
    BLS_INTERNALAPPENQUIRY_FORM_FIELD_REFNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_FIELD}.${KEY_STRINGS.REFERENCE_NO}`,
    BLS_INTERNALAPPENQUIRY_FORM_FIELD_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_FIELD}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALAPPENQUIRY_FORM_FIELD_AGENGY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_FIELD}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALAPPENQUIRY_FORM_FIELD_APPLICATIONSTATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_FIELD}.${KEY_STRINGS.APPLICATION_STATUS}`,
    BLS_INTERNALAPPENQUIRY_FORM_FIELD_FROMDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_FIELD}.${KEY_STRINGS.FROM_DATE}`,
    BLS_INTERNALAPPENQUIRY_FORM_FIELD_TODATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_FIELD}.${KEY_STRINGS.TO_DATE}`,
    BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_SUBMISSIONNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.SUBMISSION_NO}`,
    BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_REFNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.REFERENCE_NO}`,
    BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_APPLICATIONSTATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.APPLICATION_STATUS}`,
    BLS_INTERNALAPPENQUIRY_FORM_PLACEHOLDER_DATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.DATE}`,
    BLS_INTERNALAPPENQUIRY_FORM_TITLE_RECORDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_TITLE}.${KEY_STRINGS.RECORDS}`,
    BLS_INTERNALAPPENQUIRY_FORM_BUTTON_SEARCHAPP: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_FORM_BUTTON}.${KEY_STRINGS.SEARCHAPP}`,
    BLS_INTERNALAPPENQUIRY_MESSAGE_PLACEHOLDER_NORESULTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPENQUIRY_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_RESULTS}`,
    //APPLICATION DETAILS
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_KEY: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.%Key%`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_HEADER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.HEADER}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_REFDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.REF_DETAILS}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_CANCELLATIONINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.CANCELLATION_INFORMATION}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_STATUSDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.STATUS_DETAILS}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_ADDITIONALSUPPORTINGDOCUMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.ADDITIONAL_SUPPORTING_DOCUMENTS}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_BUTTON_VIEWREMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_BUTTON}.${KEY_STRINGS.VIEW_REMARKS}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_APPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION}.${KEY_STRINGS.APPLICATION}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_DOCUMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION}.${KEY_STRINGS.DOCUMENTS}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_PAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION}.${KEY_STRINGS.PAYMENT}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_HISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION}.${KEY_STRINGS.HISTORY}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_RFA: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION}.${KEY_STRINGS.RFA}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_CHECKOUTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION}.${KEY_STRINGS.CHECKOUTS}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION_GENERAL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_PAGINATION}.${KEY_STRINGS.GENERAL}`,
    BLS_INTERNALAPPLICATIONDETAILS_NAVIGATION_BUTTON_BACK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_NAVIGATION_BUTTON}.${KEY_STRINGS.BACK}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_DOCUMENTNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.DOCUMENT_NAME}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_DOCUMENTTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.DOCUMENTTYPE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_SIZE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.SIZE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_TYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.TYPE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_PAYMENTREFERENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_REFERENCE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_PAYMENTDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_DATE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_AMOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.AMOUNT}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_PAYMENTMODE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_MODE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_EMAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.EMAIL}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_ACTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.ACTION}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_TITLE_DATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_TITLE}.${KEY_STRINGS.DATE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_STATUSOFFORM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.STATUS_OF_FORM}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.ACTION}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTIONBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.ACTION_BY}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTIONDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.ACTION_DATE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.REMARKS}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_RFATYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.RFA_TYPE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_DUEDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.DUE_DATE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_CREATEDBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.CREATED_BY}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_REQUESTDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.REQUEST_DATE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_RESPONSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.RESPONSE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_RESPONSEBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.RESPONSE_BY}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_RESPONSEDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.RESPONSE_DATE}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.ACTIONS}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_SUBMITTED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.SUBMITTED}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_ISSUED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.ISSUED}`,
    BLS_INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION_EFFECTIVEENDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.EFFECTIVE_END_DATE}`,
    BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NORFAHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_RFA_HISTORY}`,
    BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NOCHECKOUT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_CHECKOUT}`,
    BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NOPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_PAYMENT}`,
    BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NOFORMHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_FORM_HISTORY}`,
    BLS_INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER_NOFILESUPLOADED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_FILES_UPLOADED}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_REVIEWAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.REVIEW_APPLICATION}`,
    BLS_INTERNALAPPLICATIONDETAILS_CONTENT_TITLE_REQUESTFORCLARIFICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALAPPLICATIONDETAILS_CONTENT_TITLE}.${KEY_STRINGS.REQUEST_FOR_CLARIFICATION}`,
    //LICENCE DETAILS
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICENUM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.SERVICE_NUMBER}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_SERVICEOVERVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_MENU}.${KEY_STRINGS.SERVICE_OVERVIEW}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_APPLICANT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.APPLICANT}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_SERVICESTATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.SERVICE_STATUS}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.ISSUED_DATE}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_ENTITY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.ENTITY}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_PREMISEOPERATINGADDRESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.PREMISE_OPERATING_ADDRESS}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_INFORMATION_DETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_INFORMATION}.${KEY_STRINGS.DETAILS}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_SERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_MENU}.${KEY_STRINGS.SERVICE_DOCUMENT}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_LINK_DOWNLOADLICENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_LINK}.${KEY_STRINGS.DOWNLOAD_LICENCE}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_SERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.SERVICE_DOCUMENT}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_FILETYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.FILE_TYPE}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.ISSUED_DATE}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_SERVICEAPPHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_MENU}.${KEY_STRINGS.SERVICE_APP_HISTORY}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_APPREFNUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.APP_REFNO}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_APPTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.APP_TYPE}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_SUBMISSIONDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.SUBMISSION_DATE}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_INFORMATION_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_INFORMATION}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCEDETAILS_CONTENT_MENU_SERVICEMANAGEMENTHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_CONTENT_MENU}.${KEY_STRINGS.SERVICE_MANAGEMENT_HISTORY}`,
    BLS_INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER_NOSERVICESTATUSHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_SERVICE_STATUS_HISTORY}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_STATUSTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.STATUS_TYPE}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_PROCESSEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.PROCESSED_DATE}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_PROCESSEDBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.PROCESSED_BY}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_REMARK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.REMARK}`,
    BLS_INTERNALLICENCEDETAILS_TABLE_TITLE_ENDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_TABLE_TITLE}.${KEY_STRINGS.END_DATE}`,
    BLS_INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER_NOSERVICEHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_SERVICE_HISTORY}`,
    BLS_INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER_NOSERVICEAPPHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEDETAILS_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_SERVICE_APP_HISTORY}`,
    //LICENCE ENQUIRY
    BLS_INTERNALLICENQUIRY_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLICENQUIRY_TABLE_TITLE_SERVICENO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NO}`,
    BLS_INTERNALLICENQUIRY_TABLE_TITLE_SERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_TABLE_TITLE}.${KEY_STRINGS.SERVICE}`,
    BLS_INTERNALLICENQUIRY_TABLE_TITLE_APPLICANT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_TABLE_TITLE}.${KEY_STRINGS.APPLICANT}`,
    BLS_INTERNALLICENQUIRY_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENQUIRY_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENQUIRY_TABLE_TITLE_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_TABLE_TITLE}.${KEY_STRINGS.ISSUED_DATE}`,
    BLS_INTERNALLICENQUIRY_TABLE_TITLE_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_TABLE_TITLE}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_INTERNALLICENQUIRY_CONTENT_TITLE_APPROVALENQUIRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_CONTENT_TITLE}.${KEY_STRINGS.APPROVAL_ENQUIRY}`,
    BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.ISSUED_DATE}`,
    BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_SERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.SERVICE}`,
    BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_SERVICENO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.SERVICE_NO}`,
    BLS_INTERNALLICENQUIRY_FORM_PLACEHOLDER_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_FORM_PLACEHOLDER}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENQUIRY_FORM_BUTTON_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_FORM_BUTTON}.${KEY_STRINGS.SEARCH}`,
    BLS_INTERNALLICENQUIRY_FORM_TITLE_RECORDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENQUIRY_FORM_TITLE}.${KEY_STRINGS.RECORDS}`,
    //INBOX
    BLS_INTERNALINBOX_MESSAGE_INFORMATION_NOTASKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_MESSAGE_INFORMATION}.${KEY_STRINGS.NO_TASKS}`,
    BLS_INTERNALINBOX_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALINBOX_TABLE_TITLE_SLADATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.SLA_DATE}`,
    BLS_INTERNALINBOX_TABLE_TITLE_LASTUPDATED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.LAST_UPDATED}`,
    BLS_INTERNALINBOX_TABLE_TITLE_SUBMISSIONDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.SUBMISSION_DATE}`,
    BLS_INTERNALINBOX_TABLE_TITLE_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALINBOX_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALINBOX_TABLE_TITLE_LOCKED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.LOCKED}`,
    BLS_INTERNALINBOX_TABLE_TITLE_REFNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.REFERENCE_NO}`,
    BLS_INTERNALINBOX_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALINBOX_CONTENT_TITLE_MYTASKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_CONTENT_TITLE}.${KEY_STRINGS.MYTASKS}`,
    BLS_INTERNALINBOX_NAVIGATION_FIELD_FILTER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALINBOX_NAVIGATION_FIELD}.${KEY_STRINGS.FILTER}`,
    BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE_LICENCEAPPLICATIONTYPE_TITLE}.%Key%`,
    BLS_CODE_LICENCESTATUS_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE_LICENCESTATUS_TITLE}.%Key%`,
    BLS_INTERNALINBOX_CHECKINCHECKOUTSTATUS_TITLE_KEY: `${APP_NAME}.%Key%`,
    //CATEGORY MANAGEMENT
    BLS_INTERNALCATEGORYMGMT_FORM_PLACEHOLDER_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALCATEGORYMGMT_FORM_PLACEHOLDER_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALCATEGORYMGMT_FORM_FIELD_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_FORM_FIELD}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALCATEGORYMGMT_FORM_FIELD_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_FORM_FIELD}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALCATEGORYMGMT_FORM_TITLE_ADDCATEGORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_FORM_TITLE}.${KEY_STRINGS.ADD_CATEGORY}`,
    BLS_INTERNALCATEGORYMGMT_FORM_TITLE_CATEGORYMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_FORM_TITLE}.${KEY_STRINGS.CATEGORY_MANAGEMENT}`,
    BLS_INTERNALCATEGORYMGMT_FORM_BUTTON_ADDCATEGORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_FORM_BUTTON}.${KEY_STRINGS.ADD_CATEGORY}`,
    BLS_INTERNALCATEGORYMGMT_FORM_HELPTEXT_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_FORM_HELPTEXT}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALCATEGORYMGMT_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALCATEGORYMGMT_TABLE_TITLE_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_TABLE_TITLE}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALCATEGORYMGMT_TABLE_TITLE_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_TABLE_TITLE}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALCATEGORYMGMT_TABLE_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALCATEGORYMGMT_TABLE_TITLE}.${KEY_STRINGS.ACTIONS}`,
    //SCOPE MANAGEMENT
    BLS_INTERNALSCOPEMGMT_FORM_PLACEHOLDER_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALSCOPEMGMT_FORM_PLACEHOLDER_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALSCOPEMGMT_FORM_FIELD_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_FORM_FIELD}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALSCOPEMGMT_FORM_FIELD_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_FORM_FIELD}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALSCOPEMGMT_FORM_TITLE_ADDSCOPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_FORM_TITLE}.${KEY_STRINGS.ADD_SCOPE}`,
    BLS_INTERNALSCOPEMGMT_FORM_TITLE_SCOPEMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_FORM_TITLE}.${KEY_STRINGS.SCOPE_MANAGEMENT}`,
    BLS_INTERNALSCOPEMGMT_FORM_HELPTEXT_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_FORM_HELPTEXT}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALSCOPEMGMT_FORM_BUTTON_ADDSCOPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_FORM_BUTTON}.${KEY_STRINGS.ADD_SCOPE}`,
    BLS_INTERNALSCOPEMGMT_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALSCOPEMGMT_TABLE_TITLE_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_TABLE_TITLE}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALSCOPEMGMT_TABLE_TITLE_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_TABLE_TITLE}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALSCOPEMGMT_TABLE_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSCOPEMGMT_TABLE_TITLE}.${KEY_STRINGS.ACTIONS}`,
    //SUBCAT MANAGEMENT
    BLS_INTERNALSUBCATEGORYMGMT_FORM_PLACEHOLDER_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALSUBCATEGORYMGMT_FORM_PLACEHOLDER_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALSUBCATEGORYMGMT_FORM_FIELD_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_FORM_FIELD}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALSUBCATEGORYMGMT_FORM_FIELD_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_FORM_FIELD}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALSUBCATEGORYMGMT_FORM_TITLE_ADDSUBCATEGORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_FORM_TITLE}.${KEY_STRINGS.ADD_SUBCATEGORY}`,
    BLS_INTERNALSUBCATEGORYMGMT_FORM_TITLE_SUBCATEGORYMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_FORM_TITLE}.${KEY_STRINGS.SUBCATEGORY_MANAGEMENT}`,
    BLS_INTERNALSUBCATEGORYMGMT_FORM_BUTTON_ADDSUBCATEGORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_FORM_BUTTON}.${KEY_STRINGS.ADD_SUBCATEGORY}`,
    BLS_INTERNALSUBCATEGORYMGMT_FORM_HELPTEXT_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_FORM_HELPTEXT}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALSUBCATEGORYMGMT_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALSUBCATEGORYMGMT_TABLE_TITLE_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_TABLE_TITLE}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALSUBCATEGORYMGMT_TABLE_TITLE_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_TABLE_TITLE}.${KEY_STRINGS.NAME}`,
    BLS_INTERNALSUBCATEGORYMGMT_TABLE_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALSUBCATEGORYMGMT_TABLE_TITLE}.${KEY_STRINGS.ACTIONS}`,
    //LANGUAGE REGISTRATION
    BLS_INTERNALLANGUAGEREGISTRATION_TABLE_INFORMATION_DEFAULT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_TABLE_INFORMATION}.${KEY_STRINGS.DEFAULT}`,
    BLS_INTERNALLANGUAGEREGISTRATION_TABLE_INFORMATION_ACTIVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_TABLE_INFORMATION}.${KEY_STRINGS.ACTIVE}`,
    BLS_INTERNALLANGUAGEREGISTRATION_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLANGUAGEREGISTRATION_TABLE_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_TABLE_TITLE}.${KEY_STRINGS.ACTIONS}`,
    BLS_INTERNALLANGUAGEREGISTRATION_TABLE_TITLE_APPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_TABLE_TITLE}.${KEY_STRINGS.APPLICATION}`,
    BLS_INTERNALLANGUAGEREGISTRATION_TABLE_TITLE_REGEISTEREDLANGUAGES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_TABLE_TITLE}.${KEY_STRINGS.REGISTERED_LANGUAGES}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_TITLE_LANGUAGEREGISTRATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_TITLE}.${KEY_STRINGS.LANGUAGE_REGISTRATION}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_TITLE_EDITAPPLANG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_TITLE}.${KEY_STRINGS.EDIT_APP_LANG}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_TITLE_LANGUAGESETTINGS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_TITLE}.${KEY_STRINGS.LANGUAGE_SETTINGS}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_PLACEHOLDER_ADDLANGUAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_PLACEHOLDER}.${KEY_STRINGS.ADD_LANGUAGE}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_FIELD_ADDLANGUAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_FIELD}.${KEY_STRINGS.ADD_LANGUAGE}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_FIELD_APPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_FIELD}.${KEY_STRINGS.APPLICATION}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_FIELD_LANGUAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_FIELD}.${KEY_STRINGS.LANGUAGE}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_FIELD_DEFAULT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_FIELD}.${KEY_STRINGS.DEFAULT}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_FIELD_ACTIVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_FIELD}.${KEY_STRINGS.ACTIVE}`,
    BLS_INTERNALLANGUAGEREGISTRATION_FORM_FIELD_ALIGNTORIGHT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGUAGEREGISTRATION_FORM_FIELD}.${KEY_STRINGS.ALIGN_TO_RIGHT}`,
    //TRANSLATION MANAGEMENT
    BLS_INTERNALTRANSLATIONMGMT_FORM_TITLE_ADDVALUE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_TITLE}.${KEY_STRINGS.ADD_VALUE}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_TITLE_ADDNEWLANGUAGEKEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_TITLE}.${KEY_STRINGS.ADD_NEW_LANGUAGE_KEY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_TITLE_TRANSLATIONMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_TITLE}.${KEY_STRINGS.TRANSLATION_MANAGEMENT}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_TITLE_PUBLISHENTRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_TITLE}.${KEY_STRINGS.PUBLISH_ENTRY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_APPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.APPLICATION}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_CATEGORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.CATEGORY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_SUBCATEGORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.SUBCATEGORY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.KEY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_SCOPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.SCOPE}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_FULLKEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.FULL_KEY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_PUBLISHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.PUBLISHED}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_SORTBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.SORT_BY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.SEARCH}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_LANGUAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.LANGUAGE}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_FIELD_DEFAULTLANGUAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_FIELD}.${KEY_STRINGS.DEFAULT_LANGUAGE}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER_SCOPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.SCOPE}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER_CATEGORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.CATEGORY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER_SUBCATEGORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.SUBCATEGORY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER_PUBLISHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.PUBLISHED}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER_SORTBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.SORT_BY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_PLACEHOLDER}.${KEY_STRINGS.SEARCH}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_BUTTON_PUBLISH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_BUTTON}.${KEY_STRINGS.PUBLISH}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_BUTTON_ADDENTRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_BUTTON}.${KEY_STRINGS.ADD_ENTRY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_INFORMATION_PUBLISHENTRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_INFORMATION}.${KEY_STRINGS.PUBLISH_ENTRY}`,
    BLS_INTERNALTRANSLATIONMGMT_FORM_INFORMATION_LASTEDITEDBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALTRANSLATIONMGMT_FORM_INFORMATION}.${KEY_STRINGS.LAST_EDITED_BY}`,
    //LANG PACK UPDATE
    BLS_INTERNALLANGPACKUPDATE_FORM_TITLE_REVIEWINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_TITLE}.${KEY_STRINGS.REVIEW_INFO}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_TITLE_LANGPACKUPDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_TITLE}.${KEY_STRINGS.LANG_PACK_UPDATE}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_PLACEHOLDER_APPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_TITLE}.${KEY_STRINGS.APPLICATION}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_PLACEHOLDER_SCOPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_TITLE}.${KEY_STRINGS.SCOPE}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_FIELD_APPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_FIELD}.${KEY_STRINGS.APPLICATION}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_FIELD_SCOPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_FIELD}.${KEY_STRINGS.SCOPE}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_INFORMATION_REVIEWINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_INFORMATION}.${KEY_STRINGS.REVIEW_INFO}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_BUTTON_DOWNLOAD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_BUTTON}.${KEY_STRINGS.DOWNLOAD}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_BUTTON_ADDLANGPACK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_BUTTON}.${KEY_STRINGS.ADD_LANG_PACK}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_BUTTON_PUBLISH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_BUTTON}.${KEY_STRINGS.PUBLISH}`,
    BLS_INTERNALLANGPACKUPDATE_FORM_BUTTON_VALIDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_FORM_BUTTON}.${KEY_STRINGS.VALIDATE}`,
    BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_TABLE_TITLE}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_SIZE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_TABLE_TITLE}.${KEY_STRINGS.SIZE}`,
    BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_TYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_TABLE_TITLE}.${KEY_STRINGS.TYPE}`,
    BLS_INTERNALLANGPACKUPDATE_TABLE_TITLE_ACTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLANGPACKUPDATE_TABLE_TITLE}.${KEY_STRINGS.ACTION}`,
    //PAYMENT INBOX
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SUBMISSIONDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.SUBMISSION_DATE}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_LOCKED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.LOCKED}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_REFNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.REFERENCE_NO}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_LASTUPDATED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.LAST_UPDATED}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SLADATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.SLA_DATE}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_APPLICATIONNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.APPLICATION_NO}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SERVICES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.SERVICES}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_TAX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.TAX}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_FEES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.FEES}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_SUBTOTAL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.SUB_TOTAL}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_MODEPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.MODE_PAYMENT}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_TAXAMOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.TAX_AMOUNT}`,
    BLS_INTERNALPAYMENTINBOX_TABLE_TITLE_FEEAMOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_TABLE_TITLE}.${KEY_STRINGS.FEE_AMOUNT}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTINBOX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_TITLE}.${KEY_STRINGS.PAYMENT_INBOX}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_TITLE}.${KEY_STRINGS.PAY_APPLICATION}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_TITLE}.${KEY_STRINGS.PAYMENTS}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTSUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_TITLE}.${KEY_STRINGS.PAYMENT_SUCCESS}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTITEMS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_TITLE}.${KEY_STRINGS.PAYMENT_ITEMS}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_TITLE_PAYMENTITEM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_TITLE}.${KEY_STRINGS.PAYMENT_ITEM}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_FIELD_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_FIELD}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_FIELD_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_FIELD}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_FIELD_REF: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_FIELD}.${KEY_STRINGS.REF}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_FIELD_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_FIELD}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_BUTTON_CANCEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_BUTTON}.${KEY_STRINGS.CANCEL}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_BUTTON_RECEIVEPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_BUTTON}.${KEY_STRINGS.RECEIVE_PAYMENT}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_BUTTON_PRINTRECEIPT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_BUTTON}.${KEY_STRINGS.PRINT_RECEIPT}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_BUTTON_DASHBOARD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_BUTTON}.${KEY_STRINGS.DASHBOARD}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_CURRENCYSYMBOL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_INFORMATION}.${KEY_STRINGS.CURRENCY_SYMBOL}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_GRANDTOTAL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_INFORMATION}.${KEY_STRINGS.GRAND_TOTAL}`,
    BLS_INTERNALPAYMENTINBOX_CONTENT_INFORMATION_TOTALPAYABLEAMOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_CONTENT_INFORMATION}.${KEY_STRINGS.TOTAL_PAYABLE_AMOUNT}`,
    BLS_INTERNALPAYMENTINBOX_NAVIGATION_FIELD_FILTER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_NAVIGATION_FIELD}.${KEY_STRINGS.FILTER}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_INFORMATION_NOTASKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_INFORMATION}.${KEY_STRINGS.NO_TASKS}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_INFORMATION_ORDERID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_INFORMATION}.${KEY_STRINGS.ORDER_ID}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_INFORMATION_PAYMENTSUCCESSFUL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_INFORMATION}.${KEY_STRINGS.PAYMENT_SUCESSFUL}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_REDIRECTPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.REDIRECT_PAYMENT}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_RETRIEVEPAYMENTDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.RETRIEVE_PAYMENT_DETAILS}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_APPLICATIONSUBMISSIONCOMPLETE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.APPLICATION_SUBMISSION_COMPLETE}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_PAYMENTTEXT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.PAYMENT_TEXT}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_NOPAYMENTREQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_PAYMENT_REQUIRED}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_NOPAYMENTITEMADDED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_PAYMENT_ITEM_ADDED}`,
    BLS_INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER_SELECTPAYMENTITEM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTINBOX_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.SELECT_PAYMENT_ITEM}`,
    BLS_DEFAULT_PAYMENTTYPE_PAYMENTOPTION_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.DEFAULT_PAYMENTTYPE_PAYMENTOPTION}.%Key%`,
    //REPORT HISTORY
    BLS_INTERNALREPORTHISTORY_TABLE_BUTTON_VIEWPARAMETER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_TABLE_BUTTON}.${KEY_STRINGS.VIEW_PARAMETER}`,
    BLS_INTERNALREPORTHISTORY_TABLE_BUTTON_DOWNLOAD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_TABLE_BUTTON}.${KEY_STRINGS.DOWNLOAD}`,
    BLS_INTERNALREPORTHISTORY_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALREPORTHISTORY_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALREPORTHISTORY_TABLE_TITLE_REQUESTEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_TABLE_TITLE}.${KEY_STRINGS.REQUESTED_DATE}`,
    BLS_INTERNALREPORTHISTORY_TABLE_TITLE_REQUESTEDBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_TABLE_TITLE}.${KEY_STRINGS.REQUESTED_BY}`,
    BLS_INTERNALREPORTHISTORY_TABLE_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_TABLE_TITLE}.${KEY_STRINGS.ACTIONS}`,
    BLS_INTERNALREPORTHISTORY_MESSAGE_PLACEHOLDER_NOREPORTRECORDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_REPORT_RECORDS}`,
    BLS_INTERNALREPORTHISTORY_CONTENT_BUTTON_CLOSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_CONTENT_BUTTON}.${KEY_STRINGS.CLOSE}`,
    BLS_INTERNALREPORTHISTORY_CONTENT_BUTTON_DOWNLOADREPORT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_CONTENT_BUTTON}.${KEY_STRINGS.DOWNLOAD_REPORT}`,
    BLS_INTERNALREPORTHISTORY_CONTENT_BUTTON_VIEWSEARCHPARAM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_CONTENT_BUTTON}.${KEY_STRINGS.VIEW_SEARCH_PARAM}`,
    BLS_INTERNALREPORTHISTORY_CONTENT_MODAL_REPORTPARAMETERS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_CONTENT_MODAL}.${KEY_STRINGS.REPORT_PARAMETERS}`,
    BLS_INTERNALREPORTHISTORY_NAVIGATION_FIELD_FILTER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTHISTORY_NAVIGATION_FIELD}.${KEY_STRINGS.FILTER}`,
    //LICENCE REPORT
    BLS_INTERNALLICENCEREPORT_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLICENCEREPORT_TABLE_TITLE_SERVICENO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NO}`,
    BLS_INTERNALLICENCEREPORT_TABLE_TITLE_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_TABLE_TITLE}.${KEY_STRINGS.ISSUED_DATE}`,
    BLS_INTERNALLICENCEREPORT_TABLE_TITLE_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_TABLE_TITLE}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_INTERNALLICENCEREPORT_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCEREPORT_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEREPORT_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCEREPORT_FORM_FIELD_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_FORM_FIELD}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCEREPORT_FORM_FIELD_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_FORM_FIELD}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEREPORT_FORM_FIELD_ISSUEDSTARTDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_FORM_FIELD}.${KEY_STRINGS.ISSUED_START_DATE}`,
    BLS_INTERNALLICENCEREPORT_FORM_FIELD_ISSUEDENDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_FORM_FIELD}.${KEY_STRINGS.ISSUED_END_DATE}`,
    BLS_INTERNALLICENCEREPORT_FORM_FIELD_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEREPORT_FORM_FIELD}.${KEY_STRINGS.STATUS}`,
    //REPORT SEARCH
    BLS_INTERNALREPORTSEARCH_NAVIGATION_BUTTON_SEARCHSERVICES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_NAVIGATION_BUTTON}.${KEY_STRINGS.SEARCH_SERVICES}`,
    BLS_INTERNALREPORTSEARCH_NAVIGATION_BUTTON_REPORTHISTORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_NAVIGATION_BUTTON}.${KEY_STRINGS.REPORT_HISTORY}`,
    BLS_INTERNALREPORTSEARCH_NAVIGATION_BUTTON_PAYMENTREPORT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_NAVIGATION_BUTTON}.${KEY_STRINGS.PAYMENT_REPORT}`,
    BLS_INTERNALREPORTSEARCH_NAVIGATION_BUTTON_RENEWALREPORT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_NAVIGATION_BUTTON}.${KEY_STRINGS.RENEWAL_REPORT}`,
    BLS_INTERNALREPORTSEARCH_FORM_BUTTON_SEARCHLICENCES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_FORM_BUTTON}.${KEY_STRINGS.SEARCH_LICENCES}`,
    BLS_INTERNALREPORTSEARCH_FORM_BUTTON_DOWNLOADREPORT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_FORM_BUTTON}.${KEY_STRINGS.DOWNLOAD_REPORT}`,
    BLS_INTERNALREPORTSEARCH_FORM_TITLE_RECORDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_FORM_TITLE}.${KEY_STRINGS.RECORDS}`,
    BLS_INTERNALREPORTSEARCH_MESSAGE_PLACEHOLDER_NOSERVICESMATCHING: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_SERVICES_MATCHING}`,
    BLS_INTERNALREPORTSEARCH_MESSAGE_PLACEHOLDER_NOPAYMENTSMATCHING: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALREPORTSEARCH_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_PAYMENTS_MATCHING}`,
    //PAYMENT REPORT
    BLS_INTERNALPAYMENTREPORT_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALPAYMENTREPORT_TABLE_TITLE_PAYMENTREFNO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_REFNO}`,
    BLS_INTERNALPAYMENTREPORT_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALPAYMENTREPORT_TABLE_TITLE_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_TABLE_TITLE}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALPAYMENTREPORT_TABLE_TITLE_PAYMENTMETHOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_METHOD}`,
    BLS_INTERNALPAYMENTREPORT_TABLE_TITLE_SUBTOTAL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_TABLE_TITLE}.${KEY_STRINGS.SUBTOTAL}`,
    BLS_INTERNALPAYMENTREPORT_TABLE_TITLE_COLLECTEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_TABLE_TITLE}.${KEY_STRINGS.COLLECTED_DATE}`,
    BLS_INTERNALPAYMENTREPORT_TABLE_TITLE_PAYMENTBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_BY}`,
    BLS_INTERNALPAYMENTREPORT_FORM_FIELD_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_FORM_FIELD}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALPAYMENTREPORT_FORM_FIELD_ISSUEDSTARTDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_FORM_FIELD}.${KEY_STRINGS.ISSUED_START_DATE}`,
    BLS_INTERNALPAYMENTREPORT_FORM_FIELD_ISSUEDENDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPAYMENTREPORT_FORM_FIELD}.${KEY_STRINGS.ISSUED_END_DATE}`,
    //RENEWAL REPORT
    BLS_INTERNALRENEWALREPORT_FORM_FIELD_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALRENEWALREPORT_FORM_FIELD}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALRENEWALREPORT_FORM_FIELD_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALRENEWALREPORT_FORM_FIELD}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALRENEWALREPORT_FORM_FIELD_TIMEFROMEXPIRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALRENEWALREPORT_FORM_FIELD}.${KEY_STRINGS.TIME_FROM_EXPIRY}`,
    BLS_INTERNALRENEWALREPORT_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALRENEWALREPORT_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALRENEWALREPORT_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALRENEWALREPORT_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALRENEWALREPORT_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALRENEWALREPORT_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALRENEWALREPORT_TABLE_TITLE_SERVICENO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALRENEWALREPORT_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NO}`,
    BLS_INTERNALRENEWALREPORT_TABLE_TITLE_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALRENEWALREPORT_TABLE_TITLE}.${KEY_STRINGS.EXPIRYDATE}`,
    //MENU
    BLS_INTERNALMENU_NAVIGATION_MENU_INBOX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.INBOX}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_ADMIN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.ADMIN}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_APPLICATIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.APPLICATIONS}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_APPROVALS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.APPROVALS}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_SYSTEMCONFIG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.SYSTEM_CONFIGURATION}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_REPORTGENERATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.REPORT_GENERATION}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_PAYMENTINBOX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.PAYMENT_INBOX}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_NETWORKGRAPH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.NETWORK_GRAPH}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_SERVICECONFIG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.SERVICE_CONFIGURATION}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_SERVICEAPPCONFIG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.SERVICE_APP_CONFIGURATION}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_PREVIEWSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.PREVIEW_SERVICE}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_LANGUAGEMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.LANGUAGE_MANAGEMENT}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_HOME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.HOME}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_ENQUIRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.ENQUIRY}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_SERVICEMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.SERVICE_MANAGEMENT}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_SERVICESUMMARY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.SERVICE_SUMMARY}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_PAYMENTSUMMARY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.PAYMENT_SUMMARY}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_RENEWALREPORT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.RENEWAL_REPORT}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_CONFIG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.CONFIGURATION}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_ACCESSMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.ACCESS_MANAGEMENT}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_FORMIOADMIN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.FORMIOADMIN}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_APPCONFIG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.APP_CONFIG}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_CODETABLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.CODE_TABLE}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_TRANSLATIONMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.TRANSLATION_MANAGEMENT}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_LANGUAGEPACKUPDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.LANGUAGE_PACK_UPDATE}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_LANGUAGEREGISTRATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.LANGUAGE_REGISTRATION}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_NOTIFICATIONTEMPLATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.NOTIFICATION_TEMPLATE}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_EMAILTEMPLATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.EMAIL_TEMPLATE}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_SMSTEMPLATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.SMS_TEMPLATE}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_LETTERTEMPLATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.LETTER_TEMPLATE}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_PUBLICHOLIDAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.PUBLIC_HOLIDAY}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_WORKINGHOUR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.WORKING_HOUR}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_ONLINEHELPMANANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.ONLINE_HELP_MANAGEMENT}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_SERVICEEXTENSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.SERVICE_EXTENSION}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_CHANGEUSERNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.CHANGE_USERNAME}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_CHANGEPASSWORD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.CHANGE_PASSWORD}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_CHANGESECURITYINFO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.CHANGE_SECURITY_INFO}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_LOGOUT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.LOGOUT}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_LOGIN: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.LOGIN}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_LANGUAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.LANGUAGE}`,
    BLS_INTERNALMENU_NAVIGATION_MENU_FAQMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALMENU_NAVIGATION_MENU}.${KEY_STRINGS.FAQ_MANAGEMENT}`,
    //SERVICE MANAGEMENT
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_TITLE_SERVICEMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_TITLE}.${KEY_STRINGS.SERVICE_MANAGEMENT}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_TITLE_REVOKESERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_TITLE}.${KEY_STRINGS.REVOKE_SERVICE}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_TITLE_SUSPENDSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_TITLE}.${KEY_STRINGS.SUSPEND_SERVICE}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_TITLE_REINSTATESERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_TITLE}.${KEY_STRINGS.REINSTATE_SERVICE}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_SERVICEOVERVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_SECTION}.${KEY_STRINGS.SERVICE_OVERVIEW}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_REVOKE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_SECTION}.${KEY_STRINGS.REVOKE}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_EDITSUSPENSIONDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_SECTION}.${KEY_STRINGS.EDIT_SUSPENSION_DETAILS}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_REINSTATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_SECTION}.${KEY_STRINGS.REINSTATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_SECTION_SUSPEND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_SECTION}.${KEY_STRINGS.SUSPEND}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON_REVOKE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON}.${KEY_STRINGS.REVOKE}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON_EDITSUSPENSIONDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON}.${KEY_STRINGS.EDIT_SUSPENSION_DETAILS}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON_REINSTATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON}.${KEY_STRINGS.REINSTATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON_SUSPEND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON}.${KEY_STRINGS.SUSPEND}`,
    BLS_INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON_VIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_CONTENT_BUTTON}.${KEY_STRINGS.VIEW}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_TITLE_RECORDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_TITLE}.${KEY_STRINGS.RECORDS}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.SERVICE}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SERVICENO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.SERVICE_NO}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_AGENCY2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.AGENCY2}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.ISSUED_DATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_ISSUEDDATE2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.ISSUED_DATE2}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_EXPIRYDATE2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.EXPIRYDATE2}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SERVICENUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.SERVICE_NUMBER}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_APPLICANT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.APPLICANT}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SERVICESTATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.SERVICE_STATUS}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_REVOCATIONREMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.REVOCATION_REMARKS}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SUSPENSIONENDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.SUSPENSION_END_DATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_SUSPENSIONREMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.SUSPENSION_REMARKS}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_REINSTATEMENTREMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.REINSTATEMENT_REMARKS}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_FIELD_ENTITY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_FIELD}.${KEY_STRINGS.ENTITY}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER_SERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER}.${KEY_STRINGS.SERVICE}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER_SERVICENO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER}.${KEY_STRINGS.SERVICE_NO}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER}.${KEY_STRINGS.ISSUED_DATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_PLACEHOLDER}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_SERVICENO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NO}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_ISSUEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.ISSUED_DATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_EXPIRYDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.EXPIRYDATE}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_APPLICANT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.APPLICANT}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLICENCEMANAGEMENT_TABLE_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_TABLE_TITLE}.${KEY_STRINGS.ACTIONS}`,
    BLS_INTERNALLICENCEMANAGEMENT_MESSAGE_PLACEHOLDER_NORESULTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_RESULTS}`,
    BLS_INTERNALLICENCEMANAGEMENT_FORM_BUTTON_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_FORM_BUTTON}.${KEY_STRINGS.SEARCH}`,
    BLS_INTERNALLICENCEMANAGEMENT_NAVIGATION_BUTTON_BACK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_NAVIGATION_BUTTON}.${KEY_STRINGS.BACK}`,
    BLS_INTERNALLICENCEMANAGEMENT_NAVIGATION_BUTTON_SUBMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEMANAGEMENT_NAVIGATION_BUTTON}.${KEY_STRINGS.SUBMIT}`,
    //LICENCE EXTENSION
    BLS_INTERNALLICENCEEXTENSION_CONTENT_TITLE_SERVICEEXTENSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_CONTENT_TITLE}.${KEY_STRINGS.SERVICE_EXTENSION}`,
    BLS_INTERNALLICENCEEXTENSION_CONTENT_TITLE_NEWSERVICEEXTENSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_CONTENT_TITLE}.${KEY_STRINGS.NEW_SERVICE_EXTENSION}`,
    BLS_INTERNALLICENCEEXTENSION_CONTENT_TITLE_SERVICELIST: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_CONTENT_TITLE}.${KEY_STRINGS.SERVICE_LIST}`,
    BLS_INTERNALLICENCEEXTENSION_CONTENT_TITLE_SERVICEEXTENSIONLIST: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_CONTENT_TITLE}.${KEY_STRINGS.SERVICE_EXTENSION_LIST}`,
    BLS_INTERNALLICENCEEXTENSION_CONTENT_SECTION_SERVICEEXTENSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_CONTENT_SECTION}.${KEY_STRINGS.SERVICE_EXTENSION}`,
    BLS_INTERNALLICENCEEXTENSION_CONTENT_MODAL_SERVICEEXTENSIONDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_CONTENT_MODAL}.${KEY_STRINGS.SERVICE_EXTENSION_DETAILS}`,
    BLS_INTERNALLICENCEEXTENSION_CONTENT_BUTTON_DOWNLOAD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_CONTENT_BUTTON}.${KEY_STRINGS.DOWNLOAD}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_TITLE_RECORDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_TITLE}.${KEY_STRINGS.RECORDS}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_BUTTON_SEARCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_BUTTON}.${KEY_STRINGS.SEARCH}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_FIELD}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_EXTENSIONPERIODTO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_FIELD}.${KEY_STRINGS.EXTENSION_PERIOD_TO}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_EXTENSIONPERIODFROM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_FIELD}.${KEY_STRINGS.EXTENSION_PERIOD_FROM}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_SERVICEVALIDITYPERIOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_FIELD}.${KEY_STRINGS.SERVICE_VALIDITY_PERIOD}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_YEAR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_FIELD}.${KEY_STRINGS.YEAR}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_MONTH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_FIELD}.${KEY_STRINGS.MONTH}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_DAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_FIELD}.${KEY_STRINGS.DAY}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_FIELD_REMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_FIELD}.${KEY_STRINGS.REMARKS}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_LICENCEVALIDITYPERIOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER}.${KEY_STRINGS.LICENCE_VALIDITY_PERIOD}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_YEAR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER}.${KEY_STRINGS.YEAR}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_MONTH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER}.${KEY_STRINGS.MONTH}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_DAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER}.${KEY_STRINGS.DAY}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_REMARKS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER}.${KEY_STRINGS.REMARKS}`,
    BLS_INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER_CHOOSEDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_FORM_PLACEHOLDER}.${KEY_STRINGS.CHOOSE_DATE}`,
    BLS_INTERNALLICENCEEXTENSION_MESSAGE_PLACHOLDER_NORESULTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_RESULTS}`,
    BLS_INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON_ADDNEWSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON}.${KEY_STRINGS.ADD_NEW_SERVICE}`,
    BLS_INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON_BACK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON}.${KEY_STRINGS.BACK}`,
    BLS_INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON_PUBLISH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_NAVIGATION_BUTTON}.${KEY_STRINGS.PUBLISH}`,
    BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_DATEOFEXTENSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_TABLE_TITLE}.${KEY_STRINGS.DATE_OF_EXTENSION}`,
    BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_EXTENSIONPERIOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_TABLE_TITLE}.${KEY_STRINGS.EXTENSION_PERIOD}`,
    BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_ACTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_TABLE_TITLE}.${KEY_STRINGS.ACTION}`,
    BLS_INTERNALLICENCEEXTENSION_TABLE_TITLE_ACTIONBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_TABLE_TITLE}.${KEY_STRINGS.ACTION_BY}`,
    BLS_INTERNALLICENCEEXTENSION_TABLE_LINK_DOWNLOADLIST: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEEXTENSION_TABLE_LINK}.${KEY_STRINGS.DOWNLOAD_LIST}`,
    //NETWORK GRAPH 
    BLS_INTERNALLICENCERECOMMENDATION_CONTENT_BUTTON_ADDCONNECTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_CONTENT_BUTTON}.${KEY_STRINGS.ADD_CONNECTION}`,
    BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_ADDSERVICERELATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_CONTENT_MODAL}.${KEY_STRINGS.ADD_SERVICE_RELATION}`,
    BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_SOURCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_CONTENT_MODAL}.${KEY_STRINGS.SOURCE}`,
    BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_TARGET: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_CONTENT_MODAL}.${KEY_STRINGS.TARGET}`,
    BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_RULETYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_CONTENT_MODAL}.${KEY_STRINGS.RULE_TYPE}`,
    BLS_INTERNALLICENCERECOMMENDATION_CONTENT_MODAL_PLEASESELECT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_CONTENT_MODAL}.${KEY_STRINGS.PLEASE_SELECT}`,
    BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_MENU_DEFINEDRULES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_NAVIGATION_MENU}.${KEY_STRINGS.DEFINED_RULES}`,
    BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_MENU_AIASSOCIATIONRULEMODEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_NAVIGATION_MENU}.${KEY_STRINGS.AI_ASSOCIATION_RULE_MODEL}`,
    BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON_CANCEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON}.${KEY_STRINGS.CANCEL}`,
    BLS_INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON_CONFIRM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCERECOMMENDATION_NAVIGATION_BUTTON}.${KEY_STRINGS.CONFIRM}`,
    //LICENCE CONFIG
    BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_SERVICECONFIGURATIONMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_TITLE}.${KEY_STRINGS.SERVICE_CONFIGURATION_MANAGEMENT}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_VIEWSERVICEVERSIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_TITLE}.${KEY_STRINGS.VIEW_SERVICE_VERSIONS}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_NEWSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_TITLE}.${KEY_STRINGS.NEW_SERVICE}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_VIEWSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_TITLE}.${KEY_STRINGS.VIEW_SERVICE}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_EDITSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_TITLE}.${KEY_STRINGS.EDIT_SERVICE}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_TITLE_CREATEFROMEXISTINGVERSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_TITLE}.${KEY_STRINGS.CREATE_FROM_EXISTING_VERSION}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_VIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_BUTTON}.${KEY_STRINGS.VIEW}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_EDIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_BUTTON}.${KEY_STRINGS.EDIT}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_DELETE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_BUTTON}.${KEY_STRINGS.DELETE}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_USEVERSIONTEMPLATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_BUTTON}.${KEY_STRINGS.USE_VERSION_TEMPLATE}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CANCEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_BUTTON}.${KEY_STRINGS.CANCEL}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_CONFIRM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_BUTTON}.${KEY_STRINGS.CONFIRM}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_BUTTON_ADD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_BUTTON}.${KEY_STRINGS.ADD}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_SERVICEINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_SECTION}.${KEY_STRINGS.SERVICE_INFORMATION}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_PUBLISHSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_SECTION}.${KEY_STRINGS.PUBLISH_SERVICE}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_SERVICEDEPENDENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_SECTION}.${KEY_STRINGS.SERVICE_DEPENDENCY}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_FAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_SECTION}.${KEY_STRINGS.FAQ}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_SECTION_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_SECTION}.${KEY_STRINGS.DECLARATION}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_VIEWFAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.VIEW_FAQ}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_VIEWDEPENDENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.VIEW_DEPENDENCY}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_ADDFAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_FAQ}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_ADDDEPENDENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_DEPENDENCY}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_ADDDECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_DECLARATION}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_EDITFAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_FAQ}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_EDITDEPENDENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_DEPENDENCY}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_EDITDECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_DECLARATION}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_DELETEFAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.DELETE_FAQ}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_DELETEDEPENDENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.DELETE_DEPENDENCY}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_MODAL_DELETEDECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_MODAL}.${KEY_STRINGS.DELETE_DECLARATION}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_HELPTEXT_YEAR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_HELPTEXT}.${KEY_STRINGS.YEAR}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_HELPTEXT_MONTH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_HELPTEXT}.${KEY_STRINGS.MONTH}`,
    BLS_INTERNALLICENCECONFIG_CONTENT_HELPTEXT_DAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_CONTENT_HELPTEXT}.${KEY_STRINGS.DAY}`,
    BLS_INTERNALLICENCECONFIG_NAVIGATION_FIELD_FILTER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_NAVIGATION_FIELD}.${KEY_STRINGS.FILTER}`,
    BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_ADDNEWSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.ADD_NEW_SERVICE}`,
    BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_NEWDEPENDENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.NEW_DEPENDENCY}`,
    BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_NEWFAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.NEW_FAQ}`,
    BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_BACK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.BACK}`,
    BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_PUBLISH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.PUBLISH}`,
    BLS_INTERNALLICENCECONFIG_NAVIGATION_BUTTON_CREATENEWVERSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.CREATE_NEW_VERSION}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_EFFECTIVEFROM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.EFFECTIVE_FROM}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_EFFECTIVETO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.EFFECTIVE_TO}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ACTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.ACTION}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_CREATEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.CREATED_DATE}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_UPDATEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.UPDATED_DATE}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_UPDATEDBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.UPDATED_BY}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_DISPLAYSEQUENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.DISPLAY_SEQUENCE}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_ANSWER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.ANSWER}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_QUESTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.QUESTIONS}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_DEPENDENCYTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.DEPENDENCY_TYPE}`,
    BLS_INTERNALLICENCECONFIG_TABLE_TITLE_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_TABLE_TITLE}.${KEY_STRINGS.DECLARATION}`,
    BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NOSERVICECONFIG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_SERVICE_CONFIG}`,
    BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NOVERSIONFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_VERSION_FOUND}`,
    BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NODEPENDENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_DEPENDENCY}`,
    BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NODECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_DECLARATION}`,
    BLS_INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER_NOFAQ: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_FAQ}`,
    BLS_INTERNALLICENCECONFIG_MESSAGE_MODAL_DELETEDEPENDENCYMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_MESSAGE_MODAL}.${KEY_STRINGS.DELETE_DEPENDENCY_MESSAGE}`,
    BLS_INTERNALLICENCECONFIG_MESSAGE_MODAL_DELETEFAQMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_MESSAGE_MODAL}.${KEY_STRINGS.DELETE_FAQ_MESSAGE}`,
    BLS_INTERNALLICENCECONFIG_MESSAGE_MODAL_DELETEDECLARATIONMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_MESSAGE_MODAL}.${KEY_STRINGS.DELETE_DECLARATION_MESSAGE}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEDESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.SERVICE_DESCRIPTION}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_ISONLINE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.IS_ONLINE}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_EXTERNALLINK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.EXTERNAL_LINK}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_EFFECTIVEFROM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.EFFECTIVE_FROM}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_EFFECTIVETO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.EFFECTIVE_TO}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEPROCESSTIME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.SERVICE_PROCESS_TIME}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEPREFIX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.SERVICE_PREFIX}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_RFARESPONSEPERIOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.RFA_RESPONSE_PERIOD}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_DAYSTOMAKEPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.DAYS_TO_MAKE_PAYMENT}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_TERMINATIONMINPERIOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.TERMINATION_MIN_PERIOD}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEVALIDITYPERIOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.SERVICE_VALIDITY_PERIOD}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_CANBERENEWED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.CAN_BE_RENEWED}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_RENEWALPERIOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.RENEWAL_PERIOD}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_LATEPENALTYPERIOD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.LATE_PENALTY_PERIOD}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_SERVICEFEEDESC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.SERVICE_FEE_DESC}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_QUESTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.QUESTION}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_ANSWER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.ANSWER}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_DISPLAYSEQUENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.DISPLAY_SEQUENCE}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_DEPENDENCYTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.DEPENDENCY_TYPE}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_YES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.YES}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_NO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.NO}`,
    BLS_INTERNALLICENCECONFIG_FORM_FIELD_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_FIELD}.${KEY_STRINGS.DECLARATION}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_SERVICEDESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.SERVICE_DESCRIPTION}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_AGENCY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.AGENCY}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_EXTERNALLINK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.EXTERNAL_LINK}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_EFFECTIVEFROM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.EFFECTIVE_FROM}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_EFFECTIVETO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.EFFECTIVE_TO}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_QUESTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.QUESTION}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_ANSWER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.ANSWER}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DISPLAYSEQUENCE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DISPLAY_SEQUENCE}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DEPENDENCYTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DEPENDENCY_TYPE}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_WORKINGDAYS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.WORKING_DAYS}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DAYS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DAYS}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_DAY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DAY}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_MONTH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.MONTH}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_YEAR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.YEAR}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_NUMBEROFDAYS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.NUMBER_OF_DAYS}`,
    BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_NUMBEROFWORKINGDAYS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.NUMBER_OF_WORKING_DAYS}`,
    BLS_INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_VERSIONSTATUS_TITLE}.%Key%`,
    //BLS_INTERNALLICENCECONFIG_FORM_PLACEHOLDER_SERVICENAME : `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCECONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_SCOPE_LICENCEDEFINITIONFAQ_QUESTION_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEDEFINITIONFAQ_QUESTION}.%Key%`,
    BLS_SCOPE_LICENCEDEFINITIONFAQ_ANSWER_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEDEFINITIONFAQ_ANSWER}.%Key%`,
    //LICENCE APP CONFIG
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_SERVICEAPPCONFIGURATIONMANAGEMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_TITLE}.${KEY_STRINGS.SERVICE_APP_CONFIGURATION_MANAGEMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_VIEWSERVICEAPPLICATIONVERSIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_TITLE}.${KEY_STRINGS.VIEW_SERVICE_APPLICATION_VERSIONS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_NEWSERVICEAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_TITLE}.${KEY_STRINGS.NEW_SERVICE_APPLICATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_VIEWSERVICEAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_TITLE}.${KEY_STRINGS.VIEW_SERVICE_APPLICATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_EDITSERVICEAPPLICATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_TITLE}.${KEY_STRINGS.EDIT_SERVICE_APPLICATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_TITLE_CREATEFROMEXISTINGVERSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_TITLE}.${KEY_STRINGS.CREATE_FROM_EXISTING_VERSION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_VIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.VIEW}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.ADD}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CONFIRM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.CONFIRM}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_EDIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.EDIT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DELETE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.DELETE}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_USEVERSIONTEMPLATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.USE_VERSION_TEMPLATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_CANCEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.CANCEL}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_SUBMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.SUBMIT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_PREVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.PREVIEW}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDDECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.ADD_DECLARATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.ADD_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDPAYMENTITEM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.ADD_PAYMENT_ITEM}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_ADDSERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.ADD_SERVICE_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON_DOWNLOADFILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_BUTTON}.${KEY_STRINGS.DOWNLOAD_FILE}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_APPLICATIONDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MENU}.${KEY_STRINGS.APPLICATION_DETAILS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_APPLICATIONDETAILSOFFICER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MENU}.${KEY_STRINGS.APPLICATION_DETAILS_OFFICER}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_PAYMENTINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MENU}.${KEY_STRINGS.PAYMENT_INFORMATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_SERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MENU}.${KEY_STRINGS.SERVICE_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MENU_PUBLISH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MENU}.${KEY_STRINGS.PUBLISH}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_APPLICATIONDETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_SECTION}.${KEY_STRINGS.APPLICATION_DETAILS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_SECTION}.${KEY_STRINGS.DECLARATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_SUPPORTINGDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_SECTION}.${KEY_STRINGS.SUPPORTING_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_APPLICATIONFIELDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_SECTION}.${KEY_STRINGS.APPLICATION_FIELDS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_ADDITIONALAPPLICATIONFORMS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_SECTION}.${KEY_STRINGS.ADDITIONAL_APPLICATION_FORMS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_PAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_SECTION}.${KEY_STRINGS.PAYMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_SERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_SECTION}.${KEY_STRINGS.SERVICE_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_SECTION_PUBLISH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_SECTION}.${KEY_STRINGS.PUBLISH}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWDECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.VIEW_DECLARATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.VIEW_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWADDITIONALAPPFIELDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.VIEW_ADDITIONAL_APP_FIELDS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWADDITIONALAPPDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.VIEW_ADDITIONAL_APP_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWPAYMENTITEM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.VIEW_PAYMENT_ITEM}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDDECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_DECLARATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDSUPPORTINGDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_SUPPORTING_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDSUPPORTINGDOCUMENTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_SUPPORTING_DOCUMENTS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDADDITIONALAPPFIELDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_ADDITIONAL_APP_FIELDS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDADDITIONALAPPSUPPORTINGDOC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_ADDITIONAL_APP_SUPPORTINGDOC}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDPAYMENTITEM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_PAYMENT_ITEM}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_ADDSERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.ADD_SERVICE_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITDECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_DECLARATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITSUPPORTINGDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_SUPPORTING_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITPAYMENTITEM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_PAYMENT_ITEM}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITSERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_SERVICE_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITADDITIONALAPPFIELDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_ADDITIONAL_APP_FIELDS}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_EDITADDITIONALAPPSUPPORTINGDOC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.EDIT_ADDITIONAL_APP_SUPPORTINGDOC}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_VIEWSERVICEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.VIEW_SERVICE_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_DELETE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.DELETE}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_MODAL_OPTIONAL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_MODAL}.${KEY_STRINGS.OPTIONAL}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_LINK_HERE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_LINK}.${KEY_STRINGS.HERE}`,
    BLS_INTERNALLICENCEAPPCONFIG_CONTENT_LINK_DOWNLOADEXCELTEMPLATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_CONTENT_LINK}.${KEY_STRINGS.DOWNLOAD_EXCEL_TEMPLATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_FIELD_FILTER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_FIELD}.${KEY_STRINGS.FILTER}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_ADDNEWSERVICEAPP: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.ADD_NEW_SERVICE_APP}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_BACK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.BACK}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_PUBLISH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.PUBLISH}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_CREATENEWVERSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.CREATE_NEW_VERSION}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_NEXT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.NEXT}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_SAVEANDCLOSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.SAVE_AND_CLOSE}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_SAVEANDADDNEWFORM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.SAVE_AND_ADD_NEW_FORM}`,
    BLS_INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON_PREVIOUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_NAVIGATION_BUTTON}.${KEY_STRINGS.PREVIOUS}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.NUMBER}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_EFFECTIVEFROM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.EFFECTIVE_FROM}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_EFFECTIVETO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.EFFECTIVE_TO}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_ACTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.ACTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_CREATEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.CREATED_DATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_UPDATEDDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.UPDATED_DATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_UPDATEDBY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.UPDATED_BY}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.DECLARATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_SUPPORTINGDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.SUPPORTING_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_MANDATORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.MANDATORY}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_WORKFLOWSTATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.WORKFLOW_STATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PLACEHOLDERVALUE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.PLACEHOLDER_VALUE}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PLACEHOLDERDESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.PLACEHOLDER_DESCRIPTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DISPLAYTITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.DISPLAY_TITLE}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_REQUIREDACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.REQUIRED_ACTIONS}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_USERROLES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.USER_ROLES}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_FILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.FILE}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DOCUMENTNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.DOCUMENT_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_UPLOADEDDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.UPLOADED_DOCUMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTITEMDESC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_ITEM_DESC}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_TYPE}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_PAYMENTAMOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.PAYMENT_AMOUNT}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_INCLUDETAX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.INCLUDE_TAX}`,
    BLS_INTERNALLICENCEAPPCONFIG_TABLE_TITLE_DOCUMENTDESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_TABLE_TITLE}.${KEY_STRINGS.DOCUMENT_DESCRIPTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSERVICECONFIG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_SERVICE_CONFIG}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOVERSIONFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_VERSION_FOUND}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOPAYMENTITEM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_PAYMENT_ITEM}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOADDITIONALAPPFORM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_ADDITIONAL_APP_FORM}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSERVICESELECTED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_SERVICE_SELECTED}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NODOCUMENTPLACEHOLDER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_DOCUMENT_PLACEHOLDER}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOSUPPORTINGDOC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_SUPPORTINGDOC}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NODECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_DECLARATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_NOFILEFOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.NO_FILE_FOUND}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_CLICK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.CLICK}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_DOWNLOADEXCELMESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.DOWNLOAD_EXCEL_MESSAGE}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER_ACCESSFORMGENERATOR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_PLACEHOLDER}.${KEY_STRINGS.ACCESS_FORM_GENERATOR}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETEPAYMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL}.${KEY_STRINGS.DELETE_PAYMENT}`,
    BLS_INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL_DELETERECORD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_MESSAGE_MODAL}.${KEY_STRINGS.DELETE_RECORD}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.%Key%`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FILENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.FILE_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SERVICENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_INTERNALWORKFLOWSCHEMENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.INTERNAL_WORKFLOW_SCHEME_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FORMDATANEEDEDBYINTERNALWORKFLOWSCHEME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.FORM_DATA_NEEDED_BY_INERNAL_WORKFLOW_SCHEME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PUBLICWORKFLOWSCHEMENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.PUBLIC_WORKFLOW_SCHEME_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FORMDATANEEDEDBYPUBLICWORKFLOWSCHEME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.FORM_DATA_NEEDED_BY_PUBLIC_WORKFLOW_SCHEME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DECLARATIONREQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.DECLARATION_REQUIRED}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SELECTSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.SELECT_SERVICE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_EFFECTIVEFROM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.EFFECTIVE_FROM}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_EFFECTIVETO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.EFFECTIVE_TO}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTDESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.DOCUMENT_DESCRIPTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTFILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.DOCUMENT_FILE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTHINT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.DOCUMENT_HINT}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTITEMDESC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.PAYMENT_ITEM_DESC}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.PAYMENT_TYPE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTAMOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.PAYMENT_AMOUNT}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_PAYMENTDESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.PAYMENT_DESCRIPTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_INCLUDETAX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.INCLUDE_TAX}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_WORKFLOWSTATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.WORKFLOW_STATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_YES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.YES}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_NO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.NO}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.FILE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DOCUMENTNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.DOCUMENT_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ASSOCIATEDWORKFLOWSTATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.ASSOCIATED_WORKFLOW_STATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DISPLAYTITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.DISPLAY_TITLE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_REQUIREDACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.REQUIRED_ACTIONS}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_USERROLESFORMVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.USER_ROLES_FORM_VIEW}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_UPLOADFORMFIELDS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.UPLOAD_FORM_FIELDS}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_SUPPORTINGDOCNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.SUPPORTINGDOC_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_ISMANDATORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.IS_MANDATORY}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_UPLOADFILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.UPLOAD_FILE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.DECLARATION}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_FIELD_FORMIOURL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.FORMIOURL}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_ERROR_EXISTEDDOCUMENTNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_ERROR}.${KEY_STRINGS.EXISTED_DOCUMENT_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PUBLICWORKFLOWSCHEMENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.PUBLIC_WORKFLOW_SCHEME_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_SELECTSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.SELECT_SERVICE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_EFFECTIVEFROM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.EFFECTIVE_FROM}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_EFFECTIVETO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.EFFECTIVE_TO}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DOCUMENTDESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DOCUMENT_DESCRIPTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DOCUMENTFILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DOCUMENT_FILE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTITEMDESC: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.PAYMENT_ITEM_DESC}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.PAYMENT_TYPE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_PAYMENTAMOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.PAYMENT_AMOUNT}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_WORKFLOWSTATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.WORKFLOW_STATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_INTERNALWORKFLOWSCHEMENAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.INTERNAL_WORKFLOW_SCHEME_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_APPLICATIONTYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_ASSOCIATEDWORKFLOWSTATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.ASSOCIATED_WORKFLOW_STATE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DISPLAYTITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DISPLAY_TITLE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_REQUIREDACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.REQUIRED_ACTIONS}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_USERROLESFORMVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.USER_ROLES_FORM_VIEW}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_SUPPORTINGDOCNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.SUPPORTINGDOC_NAME}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DESCRIPTION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DESCRIPTION}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_ISMANDATORY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.IS_MANDATORY}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_UPLOADFILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.UPLOAD_FILE}`,
    BLS_INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_PLACEHOLDER}.${KEY_STRINGS.DECLARATION}`,
    BLS_SCOPE_LICENCETYPEDECLARATION_DECLARATIONDESC_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCETYPEDECLARATION_DECLARATIONDESC}.%Key%`,
    //Agency
    BLS_LICENCEAGENCY_AGENCY_AGENCYNAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEAGENCY_AGENCY_AGENCYNAME}.%Key%`,
    // LICENCE APPLICATION FEES
    BLS_LICENCE_LICENCEAPPLICATIONFEE_APPLICATIONFEE_KEY: `${APP_NAME_LICENCE_CONFIG}.%Scope%.${SCOPE_CAT_SUBCAT_STRINGS.LICENCEAPPLICATIONFEE_APPLICATIONFEE}.%Key%`,
    //Licence Recommendation Graph
    BLS_AI_LICENCERECOMMENDER_RULETYPE: `${APP_NAME_LICENCE_CONFIG}.${SCOPE_CAT_SUBCAT_STRINGS.AI_LICENCERECOMMENDER_RULETYPE}.%Key%`,
    // GENERAL INFORMATION
    BLS_GENERALINFORMATION_FORM_SECTION_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATION_FORM_SECTION}.%Key%`,
    BLS_GENERALINFORMATION_FORM_FIELD_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATION_FORM_FIELD}.%Key%`,
    BLS_GENERALINFORMATION_FORM_HELPTEXT_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATION_FORM_HELPTEXT}.%Key%`,
    BLS_GENERALINFORMATION_FORM_PLACEHOLDERTEXT_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.GENERALINFORMATION_FORM_PLACEHOLDERTEXT}.%Key%`,
    //Code Table DropDown List
    BLS_CODE_CATEGORY_TITLE_KEY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.CODE}.%Category%.${SCOPE_CAT_SUBCAT_STRINGS.TITLE}.%Key%`,
    //GENERAL
    BLS_INTERNALGENERAL_FORM_BUTTON_BACK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_FORM_BUTTON}.${KEY_STRINGS.BACK}`,
    BLS_INTERNALGENERAL_FORM_BUTTON_SUBMIT:  `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_FORM_BUTTON}.${KEY_STRINGS.SUBMIT}`,
    BLS_INTERNALGENERAL_FORM_BUTTON_CONFIRM:  `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_FORM_BUTTON}.${KEY_STRINGS.CONFIRM}`,
    BLS_INTERNALGENERAL_FORM_BUTTON_CANCEL:  `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_FORM_BUTTON}.${KEY_STRINGS.CANCEL}`,
    BLS_INTERNALGENERAL_CONTENT_HELPTEXT_CHARACTERSREMAINING: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.CHARACTERS_REMAINING}`,

    //SELF SERVICE
    BLS_INTERNALPREVIEWSERVICE_CONTENT_TITLE_PREVIEWSERVICE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_CONTENT_TITLE}.${KEY_STRINGS.PREVIEW_SERVICE}`,
    BLS_INTERNALPREVIEWSERVICE_CONTENT_BUTTON_PUBLISHFORPREVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_CONTENT_BUTTON}.${KEY_STRINGS.PUBLISH_FOR_PREVIEW}`,
    BLS_INTERNALPREVIEWSERVICE_CONTENT_BUTTON_PUBLISHFORLIVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_CONTENT_BUTTON}.${KEY_STRINGS.PUBLISH_FOR_LIVE}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_PUBLISHFORPREVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_TITLE}.${KEY_STRINGS.PUBLISH_FOR_PREVIEW}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_PUBLISHFORLIVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_TITLE}.${KEY_STRINGS.PUBLISH_FOR_LIVE}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_CONTENT_CONFIRMPUBLISHFORPREVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_CONTENT}.${KEY_STRINGS.CONFIRM_PUBLISH_FOR_PREVIEW}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_CONTENT_CONFIRMPUBLISHFORLIVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_CONTENT}.${KEY_STRINGS.CONFIRM_PUBLISH_FOR_LIVE}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_CONFIGURATIONHEADER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_TITLE}.${KEY_STRINGS.CONFIGURATION_HEADER}`,
    BLS_INTERNALPREVIEWSERVICE_CONTENT_MENU_SERVICECONFIGURATION:  `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_CONTENT_MENU}.${KEY_STRINGS.SERVICE_CONFIGURATION}`,
    BLS_INTERNALPREVIEWSERVICE_CONTENT_MENU_SERVICEAPPCONFIGURATION:  `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_CONTENT_MENU}.${KEY_STRINGS.SERVICE_APP_CONFIGURATION}`,
    BLS_INTERNALPREVIEWSERVICE_ERRORMESSAGE_PLACEHOLDERTEXT_SELECTSERVICETOPREVIEW:  `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_ERRORMESSAGE_PLACEHOLDERTEXT}.${KEY_STRINGS.SELECT_SERVICE_TO_PREVIEW}`, 
    BLS_INTERNALPREVIEWSERVICE_ERRORMESSAGE_PLACEHOLDERTEXT_SELECTSERVICETOLIVE:  `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_ERRORMESSAGE_PLACEHOLDERTEXT}.${KEY_STRINGS.SELECT_SERVICE_TO_LIVE}`, 
    BLS_INTERNALPREVIEWSERVICE_CONTENT_INFORMATION_PREVIEWSERVICEINFORMATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_CONTENT_INFORMATION}.${KEY_STRINGS.PREVIEW_SERVICE_INFORMATION}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_BUTTON_ADDTESTERS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_BUTTON}.${KEY_STRINGS.ADD_TESTERS}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_BUTTON_ADDTESTER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_BUTTON}.${KEY_STRINGS.ADD_TESTER}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_CREATETESTACCOUNTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_TITLE}.${KEY_STRINGS.CREATE_TEST_ACCOUNTS}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_EDITTESTACCOUNTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_TITLE}.${KEY_STRINGS.EDIT_TEST_ACCOUNTS}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CONFIRM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_BUTTON}.${KEY_STRINGS.CONFIRM}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_CANCEL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_BUTTON}.${KEY_STRINGS.CANCEL}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_SAVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_BUTTON}.${KEY_STRINGS.SAVE}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_ADD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_BUTTON}.${KEY_STRINGS.ADD}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_BUTTON_UPDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_BUTTON}.${KEY_STRINGS.UPDATE}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_ACTIONS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.ACTIONS}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_EMAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.EMAIL}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_USER_TYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.USER_TYPE}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_USER_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.USER_NAME}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_STATUS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.STATUS}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_TESTERROLES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.TESTER_ROLES}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_AGENCY_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.AGENCY_NAME}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_SERVICE_NAME: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.SERVICE_NAME}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_LAST_PUBLISHED_TO_PREVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.LAST_PUBLISHED_TO_PREVIEW}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_PUBLISH_STATUS_TO_PREVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.PUBLISH_STATUS_TO_PREVIEW}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_NO_RESULTS_MESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.NO_RESULTS_MESSAGE}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_TEST_ACCOUNTS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.TEST_ACCOUNTS}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_PUBLISHED_DATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.PUBLISHED_DATE}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_PUBLISHED_BY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.PUBLISHED_BY}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_NO_DUPLICATE_EMAIL_MESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODEL_ERROR}.${KEY_STRINGS.NO_DUPPLICATE_EMAIL_MESSAGE}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_MINIMAL_TEST_ACCOUNT_ERROR_MESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODEL_ERROR}.${KEY_STRINGS.MINIMAL_TEST_ACCOUNT_ERROR_MESSAGE}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_ADD_TEST_ACCOUNT_SUCCESS_MESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODEL_SUCCESS}.${KEY_STRINGS.ADD_TEST_ACCOUNT_SUCCESS_MESSAGE}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_ADD_TEST_ACCOUNT_DEFAULT_MESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODEL_SUCCESS}.${KEY_STRINGS.ADD_TEST_ACCOUNT_DEFAULT_MESSAGE}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TOOLTIP_EDIT_TEST_ACCOUNT:`${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TOOLTIP}.${KEY_STRINGS.EDIT_TEST_ACCOUNT_TOOLTIP}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TOOLTIP_DELETE_TEST_ACCOUNT:`${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TOOLTIP}.${KEY_STRINGS.DELETE_TEST_ACCOUNT_TOOLTIP}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_FILED_COMPLETE_TESTING_BY:`${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_FILED}.${KEY_STRINGS.COMPLETE_TESTING_BY}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_ADD_NEW_TEST_ACCOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_TITLE}.${KEY_STRINGS.ADD_NEW_TEST_ACCOUNTS}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_UPDATE_TEST_ACCOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_TITLE}.${KEY_STRINGS.UPDATE_TEST_ACCOUNTS}`,
    BLS_INTERNALPREVIEWSERVICE_MODAL_TITLE_DELETE_TEST_ACCOUNT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODAL_TITLE}.${KEY_STRINGS.DELETE_TEST_ACCOUNTS}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_NO_TEST_ACCOUNT_RESULT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.NO_TEST_ACCOUNT_RESULT}`,
    BLS_INTERNALPREVIEWSERVICE_MODEL_CONFIRM_TEST_ACCOUNT_TRUE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODEL_TEXT}.${KEY_STRINGS.MODAL_CONFIRM_TEST_ACCOUNT_TRUE}`,
    BLS_INTERNALPREVIEWSERVICE_MODEL_CONFIRM_TEST_ACCOUNT_FALSE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MODEL_TEXT}.${KEY_STRINGS.MODAL_CONFIRM_TEST_ACCOUNT_FALSE}`,
    BLS_INTERNALPREVIEWSERVICE_FORM_PLACEHOLDER_TESTER_ROLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_FORM_PLACEHOLDER}.${KEY_STRINGS.TESTER_ROLE}`,
    BLS_INTERNALPREVIEWSERVICE_FORM_PLACEHOLDER_TESTER_EMAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_FORM_PLACEHOLDER}.${KEY_STRINGS.TESTER_EMAIL}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TEXT_NO_SERVICE_RESULT_MESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TEXT}.${KEY_STRINGS.NO_SERVICE_RESULT}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_VERSION_NO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.VERSION_NO}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_APPLICATION_TYPE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.APPLICATION_TYPE}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_PUBLISH_STATUS_TO_LIVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.PUBLISH_STATUS_TO_LIVE}`,
    BLS_INTERNALPREVIEWSERVICE_TABLE_TITLE_CHECK_BOX: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_TABLE_TITLE}.${KEY_STRINGS.CHECK_BOX}`,
    BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PREVIEW_SERVICE_DRAFT_TAB_TITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MESSAGE_SECTION}.${KEY_STRINGS.PREVIEW_SERVICE_DRAFT_TAB_TITLE}`,
    BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PUBLIC_PREVIEW_SITE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MESSAGE_SECTION}.${KEY_STRINGS.PUBLIC_PREVIEW_SITE}`,
    BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_INTRANET_PREVIEW_SITE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MESSAGE_SECTION}.${KEY_STRINGS.INTRANET_PREVIEW_SITE}`,
    BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PREVIEW_SERVICE_NOTE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MESSAGE_SECTION}.${KEY_STRINGS.PREVIEW_SERVICE_NOTE}`,
    BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PREVIEW_SERVICE_NOTE_MESSAGE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MESSAGE_SECTION}.${KEY_STRINGS.PREVIEW_SERVICE_NOTE_MESSAGE}`,
    BLS_INTERNALPREVIEWSERVICE_MESSAGE_SECTION_PREVIEW_SERVICE_PREVIEW_TAB_TITLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALPREVIEWSERVICE_MESSAGE_SECTION}.${KEY_STRINGS.PREVIEW_SERVICE_PREVIEW_TAB_TITLE}`,
    // FormGenerator
    BLS_INTERNALAPPCONFIG_FORM_FIELD_FORMGENERATOR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALLICENCEAPPCONFIG_FORM_FIELD}.${KEY_STRINGS.FORMGENERATOR}`,
}

//Const: Error Messages
export const ERROR = {
    UNABLETOGENARATEDOCUMENT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.UNABLETOGENARATEDOCUMENT}`,
    CONTACTAGENCYFORSUPPORT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.CONTACTADMINFORSUPPORT}`,
    REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.LABEL_REQUIRED}`,
    LABEL: '<%= label %> ',
    TICK_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TICK_REQUIRED}`,
    MIN_VALUE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MIN_VALUE}`,
    MIN_VALUE_LABEL: ' <%= minValue %>.',
    MAX_VALUE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MAX_VALUE}`,
    MAX_VALUE_LABEL: ' <%= maxValue %>.',
    FILE_LIMIT_REACHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FILE_LIMIT_REACHED}`,
    LIMIT_LABEL: ' <%= limit %>.',
    FILE_ITEM_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FILE_REQUIRED}`,
    FILE_DESC_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FILE_DESC_REQUIRED}`,
    FORM_VALIDATION_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FORM_VALIDATION_FAIL}`,
    FORM_DEFAULT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FORM_DEFAULT_FAIL}`,
    PASSWORD_REGEX_UNMATCH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.PASSWORD_REGEX_UNMATCH}`,
    EMAIL_INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.EMAIL_INVALID}`,
    MIN_LENGTH_LIMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MIN_LENGTH_LIMIT}`,
    MIN_LENGTH_LABEL: ' <%= minLength %>.',
    MAX_LENGTH_LIMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MAX_LENGTH_LIMIT}`,
    MAX_LENGTH_LABEL: ' <%= maxLength %>.',
    TELEPHONE_INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TELEPHONE_INVALID}`,
    NUMBER_INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.NUMBER_INVALID}`,
    APPLICATION_DELETE_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.APPLICATION_DELETE_FAIL}`,
    LIST_RETRIEVAL_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.LIST_RETRIEVAL_FAIL}`,
    DRAFT_SAVED_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DRAFT_SAVED_FAIL}`,
    DRAFT_EMPTY_FIELD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DRAFT_EMPTY_FIELD}`,
    DATE_INVALID: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DATE_INVALID}`,
    //Work Hour Error
    TIME_INVALID_WE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TIME_INVALID_WE}`,
    TIME_INVALID_BS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TIME_INVALID_BS}`,
    TIME_INVALID_BSWE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TIME_INVALID_BSWE}`,
    TIME_INVALID_BE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TIME_INVALID_WE}`,
    SERVER_UNREACHABLE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.SERVER_UNREACHABLE}`,
    TREE_QUESTION_SET_EMPTY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TREE_QUESTION_SET_EMPTY}`,
    TREE_QUESTION_SET_ANSWER_EMPTY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.TREE_QUESTION_SET_ANSWER_EMPTY}`,
    SEQUENCE_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.SEQUENCE_ERROR}`,
    PAYMENT_ITEMS_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.PAYMENT_ITEMS_REQUIRED}`,
    VALUE_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.VALUE_REQUIRED}`,
    SELECT_DECLARATION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.SELECT_DECLARATION}`,
    DAY_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DAY_REQUIRED}`,
    MONTH_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.MONTH_REQUIRED}`,
    YEAR_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.YEAR_REQUIRED}`,
    EFFECTIVE_DATE_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.EFFECTIVE_DATE_ERROR}`,
    VALID_NUMBER: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.VALID_NUMBER}`,
    VALID_NUM_GREATER_ZERO: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.VALID_NUM_GREATER_ZERO}`,
    VALID_NUM_ZERO_TO_TWELVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.VALID_NUM_ZERO_TO_TWELVE}`,
    VALID_NUM_ZERO_TO_THIRTYONE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.VALID_NUM_ZERO_TO_THIRTYONE}`,
    REMARKS_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.REMARKS_REQUIRED}`,
    DATE_LATER_THAN_EXPIRY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DATE_LATER_THAN_EXPIRY}`,
    DATE_EQUAL_PREVIOUS_SUSPENSION: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DATE_EQUAL_PREVIOUS_SUSPENSION}`,
    START_DATE_BEFORE_END: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.START_DATE_BEFORE_END}`,
    SERVICE_DATA_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.SERVICE_DATA_NOT_FETCHED}`,
    STATUS_DATA_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.STATUS_DATA_NOT_FETCHED}`,
    SUPPORTING_DOCS_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.SUPPORTING_DOCS_NOT_FETCHED}`,
    FORM_NOT_PROCESSED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.FORM_NOT_PROCESSED}`,
    INVALID_SELECTED_ITEM: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.INVALID_SELECTED_ITEM}`,
    PREVIEW_NO_FILE_FOUND: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PREVIEW_NO_FILE_FOUND}`,
    REINSTATEMENT_NOT_PROCESSED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.REINSTATEMENT_NOT_PROCESSED}`,
    REVOCATION_NOT_PROCESSED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.REVOCATION_NOT_PROCESSED}`,
    RECOMMEND_RULE_NOT_UPDATED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.RECOMMEND_RULE_NOT_UPDATED}`,
    REPORT_HISTORY_LOAD_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.REPORT_HISTORY_LOAD_FAIL}`,
    PLEASE_UPLOAD_FILE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PLEASE_UPLOAD_FILE}`,
    FILE_PROCESS_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.FILE_PROCESS_FAIL}`,
    APP_CONFIG_NOT_ADDED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.APP_CONFIG_NOT_ADDED}`,
    PREV_END_DATE_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PREV_END_DATE_NOT_FETCHED}`,
    SERVER_UNREACHABLE2: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.SERVER_UNREACHABLE2}`,
    NOTIF_TEMPLATE_NOT_ADDED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.NOTIF_TEMPLATE_NOT_ADDED}`,
    NOTIF_TEMPLATE_NOT_UPDATED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.NOTIF_TEMPLATE_NOT_UPDATED}`,
    HELP_TREE_NOT_CREATED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.HELP_TREE_NOT_CREATED}`,
    HELP_TREE_NOT_UPDATED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.HELP_TREE_NOT_UPDATED}`,
    PUBLIC_HOLIDAY_NOT_ADDED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PUBLIC_HOLIDAY_NOT_ADDED}`,
    PUBLIC_HOLIDAY_NOT_UPDATED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PUBLIC_HOLIDAY_NOT_UPDATED}`,
    WORKING_HOUR_NOT_ADDED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.WORKING_HOUR_NOT_ADDED}`,
    EXCEPTION_DATE_NOT_ADDED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.EXCEPTION_DATE_NOT_ADDED}`,
    SOMETHING_WENT_WRONG: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.SOMETHING_WENT_WRONG}`,
    EXCEPTION_NOT_UPDATED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.EXCEPTION_NOT_UPDATED}`,
    PAYMENT_ITEM_REQUIRED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PAYMENT_ITEM_REQUIRED}`,   
    SERVICE_WORKER_REG_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.SERVICE_WORKER_REG_ERROR}`,   
    ENQUIRY_PROCESS_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.ENQUIRY_PROCESS_FAIL}`,   
    REPORT_GENERATE_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.REPORT_GENERATE_FAIL}`,   
    PREPARE_PAYMENT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PREPARE_PAYMENT_FAIL}`,
    PROCESS_PAYMENT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PROCESS_PAYMENT_FAIL}`,
    PAYMENT_FAIL: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PAYMENT_FAIL}`,
    FILE_FORMAT_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.FILE_FORMAT_ERROR}`,
    FILE_SIZE_ERROR: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.FILE_SIZE_ERROR}`,
    CANNOT_GET_PAYMENT_DETAILS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.CANNOT_GET_PAYMENT_DETAILS}`,
    PAYMENT_DETAILS_NOT_FETCHED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.PAYMENT_DETAILS_NOT_FETCHED}`,
    NO_PAYMENT_ADDED: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_ERROR}.${KEY_STRINGS.NO_PAYMENT_ADDED}`,
    //Key to get error messages from backend
    BACKEND_ERROR_MESSAGE: `${DEFAULT_APPLICATION_NAME}.%Key%`
}

// Const: Success Messages
export const SUCCESS = {
    FORM_DEFAULT_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.FORM_DEFAULT_SUCCESS}`,
    APPLICATION_DELETE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.APPLICATION_DELETE_SUCCESS}`,
    COMPONENT_BEFORE: '<%= component %> ',
    CREATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.CREATE_SUCCESS}`,
    UPDATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.UPDATE_SUCCESS}`,
    DELETE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.DELETE_SUCCESS}`,
    PUBLISH_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.PUBLISH_SUCCESS}`,
    REVERT_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.REVERT_SUCCESS}`,
    CREATE_DRAFT_SUCCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_HELPTEXT}.${KEY_STRINGS.CREATE_DRAFT_SUCCESS}`,
    FORM_PROCESS_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.FORM_PROCESS_SUCCESS}`,
    REINSTATE_SERVICE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.REINSTATE_SERVICE_SUCCESS}`,
    REVOKE_SERVICE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.REVOKE_SERVICE_SUCCESS}`,
    SUSPEND_SERVICE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.SUSPEND_SERVICE_SUCCESS}`,
    RECOMMEND_RULE_UPDATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.RECOMMEND_RULE_UPDATE_SUCCESS}`,
    SERVICE_SUSPENSION_UPDATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.SERVICE_SUSPENSION_UPDATE_SUCCESS}`,
    DELETED_SUCCESSFULLY: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.DELETED_SUCCESSFULLY}`,
    NOTIF_TEMPLATE_ADD_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.NOTIF_TEMPLATE_ADD_SUCCESS}`,
    NOTIF_TEMPLATE_UPDATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.NOTIF_TEMPLATE_UPDATE_SUCCESS}`,
    HELP_TREE_CREATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.HELP_TREE_CREATE_SUCCESS}`,
    HELP_TREE_UPDATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.HELP_TREE_UPDATE_SUCCESS}`,
    PUBLIC_HOLIDAY_ADD_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.PUBLIC_HOLIDAY_ADD_SUCCESS}`,
    PUBLIC_HOLIDAY_UPDATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.PUBLIC_HOLIDAY_UPDATE_SUCCESS}`,
    WORKING_HOUR_ADD_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.WORKING_HOUR_ADD_SUCCESS}`,
    WORKING_HOUR_UPDATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.WORKING_HOUR_UPDATE_SUCCESS}`,
    EXCEPTION_DATE_ADD_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.EXCEPTION_DATE_ADD_SUCCESS}`,
    CATEGORY_DELETE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.CATEGORY_DELETE_SUCCESS}`,
    EXCEPTION_DELETE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.EXCEPTION_DELETE_SUCCESS}`,
    EXCEPTION_UPDATE_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.EXCEPTION_UPDATE_SUCCESS}`,
    FAQ_SET_CREATED_SUCCESS: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_SUCCESS}.${KEY_STRINGS.FAQ_SET_CREATED_SUCCESS}`,
    //Key to get success messages from backend
    BACKEND_SUCCESS_MESSAGE: `${APP_NAME}.%Key%`
}

//Const: Button Labels
export const BUTTON = {
    CREATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.CREATE}`,
    SAVE_CHANGES: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.SAVE_CHANGES}`,
    BACK: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.BACK}`,
    SUBMIT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.SUBMIT}`,
    SAVE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.SAVE}`,
    PUBLISH: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.PUBLISH}`,
    CREATE_DRAFT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.CREATE_DRAFT}`,
    DELETE_DRAFT: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.DELETE_DRAFT}`,
    PREVIEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.PREVIEW}`,
    NEW: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.NEW}`,
    //COMPONENT_AFTER is placed after NEW
    COMPONENT_AFTER: ' <%= component %>',
    ADD: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.ADD}`,
    UPDATE: `${APP_NAME}.${SCOPE_CAT_SUBCAT_STRINGS.INTERNALGENERAL_CONTENT_BUTTON}.${KEY_STRINGS.UPDATE}`
}

export const DEFAULT_SCOPE = {
    APPCONFIGSTATIC: 'AppConfigStatic',
    CODESTATIC: 'CodeStatic',
    MENU: 'Menu',
    DEFAULTSTATIC: 'DefaultStatic',
    FAQSTATIC: 'FaqStatic',
    LOCALESTATIC: 'LocaleStatic',
    PUBLICHOLIDAYSTATIC: 'PublicHolidayStatic',
    NOTITEMPLATESTATIC: 'NotiTemplateStatic'
}

export const DEFAULT_CATEGORY_SUBCATEGORY = {
    CONTENT_TITLE: 'Content.Title',
    CONTENT_DROPDOWN: 'Content.Dropdown',
    NAVIGATION_BUTTON: 'Navigation.Button',
    CONTENT_PLACEHOLDER: 'Content.Placeholder',
    CONTENT_TABLE: 'Content.Table',
    FORM_TITLE: 'Form.Title',
    FORM_VALUE: 'Form.Value',
    FORM_HELPTEXT: 'Form.HelpText',
    FORM_PLACEHOLDER: 'Form.Placeholder',
    MODAL_TITLE: 'Modal.Title',
    MODAL_TEXT: 'Modal.Text',
    TABLE_PLACEHOLDER: 'Table.Placeholder',
    TABLE_TOOLTIP: 'Table.Tooltip',
    TABLE_TEXT: 'Table.Text',
    SUCCESS_TITLE: 'Success.Title',
    ERROR_TITLE: 'Error.Title',
    TREE_TITLE: 'Tree.Title',
    BADGE_TEXT: 'Badge.Text',
    CONTENT_TEXT: 'Content.Text',
    FORM_DIVIDER: 'Form.Divider'
}

export const DEFAULT_KEY = {
    APPLICATIONCONFIGURATIONMANAGEMENT: 'ApplicationConfigurationManagement',
    NEWCONFIGURATION: 'NewConfiguration',
    ALLCATEGORIES: 'AllCategories',
    FILTER: 'Filter',
    NO: 'No',
    ACTIONS: 'Actions',
    CATEGORY: 'Category',
    NAME: 'Name',
    VALUETYPE: 'ValueType',
    VALUE: 'Value',
    EFFECTIVESTARTDATE: 'EffectiveStartDate',
    EFFECTIVEENDDATE: 'EffectiveEndDate',
    UPDATECONFIGURATION: 'UpdateConfiguration',
    LOCALESETTINGS: 'LocaleSettings',
    ISDEFAULTGLOBALAPPENABLED: 'IsDefaultGlobalAppEnabled',
    GENERAL: 'General',
    CURRENCY: 'Currency',
    DATE: 'Date',
    EMAIL: 'Email',
    NUMBER: 'Number',
    DECIMAL: 'Decimal',
    BACK: 'Back',
    VIEWCONFIGURATION: 'ViewConfiguration',
    CREATEDBY: 'CreatedBy',
    CREATEDDATE: 'CreatedDate',
    LASTUPDATEDBY: 'LastUpdatedBy',
    LASTUPDATEDDATE: 'LastUpdatedDate',
    NORECORD: 'NoRecord',
    DELETEAPPCONFIG: 'DeleteAppConfig',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    CREATE: 'Create',
    CODETABLEMANAGEMENT: 'CodeTableManagement',
    NEWCODE: 'NewCode',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    STATUS: 'Status',
    PARENTCODE: 'ParentCode',
    UPDATECODETABLEENTRY: 'UpdateCodeTableEntry',
    PARENTCODECATEGORY: 'ParentCodeCategory',
    PARENTCATEGORY: 'ParentCategory',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    SEQUENCENUMBER: 'SequenceNumber',
    COUNTRY: 'Country',
    SAVECHANGES: 'SaveChanges',
    CODETABLEENTRYDETAILS: 'CodeTableEntryDetails',
    NEWCODETABLEENTRY: 'NewCodeTableEntry',
    UPDATECODE: 'UpdateCode',
    VIEWCODE: 'ViewCode',
    VIEWCONFIG: 'ViewConfiguration',
    DELETECONFIG: 'DeleteConfiguration',
    LANGUAGE: 'Language',
    CODECATEGORY: 'CodeCategory',
    SERVERUNREACHABLE: 'ServerUnreachable',
    UPDATE: 'Update',
    CATEGORYREQUIRED: 'CategoryRequired',
    NAMEREQUIRED: 'NameRequired',
    QUESTION: 'Question',
    TAGS: 'Tags',
    REQUIRED: 'Required',
    MINLENGTH: 'MinLength',
    MAXLENGTH: 'MaxLength',
    DATEINVALID: 'DateInvalid',
    DELETECONFIGURATION: 'DeleteConfiguration',
    FAQMANAGEMENT: 'FaqManagement',
    NEWFAQ: 'NewFaq',
    APPLICATION: 'Application',
    LASTPUBLISHED: 'LastPublished',
    MULTILANG: 'MultiLang',
    QNAMAKER: 'QnAMaker',
    ADDFAQ: 'AddFaq',
    SELECTLANG: 'SelectLang',
    ENABLEAI: 'EnableAI',
    ENABLEMULTILANG: 'EnableMultiLang',
    EDITFAQ: 'EditFaq',
    PREVIEW: 'Preview',
    DELETEDRAFT: 'DeleteDraft',
    FAQTREE: 'FaqTree',
    DELETETOPIC: 'DeleteTopic',
    DELETESUBTOPIC: 'DeleteSubTopic',
    DELETEQNSET: 'DeleteQnSet',
    UPDATEQNSET: 'UpdateQnSet',
    UPDATETOPIC: 'UpdateTopic',
    UPDATEFAQ: 'UpdateFaq',
    UPDATEFAQSUBTOPIC: 'UpdateSubTopic',
    CREATETOPIC: 'CreateTopic',
    CREATESUBTOPIC: 'CreateSubTopic',
    CREATEQNSET: 'CreateQnSet',
    SETTINGS: 'Settings',
    SAVE: 'Save',
    AICHATBOT: 'AIChatbot',
    ANSWER: 'Answer',
    YES: 'Yes',
    FAQDESC: 'FaqDesc',
    RANK: 'Rank',
    TYPE: 'Type',
    SORTRANKUP: 'SortRankUp',
    SORTRANKDOWN: 'SortRankDown',
    QNSETNORECORD: 'QnSetNoRecord',
    FAQTOPICTITLE: 'FaqTopicTitle',
    ADD: 'Add',
    FAQSUBTOPICTITLE: 'FaqSubTopicTitle',
    FAQANS: 'FaqAns',
    TOGGLETREEVIEW: 'ToggleTreeView',
    SEARCH_QNSET: 'SearchQnSet',
    FAQQNSETS: "FaqQnSets",
    MOVEUP: "MoveUp",
    MOVEDOWN: "MoveDown",
    FAQSUBTOPICDESC: "FaqSubTopicDesc",
    FAQTOPICDESC: "FaqTopicDesc",
    ADDQN: "AddQn",
    PRIQN: "PriQn",
    REMOVE: "Remove",
    EDITFAQTOPIC: "EditFaqTopic",
    NEWFAQTOPIC: "NewFaqTopic",
    NEWFAQSUBTOPIC: "NewFaqSubTopic",
    NEWFAQQNSET: "NewFaqQnSet",
    EDITFAQSUBTOPIC: "EditFaqSubTopic",
    EDITFAQQNSET: "EditFaqQnSet",
    VIEWFAQ: "ViewFaq",
    VIEWFAQTOPIC: "ViewFaqTopic",
    VIEWFAQSUBTOPIC: "ViewFaqSubTopic",
    VIEWFAQQNSET: "ViewFaqQnSet",
    VIEWQNSET: "ViewQnSet",
    EDITQNSET: "EditQnSet",
    PRIMARY: "Primary",
    ADDNEWFAQTOPIC: "AddNewFaqTopic",
    ADDNEWFAQSUBTOPIC: "AddNewFaqSubTopic",
    ADDNEWQNSET: "AddNewQnSet",
    LOADING: "Loading",
    FAQ: "Faq",
    TOPIC: "Topic",
    SUBTOPIC: "SubTopic",
    SCOPEMANAGEMENT: "ScopeManagement",
    CATEGORYMANAGEMENT: "CategoryManagement",
    SUBCATEGORYMANAGEMENT: "SubCategoryManagement",
    NEWSCOPE:"NewScope",
    SCOPENORECORD: "ScopeNoRecord",
    CATEGORYNORECORD: "CategoryNoRecord",
    SUBCATEGORYNORECORD: "SubCategoryNoRecord",
    NEWCATEGORY: "NewCategory",
    NEWSUBCATEGORY: "NewSubCategory",
    EDITSCOPE: "EditScope",
    EDITCATEGORY: "EditCategory",
    EDITSUBCATEGORY: "EditSubCategory",
    VIEWSCOPE: "ViewScope",
    VIEWCATEGORY: "ViewCategory",
    VIEWSUBCATEGORY: "ViewSubCategory",
    UPDATESCOPE: "UpdateScope",
    UPDATECATEGORY: "UpdateCategory",
    UPDATESUBCATEGORY: "UpdateSubCategory",
    CREATESCOPE: "CreateScope",
    CREATECATEGORY: "CreateCategory",
    CREATESUBCATEGORY: "CreateSubCategory",
    ADDSCOPE: "AddScope",
    ADDCATEGORY: "AddCategory",
    ADDSUBCATEGORY: "AddSubCategory",
    SUBCATEGORY: "SubCategory",
    SCOPE: "Scope",
    LANGUAGEPACK: "LanguagePack",
    DOWNLOAD: "Download",
    PUBLISH: "Publish",
    UPLOADFILE: "UploadFile",
    TRANSDATANORECORD: "TransDataNoRecord",
    LANGUAGEREG: "LanguageReg",
    REGLANGUAGE: "RegLanguage",
    APPNORECORD: "AppNoRecord",
    DEFAULT: "Default",
    EDITAPPLANG: "EditAppLang",
    ADDLANG: "AddLang",
    LANGSETTINGS: "LangSettings",
    DEFAULTLANG: "DefaultLang",
    UPDATEAPPLANG: "UpdateAppLang",
    VALIDATE: "Validate",
    TRANSDATA: "TransData",
    LANGKEYUPDATE: "LangKeyUpdate",
    LANGKEY: "LangKey",
    ALIGNRIGHT: "AlignRight",
    SUBMIT: "Submit",
    FULLKEY: "FullKey",
    EDITLANGVALUE: "EditLangValue",
    UPDATEKEY: "UpdateKey",
    CREATETRANS: "CreateTrans",
    ADDLANGENTRY: "AddLangEntry",
    ADDVALUE: "AddValue",
    KEY: "Key",
    FILTERBYSCOPE: "FilterByScope",
    FILTERBYCAT: "FilterByCat",
    FILTERBYSUBCAT: "FilterBySubCat",
    FILTERBYPUBLISHED: "FilterByPublished",
    PUBLISHED: "Published",
    SORTBY: "SortBy",
    SEARCH: "Search",
    NEWENTRY: "NewEntry",
    TRANSNORECORD: "TransNoRecord",
    DEFAULTVALUE: "DefaultValue",
    UPDATEDBY: "UpdatedBy",
    UPDATEDDATE: "UpdatedDate",
    REVERT: "Revert",
    TRANSMANAGEMENT: "TransManagement",
    DELETETRANSKEY: "DeleteTransKey",
    PUBLISHTRANSKEY: "PublishTransKey",
    SOFTDELETETRANSKEY: "SoftDeleteTransKey",
    REVERTTRANSKEY: "RevertTransKey",
    HARDDELETETRANSKEY: "HardDeleteTransKey",
    DRAFTED: "Drafted",
    DELETED: "Deleted",
    ASCKEY: "AscKey",
    DESCKEY: "DescKey",
    ASCUPDATEDDATE: "AscUpdatedDate",
    DESCUPDATEDDATE: "DescUpdatedDate",
    LASTEDITEDBY: "LastEditedBy",
    PUBLISHTRANS: "PublishTrans",
    DELETETRANS: "DeleteTrans",
    REVERTTRANS: "RevertTrans",
    ENTERVALUE: "EnterValue",
    CHARREMAINING: "CharRemaining",
    FAQTITLE: "FaqTitle",
    DELETEFAQ: "DeleteFaq",
    PUBLISHFAQ: "PublishFaq",
    PREVIEWFAQ: "PreviewFaq",
    PUBLISHFAQLOCALE: "PublishFaqLocale",
    ALLQUESTIONSREQUIRED: "AllQuestionsRequired",
    ANSWERREQUIRED: "AnswerRequired",
    DELETENODE: "DeleteNode",
    NOQUESTIONS: "NoQuestions",
    DISPLAYANSWER: "DisplayAnswer",
    ADDPUBLICHOLIDAY: "AddPublicHoliday",
    CREATEPUBLICHOLIDAY: "CreatePublicHoliday",
    PUBLICHOLIDAY: "PublicHoliday",
    REMARKS: "Remarks",
    PUBLICHOLIDAYMGMT: "PublicHolidayMgmt",
    YEAR: "Year",
    ALLYEARS: "AllYears",
    NEWPUBLICHOLIDAY: "NewPublicHoliday",
    PUBLICHOLIDAYNORECORD: "PublicHolidayNoRecord",
    DELETEPUBLICHOLIDAY: "DeletePublicHoliday",
    UPDATEPUBLICHOLIDAY: "UpdatePublicHoliday",
    NOTITEMPLATEMANAGEMENT: "NotiTemplateManagement",
    SMSTEMPLATEMANAGEMENT: "SmsTemplateManagement",
    NEWSMSTEMPLATE: "NewSmsTemplate",
    DELETESMSTEMPLATE: "DeleteSmsTemplate",
    SMSTEMPLATENORECORD: "SmsTemplateNoRecord",
    TEMPLATENAME: "TemplateName",
    VERSION: "Version",
    CREATESMSTEMPLATE: "CreateSmsTemplate",
    TEMPLATESUBJECT: "TemplateSubject",
    TEMPLATEBODY: "TemplateBody",
    UPDATESMSTEMPLATE: "UpdateSmsTemplate",
    VIEWSMSTEMPLATE: "ViewSmsTemplate",
    VIEWPUBLICHOLIDAY: "ViewPublicHoliday",
    PUBLISHBYSCOPE: "PublishByScope",
    PUBLISHBYAPP: "PublishByApp",
    CREATEEMAILTEMPLATE: "CreateEmailTemplate",
    NEWEMAILTEMPLATE: "NewEmailTemplate",
    DELETEEMAILTEMPLATE: "DeleteEmailTemplate",
    UPDATEEMAILTEMPLATE: "UpdateEmailTemplate",
    EMAILTEMPLATENORECORD: "EmailTemplateNoRecord"
}

export const DEFAULT_TEXT = {
    ALL_CATEGORIES: "All Categories",
    ALL_CONFIGURATIONS: "All Configurations",
    FILTER: "Filter",
    NEW_CONFIGURATION: "New Configuration",
    BACK: "Back",
    NUM: "No.",
    ACTIONS: "Actions",
    CATEGORY: "Category",
    NAME: "Name",
    VALUE_TYPE: "Value Type",
    VALUE: "Value",
    EFFECTIVE_START_DATE: "Effective Start Date",
    EFFECTIVE_END_DATE: "Effective End Date",
    APPCONFIG_MANAGEMENT: "Application Configuration Management",
    CONFIRM: "Confirm",
    CANCEL: "Cancel",
    DELETE_APPCONFIG_MODAL_TEXT: "<div>Are you sure you want to delete the Application Configuration? <br />Please be reminded that this action cannot be undone.</div>",
    DELETE_APPCONFIG_MODAL_TITLE: "Delete Application Configuration?",
    EDIT: 'Edit',
    VIEW: 'View',
    DELETE: 'Delete',
    UPDATE: 'Update',
    APPCONFIG_NO_RECORD: "No Application Configuration(s) Found",
    HELPTEXT_CATEGORY: "Please choose an existing category, or type a new one then press enter to create a new option.",
    CURRENCY: "Currency",
    DATE: "Date",
    DECIMAL: "Decimal",
    EMAIL: "Email",
    GENERAL: "General",
    NUMBER: "Number",
    PLACEHOLDER_VALUETYPE: "Value Type",
    HELPTEXT_VALUETYPE: "Select a Value Type",
    DESCRIPTION: "Description",
    HELPTEXT_DESCRIPTION: "Please key in a description",
    HELPTEXT_CURRENCY: "Currency: $10.00 or 10.00",
    HELPTEXT_DATE: "Date: 01-Aug-2020",
    HELPTEXT_DECIMAL: "Decimal: 10.0 or 10",
    HELPTEXT_EMAIL: "Email: abc@xyz.com",
    HELPTEXT_NUMBER: "Number: 1",
    VIEWCONFIGURATION: "View Configuration",
    CREATED_BY: "Created By",
    CREATED_DATE: "Created Date",
    LAST_UPDATED_DATE: "Last Updated Date",
    LAST_UPDATED_BY: "Last Updated By",
    CODE_TABLE_MANAGEMENT: "Code Table Management",
    CODE_NO_RECORD: "No Code(s) Found",
    STATUS: "Status",
    TOOLTIP_UPDATE_CODE: "Update Code",
    TOOLTIP_VIEW_CODE: "View Code",
    UPDATE_CONFIGURATION: "Update Configuration",
    VIEW_CONFIGURATION: "View Configuration",
    DELETE_CONFIGURATION: "Delete Configuration",
    NEW_CODE: "New Code Table Entry",
    HELPTEXT_PARENT_CATEGORY: "Choose a parent category to help narrow down the parent code list when choosing.",
    HELPTEXT_SEQUENCE_NUMBER: "Sequence number used for ordering purpose. Default order by ascending.",
    SEQUENCE_NO: "Sequence Number",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    PARENT_CODE: "Parent Code",
    PARENT_CODE_CATEGORY: "Parent Code Category",
    HELPTEXT_CODE_CATEGORY: "Please choose an existing code category, or type a new one then press enter to create a new option.",
    CREATE: "Create",
    UPDATE_CODE: "Update Code Table Entry",
    VIEW_CODE: "Code Table Entry Details",
    TITLE: "Title",
    CODE_CATEGORY: "Code Category",
    FAQ_MANAGEMENT: "FAQ Management",
    NEW_FAQ: "New FAQ",
    APPLICATION: "Application",
    LAST_PUBLISHED: "Last Published",
    MULTI_LANGUAGE: "Multi-Language",
    QNAMAKER: "QnAMaker",
    FAQ_NO_RECORD: "No FAQ(s) were found",
    SETTINGS: "Settings",
    CREATE_DRAFT: "Create Draft",
    ADD_FAQ: "Add FAQ",
    SELECT_APP: "Select an Application",
    ENABLE_AI: "Enable AI Chatbot",
    ENABLE_MULTI_LANG: "Enable Mult-Language",
    SELECT_LANG: "Select a Language",
    EDIT_FAQ: "Edit FAQ",
    FAQ_TREE: "FAQ Tree",
    FAQ_SETTINGS: "FAQ Settings",
    SAVE: "Save",
    AI_CHATBOT: "AI Chatbot",
    FAQ: "FAQ",
    FAQ_TOPIC: "FAQ Topic",
    FAQ_SUB_TOPIC: "FAQ Sub-Topic",
    FAQ_QN_SET: "FAQ Question Set",
    FAQ_QN_SET_UPDATE_SUCCESS: "FAQ question set, questions and answers have been created succesfully.",
    HELPTEXT_TAGS: "Please input the value and press enter to create a new tag.",
    ANSWER: "Answer",
    QUESTION: "Question",
    YES: "Yes",
    NO: "No",
    FAQ_TITLE: "FAQ Title",
    FAQ_DESCRIPTION: "FAQ Description",
    FAQ_TOPIC_DESCRIPTION: "FAQ Topic Description",
    FAQ_SUB_TOPIC_DESCRIPTION: "FAQ Sub-Topic Description",
    PRIMARY_QN: "PrimaryQuestion",
    TYPE: "Type",
    RANK: "Rank",
    FAQQNSET_NO_RECORD: "No Question Sets in Sub-Topic",
    FAQ_TOPIC_TITLE: "FAQ Topic Title",
    ADD: "Add",
    FAQ_SUB_TOPIC_TITLE: "FAQ Sub-Topic Title",
    TAGS: "Tags",
    FAQ_ANSWER: "FAQ Answer",
    TOGGLE_TREE_VIEW: "Toggle Tree View",
    SEARCH_QNSET: "Search Question Set...",
    BTN_FAQ_QNSETS: "Question Sets",
    MOVEUP: "Move Up",
    MOVEDOWN: "Move Down",
    ADD_MORE_QN: "Add More Question",
    REMOVE: "Remove",
    EDIT_FAQ_TOPIC: "Edit FAQ Topic",
    NEW_FAQ_TOPIC: "New FAQ Topic",
    NEW_FAQ_SUB_TOPIC: "New FAQ Sub-Topic",
    NEW_FAQ_QNSET: "New FAQ Question Set",
    EDIT_FAQ_SUB_TOPIC: "Edit FAQ Sub-Topic",
    EDIT_FAQ_QNSET: "Edit FAQ Question Set",
    VIEW_FAQ: "View FAQ",
    VIEW_FAQ_TOPIC: "View FAQ Topic",
    VIEW_FAQ_SUB_TOPIC: "View FAQ Sub-Topic",
    VIEW_FAQ_QNSET: "View FAQ QUestion Set",
    VIEW_QNSET: "View Question Set",
    EDIT_QN_SET: "Edit Question Set",
    PRIMARY: "(Primary)",
    ADD_NEW_FAQ_TOPIC: "Add new FAQ Topic",
    ADD_NEW_FAQ_SUB_TOPIC: "Add new FAQ Sub-Topic",
    ADD_NEW_QN_SET: "Add new Question Set",
    LOADING: "Loading...",
    TOPIC: "Topic",
    SUB_TOPIC: "Sub-Topic",
    SCOPE_MANAGEMENT: "Scope Management",
    CATEGORY_MANAGEMENT: "Category Management",
    SUB_CATEGORY_MANAGEMENT: "Sub-Category Management",
    NEW_SCOPE: "New Scope",
    SCOPE_NO_RECORD: "No Scope(s) found.",
    CATEGORY_NO_RECORD: "No Category(s) found.",
    SUBCATEGORY_NO_RECORD: "No Sub-Category(s) found.",
    NEW_CATEGORY: "New Category",
    NEW_SUB_CATEGORY: "New Sub-Category",
    EDIT_SCOPE: "Edit Scope",
    EDIT_CATEGORY: "Edit Category",
    EDIT_SUB_CATEGORY: "Edit Sub-Category",
    SCOPE_NAME: "Scope Name",
    SUB_CATEGORY_NAME: "Sub-Category Name",
    CATEGORY_NAME: "Category Name",
    VIEW_SCOPE: "View Scope",
    VIEW_CATEGORY: "View Category",
    VIEW_SUB_CATEGORY: "View Sub-Category",
    ADD_SCOPE: "Add Scope",
    ADD_CATEGORY: "Add Category",
    ADD_SUB_CATEGORY: "Add Sub-Category",
    HELPTEXT_SCOPENAME: "Scope name is required.",
    HELPTEXT_CATEGORY_NAME: "Category name is required.",
    HELPTEXT_SUB_CATEGORY_NAME: "Sub-Category name is required.",
    LANGUAGE_PACK: "Language Pack",
    ERROR_SELECT_APP: "Please select an application.",
    SELECT_SCOPE: "Select a scope",
    SCOPE: "Scope",
    ERROR_SELECT_SCOPE: "Please select a scope.",
    DOWNLOAD: "Download",
    PUBLISH: "Publish",
    UPLOAD_FILE: "Please upload a file.",
    TRANS_DATA_NO_RECORD: "No Translation Data was found.",
    DIVIDER_LANG_PACK: "Review of Updated Information",
    LANGUAGE_REG: "Language Registration",
    REG_LANGUAGE: "Registered Language",
    APP_NO_RECORD: "No Applications were found.",
    DEFAULT: "Default",
    EDIT_APP_LANG: "Edit Application Languages",
    ADD_LANG: "Add Language",
    LANG_SETTINGS: "Language Settings",
    ERROR_DEFAULT_LANG: "A default language has to be active.",
    VALIDATE: "Validate",
    SELECTLIST_SCOPE_NO_RECORD: "There are no scopes available.",
    NO_TRANS_DATA: "There is no translation data that has been updated.",
    LANG_KEY_UPDATE: "Please confirm the list of language keys below that has an update in the language translation.",
    LANG_KEY: "Language Key",
    LANGUAGE: "Language",
    ALIGN_TO_RIGHT: "Align To Right",
    SELECT_A_LANGUAGE: "Select a Language",
    UPLOAD_FILE_TITLE: "Upload File",
    SUBMIT: "Submit",
    FULL_KEY: "Full Key",
    EDIT_LANG_VALUE: "Edit Language Value",
    VALUE_REQUIRED: "Value is required.",
    ADD_LANG_ENTRY: "Add New Language Key And Value Entry",
    KEY: "Key",
    ADD_VALUE_FOR_KEY: "Add Value for Key",
    SELECT_SUB_CATEGORY: "Select a Sub-Category",
    SELECT_CATEGORY: "Select a Category",
    FILTER_BY_SCOPE: "Filter by Scope",
    FILTER_BY_CATEGORY: "Filter by Category",
    FILTER_BY_SUBCATEGORY: "Filter by Sub-Category",
    SUB_CATEGORY: "Sub-Category",
    FILTER_BY_PUBLISHED: "Filter by Published",
    PUBLISHED: "Published",
    SORT_BY: "Sort By",
    SEARCH: "Search",
    NEW_ENTRY: "New Entry",
    TRANS_NO_RECORD: "No Translations were found.",
    DEFAULT_VALUE: "Default Value",
    UPDATED_BY: "Updated By",
    UPDATED_DATE: "Updated Date",
    REVERT: "Revert",
    TRANSLATION_MANAGEMENT: "Translation Management",
    DELETE_TRANSKEY_MODAL_TITLE: "Delete Translation Key",
    REVERT_TRANSKEY_MODAL_TITLE: "Revert Translation Key",
    PUBLISH_TRANSKEY_MODAL_TITLE: "Publish entries",
    HARD_DELETE_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to delete this entry? <br />Please be reminded that this action cannot be undone.</div>",
    SOFT_DELETE_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to delete this entry? <br />Please note that this action <b>can</b> be reverted.</div>",
    REVERT_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to revert back to the published values for all languages in this entry? <br />Please be reminded that this action cannot be undone.</div>",
    PUBLISH_TRANSKEY_MODAL_TEXT: "<div>Are you sure you want to publish the entries for all languages in this application? <br />Please be reminded that this action cannot be undone.</div>",
    DRAFTED: "Drafted",
    DELETED: "Deleted",
    ASC_KEY: "Key (asc)",
    DESC_KEY: "Key (desc)",
    ASC_UPDATEDDATE: "Updated Date (asc)",
    DESC_UPDATEDDATE: "Updated Date (desc)",
    LAST_EDITED_BY: "Last edited by",
    DEFAULT_LANGUAGE: "Default Language",
    SUBCATEGORY: "Sub-Category",
    ADD_LANGUAGE: "Add Language",
    CHAR_REMAINING: "characters remaining",
    PUBLISH_FAQ_MODAL_TEXT: "<div>Are you sure you want to publish this FAQ? <br />Please be reminded that this action cannot be undone.</div>",
    PUBLISH_FAQ_MODAL_TITLE: "Publish Faq",
    DELETE_FAQ_MODAL_TITLE: "Delete FAQ draft",
    DELETE_FAQ_MODAL_TEXT: "<div>Are you sure you want to delete the Faq draft? <br />Please be reminded that this action cannot be undone.</div>",
    PREVIEW_FAQ_MODAL_TITLE: "Preview FAQ",
    PUBLISH_FAQ_LOCALE_MODAL_TEXT: "<br /><br />Are you sure you want to publish this FAQ? <br />Please be reminded that this action cannot be undone.",
    DELETE_DRAFT: "Delete Draft",
    PREVIEW: "Preview",
    FILL_UP_ALL_QUESTIONS: "Please fill up all the questions.",
    FILL_UP_ANSWER: "Please fill up the answer.",
    DELETE_NODE_MODAL_TITLE: "Delete Node",
    DELETE_NODE_MODAL_TEXT: "Are you sure you want to delete this node?",
    APPLICATION_CONFIGURATION: "Application Configuration",
    CODE: "Code",
    FAQ_DRAFT: "FAQ Draft",
    TRANSLATION: "Translation",
    APPLICATION_LANGUAGE: "Application Language",
    APPLICATION_TRANSLATION: "Application Translation",
    NO_QUESTIONS_AVAILABLE: "No questions available",
    DISPLAY_ANSWER: "Display Answer",
    ADD_PUBLIC_HOLIDAY: "Add Public Holiday",
    NAME_OF_PH: "Name of Public Holiday",
    PUBLIC_HOLIDAY: "Public Holiday",
    REMARKS: "Remarks",
    PUBLIC_HOLIDAY_MANAGEMENT: "Public Holiday Management",
    YEAR: "Year",
    ALL_YEARS: "All Years",
    NEW_PUBLIC_HOLIDAY: "New Public Holiday",
    PH_NO_RECORD: "No Public Holiday records found",
    DELETE_PH_MODAL_TEXT: "Are you sure you want to delete this public holiday?",
    DELETE_PH_MODAL_TITLE: "Delete Public Holiday",
    UPDATE_PUBLIC_HOLIDAY: "Update Public Holiday",
    SMS_TEMPLATE_MANAGEMENT: "SMS Template Management",
    SMS_TEMPLATE: "SMS Template",
    NEW_SMS_TEMPLATE: "New SMS Template",
    SMSTEMPLATE_NO_RECORD: "No SMS Template records found",
    TEMPLATE_NAME: "Template Name",
    VERSION: "Version",
    DELETE_SMSTEMP_MODAL_TITLE: "Delete SMS Template",
    DELETE_SMSTEMP_MODAL_TEXT: "<div>Are you sure you want to delete this SMS Template? <br />Please be reminded that this action cannot be undone.</div>",
    CREATE_SMS_TEMPLATE: "Create SMS Template",
    HELPTEXT_NOTITEMP_CATEGORY: "Please choose an existing category, or type a new one then press enter to create a new option.",
    TEMPLATE_SUBJECT: "Template Subject",
    TEMPLATE_BODY: "Template Body",
    HELPTEXT_NOTITEMP_TEMPLATESUBJECT: "Please type @ to get the list of available placeholders.",
    HELPTEXT_NOTITEMP_TEMPLATEBODY: "Please type @ to get the list of available placeholders.",
    VIEW_SMS_TEMPLATE: "View SMS Template",
    PUBLISH_BY_SCOPE: "Publish by Scope",
    PUBLISH_BY_APP_MODAL_TEXT: "<div>Note: Choosing <b>No</b> for Publish by Scope,<br />Translation Data will be published by <b>Application</b> level.<br />" + 
        "Please also note that Scope(s) with no translations will not be displayed in the list of scopes.<br /><br /></div>",
    PUBLISH_BY_SCOPE_MODAL_TEXT: "<div>Note: Choosing <b>Yes</b> for Publish by Scope,<br />Translation Data will be published by <b>Scope</b> level.<br /><br /></div>",
    EMAIL_TEMPLATE: "Email Template",
    CREATE_EMAIL_TEMPLATE: "Create Email Template",
    NEW_EMAIL_TEMPLATE: "New Email Template",
    EMAILTEMPLATE_NO_RECORD: "No Email Template records found",
}

export const DEFAULT_NAMESPACE = {
    // Application Configuration Management
    GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_APPCONFIGMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.APPLICATIONCONFIGURATIONMANAGEMENT}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_DROPDOWN_ALLCATEGORIES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ALLCATEGORIES}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_NEWCONFIGURATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWCONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_PLACEHOLDER_FILTER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_PLACEHOLDER}.${DEFAULT_KEY.FILTER}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_VALUETYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VALUETYPE}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_APPCONFIGSTATIC_CONTENT_TABLE_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_UPDATECONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.UPDATECONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_VIEWCONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEWCONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_TOOLTIP_DELETECONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DELETECONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_TABLE_TEXT_NORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.NORECORD}`,

    // Update Configuration
    GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_UPDATECONFIGURATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATECONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUETYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VALUETYPE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_LOCALESETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.LOCALESETTINGS}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_ISDEFAULTGLOBALAPPENABLED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.ISDEFAULTGLOBALAPPENABLED}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_GENERAL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.GENERAL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_CURRENCY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.CURRENCY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.DATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_DECIMAL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.DECIMAL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_EMAIL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.EMAIL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_VALUE_NUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_VALUE}.${DEFAULT_KEY.NUMBER}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_SAVECHANGES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SAVECHANGES}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_VALUETYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.VALUETYPE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_CURRENCY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CURRENCY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.DATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_DECIMAL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.DECIMAL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_EMAIL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.EMAIL}`,
    GLOBAL_APPCONFIGSTATIC_FORM_HELPTEXT_NUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.NUMBER}`,

    // View Configuration
    GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_VIEWCONFIGURATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWCONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_CREATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,

    // Delete Configuration
    GLOBAL_APPCONFIGSTATIC_MODAL_TITLE_DELETEAPPCONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEAPPCONFIG}`,
    GLOBAL_APPCONFIGSTATIC_MODAL_TEXT_DELETEAPPCONFIG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEAPPCONFIG}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,

    // New Application Configuration
    GLOBAL_APPCONFIGSTATIC_CONTENT_TITLE_NEWCONFIGURATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.NEWCONFIGURATION}`,
    GLOBAL_APPCONFIGSTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.NAME}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_APPCONFIGSTATIC_FORM_PLACEHOLDER_VALUETYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.VALUETYPE}`,

    // Success message Application Configuration
    GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_APPCONFIGSTATIC_SUCCESS_TITLE_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATE}`,

    // Error message Application Configuration
    GLOBAL_APPCONFIGSTATIC_ERROR_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_APPCONFIGSTATIC_ERROR_TITLE_NAMEREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.NAMEREQUIRED}`,
    GLOBAL_APPCONFIGSTATIC_ERROR_TITLE_CATEGORYREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.APPCONFIGSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.CATEGORYREQUIRED}`,


    // Code Table Management
    GLOBAL_CODESTATIC_NAVIGATION_BUTTON_NEWCODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWCODE}`,
    GLOBAL_CODESTATIC_CONTENT_DROPDOWN_ALLCATEGORIES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ALLCATEGORIES}`,
    GLOBAL_CODESTATIC_CONTENT_TITLE_CODETABLEMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CODETABLEMANAGEMENT}`,
    GLOBAL_CODESTATIC_TABLE_PLACEHOLDER_FILTER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_PLACEHOLDER}.${DEFAULT_KEY.FILTER}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_CODESTATIC_CONTENT_TABLE_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_CODESTATIC_TABLE_TEXT_NORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.NORECORD}`,
    GLOBAL_CODESTATIC_TABLE_TOOLTIP_UPDATECODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.UPDATECODE}`,
    GLOBAL_CODESTATIC_TABLE_TOOLTIP_VIEWCODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEWCODE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_CODECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CODECATEGORY}`,

    // Update Code Table entry
    GLOBAL_CODESTATIC_CONTENT_TITLE_UPDATECODETABLEENTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATECODETABLEENTRY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_ACTIVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ACTIVE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_INACTIVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.INACTIVE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_PARENTCODECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PARENTCODECATEGORY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_CODESTATIC_FORM_TITLE_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_SEQUENCENUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SEQUENCENUMBER}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_PARENTCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.PARENTCATEGORY}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_SEQUENCENUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.SEQUENCENUMBER}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_EFFECTIVEENDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.EFFECTIVEENDDATE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_PARENTCODECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.PARENTCODECATEGORY}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_PARENTCODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.PARENTCODE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_COUNTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.COUNTRY}`,
    GLOBAL_CODESTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_CODESTATIC_NAVIGATION_BUTTON_SAVECHANGES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SAVECHANGES}`,

    // Code Table entry details 
    GLOBAL_CODESTATIC_CONTENT_TITLE_CODETABLEENTRYDETAILS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CODETABLEENTRYDETAILS}`,
    GLOBAL_CODESTATIC_FORM_TITLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_CODESTATIC_FORM_TITLE_PARENTCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PARENTCATEGORY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_PARENTCODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PARENTCODE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_CREATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDDATE}`,
    GLOBAL_CODESTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_CODESTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,

    // New Code Table entry
    GLOBAL_CODESTATIC_CONTENT_TITLE_NEWCODETABLEENTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.NEWCODETABLEENTRY}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_PARENTCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.PARENTCATEGORY}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_SEQUENCENUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SEQUENCENUMBER}`,
    GLOBAL_CODESTATIC_FORM_PLACEHOLDER_EFFECTIVESTARTDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.EFFECTIVESTARTDATE}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_CODESTATIC_FORM_HELPTEXT_PARENTCODECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.PARENTCODECATEGORY}`,
    GLOBAL_CODESTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_CODESTATIC_ERROR_TITLE_SEQUENCENUMBER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.SEQUENCENUMBER}`,
    // Menu
    GLOBAL_MENU_CONTENT_DROPDOWN_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.MENU}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.LANGUAGE}`,

    // Success message Code Table
    GLOBAL_CODESTATIC_SUCCESS_TITLE_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_CODESTATIC_SUCCESS_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_CODESTATIC_SUCCESS_TITLE_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.CODESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATE}`,

    // Default Static: Error
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_SERVERUNREACHABLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.SERVERUNREACHABLE}`,
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_REQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.REQUIRED}`,
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MINLENGTH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.MINLENGTH}`,
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_MAXLENGTH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.MAXLENGTH}`,
    GLOBAL_DEFAULTSTATIC_ERROR_TITLE_DATEINVALID: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.DATEINVALID}`,

    // FAQ Management
    GLOBAL_FAQSTATIC_CONTENT_TITLE_FAQMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.FAQMANAGEMENT}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_NEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWFAQ}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_LASTPUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.LASTPUBLISHED}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_MULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.MULTILANG}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_QNAMAKER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.QNAMAKER}`,
    GLOBAL_FAQSTATIC_TABLE_TEXT_NORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.NORECORD}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_SETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEW}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.CREATE}`,

    // Add new FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_ADDFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDFAQ}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SELECTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SELECTLANG}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEAI: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ENABLEAI}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ENABLEMULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ENABLEMULTILANG}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LANGUAGE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,

    // View FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_VIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWFAQ}`,

    // Edit FAQ
    GLOBAL_FAQSTATIC_CONTENT_TITLE_EDITFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITFAQ}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_PREVIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.PREVIEW}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETEDRAFT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DELETEDRAFT}`,
    GLOBAL_FAQSTATIC_TREE_TITLE_FAQTREE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TREE_TITLE}.${DEFAULT_KEY.FAQTREE}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_PUBLISHFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.PUBLISHFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_DELETEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_PREVIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.PREVIEWFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_PUBLISHFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_DELETEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEFAQ}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_PUBLISHFAQLOCALE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHFAQLOCALE}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_PUBLISH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.PUBLISH}`,

    // FAQ Settings
    GLOBAL_FAQSTATIC_CONTENT_TITLE_SETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SETTINGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_SAVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SAVE}`,
    GLOBAL_FAQSTATIC_FORM_DIVIDER_MULTILANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.MULTILANG}`,
    GLOBAL_FAQSTATIC_FORM_DIVIDER_AICHATBOT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.AICHATBOT}`,

    // Preview FAQ
    GLOBAL_FAQSTATIC_CONTENT_TEXT_NOQUESTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TEXT}.${DEFAULT_KEY.NOQUESTIONS}`,

    // QuestionSetCard
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_DISPLAYANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DISPLAYANSWER}`,


    // FAQ Tree
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETESUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_DELETEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETEQNSET}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEQNSET}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_UPDATEFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEFAQ}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATETOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATETOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATESUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESUBTOPIC}`,
    GLOBAL_FAQSTATIC_SUCCESS_TITLE_CREATEQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATEQNSET}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_QUESTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.QUESTION}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_HELPTEXT_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ANSWER}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTITLE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQDESC}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTOPICDESC}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQSUBTOPICDESC}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_PRIQN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.PRIQN}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_ANSWER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ANSWER}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_RANK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.RANK}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_TYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TYPE}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_SORTRANKUP: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SORTRANKUP}`,
    GLOBAL_FAQSTATIC_CONTENT_TABLE_SORTRANKDOWN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SORTRANKDOWN}`,
    GLOBAL_FAQSTATIC_TABLE_TEXT_QNSETNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.QNSETNORECORD}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQTOPICTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQTOPICTITLE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.ADD}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQSUBTOPICTITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQSUBTOPICTITLE}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_FAQDESC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FAQDESC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TAGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TAGS}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQANS}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_TOGGLETREEVIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.TOGGLETREEVIEW}`,
    GLOBAL_FAQSTATIC_FORM_PLACEHOLDER_SEARCHQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SEARCH_QNSET}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_FAQQNSETS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.FAQQNSETS}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_MOVEUP: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.MOVEUP}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_MOVEDOWN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.MOVEDOWN}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_ADDQN: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.ADDQN}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_YES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.YES}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_REMOVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.REMOVE}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_NEWFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NEWFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWFAQQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWFAQQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_VIEWQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.VIEWQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_QUESTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.QUESTION}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_EDITQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.EDITQNSET}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_PRIMARY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PRIMARY}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWFAQTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWFAQTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWFAQSUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWFAQSUBTOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_ADDNEWQNSET: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDNEWQNSET}`,
    GLOBAL_FAQSTATIC_TABLE_TOOLTIP_LOADING: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.LOADING}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_FAQ: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FAQ}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_TOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TOPIC}`,
    GLOBAL_FAQSTATIC_FORM_TITLE_SUBTOPIC: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SUBTOPIC}`,
    GLOBAL_FAQSTATIC_ERROR_TITLE_ALLQUESTIONSREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.ALLQUESTIONSREQUIRED}`,
    GLOBAL_FAQSTATIC_ERROR_TITLE_ANSWERREQUIRED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.ANSWERREQUIRED}`,
    GLOBAL_FAQSTATIC_MODAL_TITLE_DELETENODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETENODE}`,
    GLOBAL_FAQSTATIC_MODAL_TEXT_DELETENODE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETENODE}`,
    GLOBAL_FAQSTATIC_NAVIGATION_BUTTON_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.FAQSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.VIEW}`,

    // Scope Management
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_SCOPEMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SCOPEMANAGEMENT}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWSCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_SCOPENORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.SCOPENORECORD}`,

    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWCATEGORY}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWSUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWSUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_CATEGORYNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.CATEGORYNORECORD}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_SUBCATEGORYNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.SUBCATEGORYNORECORD}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_CATEGORYMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CATEGORYMANAGEMENT}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_SUBCATEGORYMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SUBCATEGORYMANAGEMENT}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEW}`,

    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITSCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITCATEGORY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITSUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITSUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_DESCRIPTION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DESCRIPTION}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATESCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATESCOPE}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATESUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATESUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATECATEGORY}`,


    GLOBAL_LOCALESTATIC_CONTENT_TITLE_VIEWSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWSCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_VIEWCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWCATEGORY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_VIEWSUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWSUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_CREATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDDATE}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,

    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATECATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATECATEGORY}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATESCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESCOPE}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATESUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESUBCATEGORY}`,

    GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDSCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDSUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDSUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDCATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_HELPTEXT_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_FORM_HELPTEXT_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_HELPTEXT_SUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.SUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.NAME}`,

    GLOBAL_LOCALESTATIC_FORM_TITLE_PUBLISHBYSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PUBLISHBYSCOPE}`,

    // Language Pack
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_LANGUAGEPACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.LANGUAGEPACK}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_DOWNLOAD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DOWNLOAD}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_PUBLISH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.PUBLISH}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_UPLOADFILE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.UPLOADFILE}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_TRANSDATANORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.TRANSDATANORECORD}`,
    GLOBAL_LOCALESTATIC_FORM_DIVIDER_LANGUAGEPACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.LANGUAGEPACK}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_LANGKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.LANGKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TEXT_TRANSDATA: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TEXT}.${DEFAULT_KEY.TRANSDATA}`,
    GLOBAL_LOCALESTATIC_CONTENT_TEXT_LANGKEYUPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TEXT}.${DEFAULT_KEY.LANGKEYUPDATE}`,
    GLOBAL_FAQSTATIC_FORM_DIVIDER_LANGUAGEPACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.LANGUAGEPACK}`,

    // Language Registration
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_LANGUAGEREG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.LANGUAGEREG}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_APPLICATION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.APPLICATION}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_REGLANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.REGLANGUAGE}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_APPNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.APPNORECORD}`,
    GLOBAL_LOCALESTATIC_BADGE_TEXT_ACTIVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.BADGE_TEXT}.${DEFAULT_KEY.ACTIVE}`,
    GLOBAL_LOCALESTATIC_BADGE_TEXT_DEFAULT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.BADGE_TEXT}.${DEFAULT_KEY.DEFAULT}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_VALIDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.VALIDATE}`,

    // Edit Application Languages
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITAPPLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITAPPLANG}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_ADDLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.ADDLANG}`,
    GLOBAL_LOCALESTATIC_FORM_DIVIDER_LANGSETTINGS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.LANGSETTINGS}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_REMOVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.REMOVE}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_DEFAULTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.DEFAULTLANG}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATEAPPLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEAPPLANG}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_SCOPENORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.SCOPENORECORD}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.LANGUAGE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_DEFAULT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DEFAULT}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_ACTIVE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIVE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_ALIGNRIGHT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ALIGNRIGHT}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SELECTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SELECTLANG}`,

    // Upload File
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_UPLOADFILE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPLOADFILE}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_SUBMIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.SUBMIT}`,

    // Edit Key
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_EDITLANGVALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.EDITLANGVALUE}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_FULLKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.FULLKEY}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_UPDATEKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEKEY}`,
    GLOBAL_LOCALESTATIC_ERROR_TITLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.ERROR_TITLE}.${DEFAULT_KEY.VALUE}`,

    // CreateNewKey
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_CREATETRANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATETRANS}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_ADDLANGENTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDLANGENTRY}`,

    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_KEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.KEY}`,

    GLOBAL_LOCALESTATIC_FORM_TITLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_SUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_KEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.KEY}`,
    GLOBAL_LOCALESTATIC_FORM_DIVIDER_ADDVALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_DIVIDER}.${DEFAULT_KEY.ADDVALUE}`,

    // Translation Management
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTERBYSCOPE}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYCAT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTERBYCAT}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYSUBCAT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTERBYSUBCAT}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_FILTERBYPUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTERBYPUBLISHED}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SORTBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SORTBY}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_SEARCH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.SEARCH}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_SORTBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SORTBY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_PUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PUBLISHED}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_LANGUAGE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LANGUAGE}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_NEWENTRY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWENTRY}`,
    GLOBAL_LOCALESTATIC_TABLE_TEXT_TRANSNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.TRANSNORECORD}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_STATUS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.STATUS}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_KEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.KEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_FULLKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.FULLKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_DEFAULTVALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DEFAULTVALUE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_VALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VALUE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_UPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.UPDATEDBY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_UPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.UPDATEDDATE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_SCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SCOPE}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_LOCALESTATIC_CONTENT_TABLE_SUBCATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.SUBCATEGORY}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_LOCALESTATIC_TABLE_TOOLTIP_REVERT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.REVERT}`,
    GLOBAL_LOCALESTATIC_CONTENT_TITLE_TRANSMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.TRANSMANAGEMENT}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_LOCALESTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_LOCALESTATIC_MODAL_TITLE_DELETETRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETETRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TITLE_REVERTTRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.REVERTTRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TITLE_PUBLISHTRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.PUBLISHTRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_SOFTDELETETRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.SOFTDELETETRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_REVERTTRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.REVERTTRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_HARDDELETETRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.HARDDELETETRANSKEY}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHTRANSKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHTRANSKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_PUBLISHED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.PUBLISHED}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DRAFTED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.DRAFTED}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DELETED: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.DELETED}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_ASCKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ASCKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DESCKEY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.DESCKEY}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_ASCUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ASCUPDATEDDATE}`,
    GLOBAL_LOCALESTATIC_CONTENT_DROPDOWN_DESCUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.DESCUPDATEDDATE}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_LASTEDITEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTEDITEDBY}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_DEFAULTLANG: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DEFAULTLANG}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_YES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.YES}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_PUBLISHTRANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.PUBLISHTRANS}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_DELETETRANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETETRANS}`,
    GLOBAL_LOCALESTATIC_SUCCESS_TITLE_REVERTTRANS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.REVERTTRANS}`,
    GLOBAL_LOCALESTATIC_FORM_TITLE_SEARCH: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.SEARCH}`,
    GLOBAL_LOCALESTATIC_FORM_PLACEHOLDER_ENTERVALUE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.ENTERVALUE}`,
    GLOBAL_DEFAULTSTATIC_FORM_HELPTEXT_CHARREMAINING: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.DEFAULTSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CHARREMAINING}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHBYSCOPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHBYSCOPE}`,
    GLOBAL_LOCALESTATIC_MODAL_TEXT_PUBLISHBYAPP: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.LOCALESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.PUBLISHBYAPP}`,

    // Public Holiday Management
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_PUBLICHOLIDAYMGMT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.PUBLICHOLIDAYMGMT}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_YEAR: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.YEAR}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.DATE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NAME}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_TITLE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TITLE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TABLE_REMARKS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.REMARKS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_DROPDOWN_ALLYEARS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ALLYEARS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_NEWPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TEXT_PUBLICHOLIDAYNORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.PUBLICHOLIDAYNORECORD}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_MODAL_TITLE_DELETEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_MODAL_TEXT_DELETEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_SUCCESS_TITLE_DELETEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEW}`,

    // Add Public Holiday 
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_ADDPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.ADDPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.DATE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_PUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.PUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_REMARKS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.REMARKS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_SUCCESS_TITLE_CREATEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_DATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.DATE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_NAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.NAME}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_PLACEHOLDER_REMARKS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.REMARKS}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,

    // Update Public Holiday
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_UPDATEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_SUCCESS_TITLE_UPDATEPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,

    // View Public Holiday
    GLOBAL_PUBLICHOLIDAYSTATIC_CONTENT_TITLE_VIEWPUBLICHOLIDAY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWPUBLICHOLIDAY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_CREATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDDATE}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_LASTUPDATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDBY}`,
    GLOBAL_PUBLICHOLIDAYSTATIC_FORM_TITLE_LASTUPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.PUBLICHOLIDAYSTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.LASTUPDATEDDATE}`,

    // SMS Template Management
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_SMSTEMPLATEMANAGEMENT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.SMSTEMPLATEMANAGEMENT}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_DROPDOWN_ALLCATEGORIES: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_DROPDOWN}.${DEFAULT_KEY.ALLCATEGORIES}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_NEWSMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWSMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_DELETESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TEXT_SMSTEMPLATENORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.SMSTEMPLATENORECORD}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_NO: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.NO}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_ACTIONS: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.ACTIONS}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_TEMPLATENAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.TEMPLATENAME}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_VERSION: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.VERSION}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TABLE_UPDATEDDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TABLE}.${DEFAULT_KEY.UPDATEDDATE}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_EDIT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.EDIT}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TOOLTIP_VIEW: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TOOLTIP}.${DEFAULT_KEY.VIEW}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CONFIRM: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CONFIRM}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CANCEL: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CANCEL}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_DELETE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.DELETE}`,
    GLOBAL_NOTITEMPLATESTATIC_MODAL_TITLE_DELETESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_MODAL_TEXT_DELETESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETESMSTEMPLATE}`,

    // Create SMS Template
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_CREATESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_CREATESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CREATESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_CREATEDBY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CREATEDBY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATENAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TEMPLATENAME}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TYPE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TYPE}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATESUBJECT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TEMPLATESUBJECT}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_TITLE_TEMPLATEBODY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_TITLE}.${DEFAULT_KEY.TEMPLATEBODY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_PLACEHOLDER_TEMPLATENAME: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.TEMPLATENAME}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_PLACEHOLDER_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_CATEGORY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.CATEGORY}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_TEMPLATESUBJECT: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.TEMPLATESUBJECT}`,
    GLOBAL_NOTITEMPLATESTATIC_FORM_HELPTEXT_TEMPLATEBODY: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_HELPTEXT}.${DEFAULT_KEY.TEMPLATEBODY}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_BACK: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.BACK}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_CREATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.CREATE}`,

    // Update SMS Template
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_UPDATESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_UPDATESMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATESMSTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_UPDATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.UPDATE}`,

    // View SMS Template
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_VIEWSMSTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.VIEWSMSTEMPLATE}`,

    // Create Email Template
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_CREATEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.CREATEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_CREATEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.CREATEEMAILTEMPLATE}`,

    // Email Template list
    GLOBAL_NOTITEMPLATESTATIC_FORM_PLACEHOLDER_FILTER: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.FORM_PLACEHOLDER}.${DEFAULT_KEY.FILTER}`,
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_DELETEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.DELETEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_NAVIGATION_BUTTON_NEWEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.NAVIGATION_BUTTON}.${DEFAULT_KEY.NEWEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_TABLE_TEXT_EMAILTEMPLATENORECORD: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.TABLE_TEXT}.${DEFAULT_KEY.EMAILTEMPLATENORECORD}`,
    GLOBAL_NOTITEMPLATESTATIC_MODAL_TITLE_DELETEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TITLE}.${DEFAULT_KEY.DELETEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_MODAL_TEXT_DELETEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.MODAL_TEXT}.${DEFAULT_KEY.DELETEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_SUCCESS_TITLE_UPDATEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.SUCCESS_TITLE}.${DEFAULT_KEY.UPDATEEMAILTEMPLATE}`,
    GLOBAL_NOTITEMPLATESTATIC_CONTENT_TITLE_UPDATEEMAILTEMPLATE: `${DEFAULT_APPLICATION_NAME}.${DEFAULT_SCOPE.NOTITEMPLATESTATIC}.${DEFAULT_CATEGORY_SUBCATEGORY.CONTENT_TITLE}.${DEFAULT_KEY.UPDATEEMAILTEMPLATE}`,
    
}

// Const: Error Messages
export const DEFAULT_ERROR = {
    REQUIRED: '<%= label %> is required.',
    TICK_REQUIRED: 'Please tick the box to confirm that you have read the above.',
    MIN_VALUE: 'Minimum value is <%= minValue %>.',
    MAX_VALUE: 'Maximum value is <%= maxValue %>.',
    FILE_LIMIT_REACHED: 'Maximum number of file(s) uploaded is <%= limit %>.',
    FILE_ITEM_REQUIRED: 'File is required.',
    FILE_DESC_REQUIRED: 'File description is required.',
    FORM_VALIDATION_FAIL: 'Kindly fill in the required fields appropriately. Please try again.',
    FORM_DEFAULT_FAIL: 'Application has not been processed successfully.',
    PASSWORD_REGEX_UNMATCH: 'The password should contain at least 8 characters, with 1 uppercase, 1 lowercase and 1 number.',
    EMAIL_INVALID: 'Email is invalid.',
    MIN_LENGTH_LIMIT: 'Minimum length is <%= minLength %>.',
    MAX_LENGTH_LIMIT: 'Maximum length is <%= maxLength %>.',
    TELEPHONE_INVALID: 'Telephone number provided is invalid.',
    NUMBER_INVALID: 'Number provided is invalid.',
    APPLICATION_DELETE_FAIL: 'Application has not been deleted. Please try again.',
    LIST_RETRIEVAL_FAIL: 'Unable to retrieve list. Please try again.',
    DRAFT_SAVED_FAIL: 'Draft has not been saved. Please try again.',
    DRAFT_EMPTY_FIELD: 'Please fill in at least one field.',
    DATE_INVALID: 'Date is invalid',
    //Work Hour Error
    TIME_INVALID_WE: 'Work End should be after Work Start',
    TIME_INVALID_BS: 'Break Start should be after Work Start',
    TIME_INVALID_BSWE: 'Break Start should be before Work End',
    TIME_INVALID_BE: 'Break End should be after Break Start',
    SERVER_UNREACHABLE: 'Server could not be reached, please try again later.',
    TREE_QUESTION_SET_EMPTY: "Please fill up all the questions.",
    TREE_QUESTION_SET_ANSWER_EMPTY: "Please fill up the answer."
};
