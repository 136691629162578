import React, { useState } from "react";

function DataGridPanel(props) {
    const show = props.show;
    
    if (show) {
        return <div>
            <p>Datagrid</p>
        </div>
    } else {
        return <div>
        </div>
    }
    
}

export default DataGridPanel;