import * as Yup from 'yup';
import { REGEX } from '../../../App/AppSettings';
import { ERROR } from '../../../Shared/Constants/LanguageKeys';


export const createValidationSchemaForLicenceFields = (t, formDefinition ) => {
    
    const licTemp = {};
    formDefinition.Sections.forEach(section => {
        section.Fields && section.Fields.forEach(field => {
            // Start a single validation rule
            let validationRule = Yup.string();

            // Required
            if (field.Validation != null
                && field.Validation.Required) {
                validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED)).nullable();

                if (field.InputType === 'Email') {
                    validationRule = validationRule.matches(REGEX.EMAIL, t(ERROR.EMAIL_INVALID));
                } else if (field.InputType === 'Number') {
                    validationRule = validationRule.matches(REGEX.NUMBER, t(ERROR.NUMBER_INVALID));
                } else {
                    if ('MinimumLength' in field.Validation) {
                        validationRule = validationRule.min(field.Validation.MinimumLength, t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
                    }
                    if ('MaximumLength' in field.Validation) {
                        validationRule = validationRule.max(field.Validation.MaximumLength, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
                    }
                }
            }
            licTemp[field.Name] = validationRule;
        });

        if (section.Table) {
            const licTableTemp = {};

            section.Table.Fields && section.Table.Fields.forEach(field => {
                // Start a single validation rule
                let validationRule = Yup.string();
                
                // Required
                if (field.Validation != null
                    && field.Validation.Required) {
                    validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED));

                    if (field.InputType === 'Email') {
                        validationRule = validationRule.matches(REGEX.EMAIL, t(ERROR.EMAIL_INVALID));
                    } else if (field.InputType === 'Number') {
                        validationRule = validationRule.matches(REGEX.NUMBER, t(ERROR.NUMBER_INVALID));
                    } else {
                        if ('MinLength' in field.Validation) {
                            validationRule = validationRule.min(field.Validation.MinLength,  t(ERROR.MIN_LENGTH_LIMIT) + (ERROR.MIN_LENGTH_LABEL));
                        }
                        if ('MaxLength' in field.Validation) {
                            validationRule = validationRule.max(field.Validation.MaxLength, t(ERROR.MAX_LENGTH_LIMIT) + (ERROR.MAX_LENGTH_LABEL));
                        }
                    }
                }
                licTableTemp[field.Name] = validationRule;
            });

            /****************** Start Of Table Row Validation ******************/
            if (('MinimumTableRows' in section.Table) && ('MaximumTableRows' in section.Table)) {
                licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp)).required(`Minimum quota not reach: Minimum ${section.Table.MinimumTableRows} record(s)`)
                    .min(section.Table.MinimumTableRows, `Minimum quota not reach: Minimum ${section.Table.MinimumTableRows} record(s)`)
                    .max(section.Table.MaximumTableRows, `Maximum quota hit: Maximum ${section.Table.MaximumTableRows} record(s)`);
            }
            else if (('MaximumTableRows' in section.Table)) {
                licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp))
                    .max(section.Table.MaximumTableRows, `Maximum quota hit: Maximum ${section.Table.MaximumTableRows} record(s)`);
            }
            else if (('MinimumTableRows' in section.Table)) {
                licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp)).required(`Minimum quota not reach: Minimum ${section.Table.MinimumTableRows} record(s)`)
                    .min(section.Table.MinimumTableRows, `Minimum quota not reach: Minimum ${section.Table.MinimumTableRows} record(s)`);
            }
            else {
                licTemp[section.Name] = Yup.array().of(Yup.object().shape(licTableTemp));
            }
            /****************** End Of Table Row Validation ******************/
        }
    });
    return Yup.object().shape(licTemp);
};

export const createValidationSchemaForSupportingDocuments = (t, documentList) => {
    const fileTemp = {};
    documentList.forEach(fileField => {
        // Start single validation rule
        let validationRule = Yup.array();

        // Required
        if (fileField.Validation != null
            && fileField.Validation.Required) {
            validationRule = validationRule.required((ERROR.LABEL) + t(ERROR.REQUIRED))
            .min(1,(ERROR.LABEL) + t(ERROR.REQUIRED));
        }

        fileTemp[fileField.Name] = validationRule;
    });
    return Yup.object().shape(fileTemp);
};
